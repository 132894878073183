import React, { useEffect, useRef, useState } from "react";
import "./styles/main.css";
import EmojiTray from "./components/EmojiTray";
import ChatInput from "./components/ChatInput";
import Header from "./components/Header";
import ChatSidebar from "./components/ChatSidebar";
import Icon from "../../components/Icon";
import Search from "./components/Search";
import Profile from "./components/Profile";
import Convo from "./components/Convo";
import cam from "../../assets/images/cam.svg";
import out from "../../assets/images/output.png";
import voc from "../../assets/images/voc.svg";
import vide from "../../assets/images/vid.svg";
import Recorder from "../../components/recorder"
import { useDispatch, useSelector } from "react-redux";
import date from "date-and-time";
import { upload } from "../../../../../uploadServiceChat";
import {
  FILE_SERVER,
} from "../../../../../constants";
import ForwardList from "../../components/Sidebar/ForwardList";
import ContactsList from "../../components/Sidebar/ContactsList";
const Chat = ({ openContacts, setOpen, viewDisplay, id, user }) => {

  const search = useSelector((state) => state.whatsapp.wordsearch)
  let forwarderMessage = useSelector(state => state?.whatsapp?.forwarderMessage) || null;
  let contacts = useSelector(state => state?.whatsapp?.contacts) || null;
  let reachedEnd = useSelector(state => state?.whatsapp?.reachedEnd) || []
  let vcard = useSelector(state => state?.whatsapp?.vcard) || null;
  const setSearch = (a) => {
    dispatch({ type: "WORD_SEARCH", payload: a })
  }
  const searchedMessages = () => {
    return search === "" ? [] : activeChat?.messages?.filter((mes) => mes?.body?.includes(search)) || []
  }
  const getAudioMessages = () => {
    let arr = document.querySelectorAll(".aud-cont .aud audio")
    return arr
  }
  const goToMessage = (i) => {
    var numb = i.id
    let ids = numb
    setShowSearchSidebar(false)
    document.querySelector("#s" + ids)?.scrollIntoView({ block: "center" })
    document.querySelector("#s" + ids)?.scrollIntoView({ block: "center" })
  }
  const [isMention, setIsMention] = useState([])
  const [replyMessage, setReplyMessage] = useState(null);
  const is_fetch = useSelector((state) => state.whatsapp.is_fetch);
  const chats = useSelector((state) => state.whatsapp.chats);

  const lastMsgRef = useRef();
  const [downButton, showDownButton] = useState(false)
  const showButt = () => {
    let element = document.getElementById("XD-1");
    if (element) {
      let scrollTop = -parseInt(element.scrollTop);
      // element.offsetHeight + element.scrollTop === element.scrollHeight
      if (scrollTop < 250) {
        if (activeChat?.unread > 0) {
          dispatch({ type: "READ_CHAT", id: activeChat?.id })
        }
      }

      if (scrollTop < 250) {
        showDownButton(false)
      }
      else {
        showDownButton(true)
      };
    }
  };
  var timeout;
  // const [currentTop, setCurrentTop] = useState(null)
  const onScroll = (e) => {
    if (!is_fetch && divRef.current && e.deltaY < 0) {
      if (!(reachedEnd.length > 0 && reachedEnd.find(value => value === activeChat?.id))) {
        dispatch({ type: "fetch" });
        setTimeout(() => {
          dispatch({
            type: "GET_PAGE_CHAT",
            id: activeChat?.id,
            page: activeChat?.messages[0].id,
          });
        }, 100);
      }
    }
  };

  const dispatch = useDispatch();
  const [showAttach, setShowAttach] = useState(false);
  const [rec, setRec] = useState(false);

  const [img, setImg] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showProfileSidebar, setShowProfileSidebar] = useState(false);
  const [showSearchSidebar, setShowSearchSidebar] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [caretPossition, setCaretPossition] = useState(0);
  const activeChat = useSelector(state => state?.whatsapp?.activeChat);
  const first = useSelector(state => state?.whatsapp?.first);
  const messagesAll = useSelector(state => state?.whatsapp?.activeChat?.messages) || [];
  useEffect(() => {
    if (id) {
      setTimeout(() => {
        scrollToLastMsg()
        document?.querySelector(".chat__input")?.focus()
      }, 500);
      setTimeout(() => {
        scrollToLastMsg()
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first, forwarderMessage])
  const [imgs, setImgs] = useState(null);
  const [vid, setVid] = useState(null);
  const [file, setFile] = useState(null);
  // var dateOfConversation = useRef(null);
  useEffect(() => {
    scrollToLastMsg()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rec]);
  const openSidebar = (cb) => {
    // close any open sidebar first
    setShowProfileSidebar(false);
    setShowSearchSidebar(false);

    // call callback fn
    cb(true);
  };

  const scrollToLastMsg = () => {
    showDownButton(false)
    let element = document.querySelector("#XD-1");
    if (element) {
      element.scrollTop = -0;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    }
  };

  let members = activeChat?.whatsapp_contact?.sub_contacts || []

  const submitNewMessage = () => {
    if (newMessage?.split("")?.filter((d) => d === " " || d === "\n")?.length === newMessage?.length) {
      setNewMessage("")
    }
    if (newMessage?.length > 0 && newMessage?.split("")?.filter((d) => d === " " || d === "\n").length !== newMessage.length) {
      let dd = new Date();
      let dds = dd.getTime() - (3 * 60 * 60 * 1000)
      dds = new Date(dds);
      let mid = Math.random() * 1000;
      let cid = activeChat?.id;
      let a = newMessage;
      members && members?.forEach((h) => {
        if (newMessage.includes("@" + h.number)) {

          if (isMention.filter((s) => s.id === h.id).length === 0) {
            setIsMention(isMention.concat([h]));
          }
        }
        else {
          setIsMention(isMention.filter((s) => s.id !== h.id))
        }
      })
      let obj = {
        body: a,
        sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
        fromMe: 1,
        ack: "sent",
        id: "true_" + mid,
        sent_at: date.format(dds, "YYYY-MM-DD HH:mm:ss"),
        timestamp: Math.floor(Date.now() / 1000),
        type: "chat",
        cid: activeChat?.id,
        mid: mid,
        chat_id: activeChat?.id,
        quotedMsg: replyMessage
      };
      let objs = {
        receiver_mobile_phone: !activeChat?.new ? `${!activeChat?.id_from_whatsapp?.split("@")[0].includes("+") ? "+" : ""
          }${activeChat?.id_from_whatsapp?.split("@")[0]}` : activeChat?.id_from_whatsapp?.split("@")[0],

        body: newMessage,
        media: null,

        type: "chat",
        // updated_at: dd.toISOString(),
        msg_id_from_whatsapp: replyMessage?.id_from_whatsapp?.toString(),
      };

      let groubObj = {
        group_contact_id: `${activeChat?.id_from_whatsapp.split("+")[0]}`,
        body: newMessage,
        media: null,
        mentions: isMention.length > 0 && `${isMention.map((s, index) => (`${s.number}${index !== isMention.length - 1 ? "," : ""}`))}`,
        type: "chat",
        // updated_at: dd.toISOString(),
        msg_id_from_whatsapp: replyMessage?.id_from_whatsapp?.toString(),
      };


      dispatch({
        type: "SEND_TEXT",
        payload: obj,
        isGroup: activeChat?.isGroup === 1,
        pay: activeChat?.isGroup === 1 ? groubObj : objs,
        mid: mid,
        cid: cid,
      });

      dispatch({
        type: "SEND_TEXT_SAGA",

        isGroup: activeChat?.isGroup === 1,
        pay: activeChat?.isGroup === 1 ? groubObj : objs,
        mid: mid,
        cid: cid,
      });
      setIsMention([])
      setNewMessage("");
      setReplyMessage(null);
      setTimeout(() => {
        scrollToLastMsg()
      }, 400);
    }
  };
  const uploadImage = async () => {
    dispatch({ type: "SEND_P" });
    let dd = new Date();
    let dds = dd.getTime() - (3 * 60 * 60 * 1000)
    dds = new Date(dds);
    let mid = Math.random() * 1000;
    let cid = activeChat?.id;
    let a = newMessage;
    members.forEach((h) => {
      if (newMessage.includes("@" + h.number)) {

        if (isMention.filter((s) => s.id === h.id).length === 0) {
          setIsMention(isMention.concat([h]));
        }
      }
      else {
        setIsMention(isMention.filter((s) => s.id !== h.id))
      }
    })
    dispatch({
      type: "SEND_TEXT",
      cid: activeChat?.id,
      mid: mid,
      payload: {
        body: a,
        fromMe: 1,
        media: img,
        sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
        ack: "sent",
        loading: true,
        id: "true_" + mid,
        sent_at: date.format(dds, "YYYY-MM-DD HH:mm:ss"),
        timestamp: Math.floor(Date.now() / 1000),
        type: "image",
        chat_id: activeChat?.id,
        cid: activeChat?.id,
        mid: mid,
        quotedMsg: replyMessage
      },
    });
    setImg(null);
    setNewMessage("");
    dispatch({ type: "SEND_P" });
    if (activeChat?.isGroup === 0) {
      let pat = await upload(file);
      let objs = {
        receiver_mobile_phone: `+${activeChat?.id_from_whatsapp?.split("@")[0]}`,
        body: newMessage,
        mentions: isMention.length > 0 && `${isMention.map((s, index) => (`${s.number}${index !== isMention.length - 1 ? "," : ""}`))}`,
        media: FILE_SERVER + pat,

        msg_id_from_whatsapp: replyMessage?.id_from_whatsapp.toString(),
        type: "image",
        updated_at: dd.toISOString(),
      };
      dispatch({
        type: "SEND_TEXT_SAGA",
        pay: objs,
        isGroup: activeChat?.isGroup === 1,
        cid: cid,
        mid: mid,
      });
    } else {
      let pat = await upload(file);
      let groubObj = {
        group_contact_id: `${activeChat?.id_from_whatsapp.split("+")[0]}`,
        body: newMessage,
        media: FILE_SERVER + pat,
        mentions: isMention.length > 0 && `${isMention.map((s, index) => (`${s.number}${index !== isMention.length - 1 ? "," : ""}`))}`,
        msg_id_from_whatsapp: replyMessage?.id_from_whatsapp.toString(),
        type: "image",
        updated_at: dd.toISOString(),
      };

      dispatch({
        type: "SEND_TEXT_SAGA",
        mentions: isMention.length > 0 && `${isMention.map((s, index) => (`${s.number}${index !== isMention.length - 1 ? "," : ""}`))}`,
        pay: groubObj,
        isGroup: activeChat?.isGroup === 1,
        cid: cid,
        mid: mid,
      });
    }
  };

  const handlePhoto = (e) => {
    setFile(e);
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(e);
      reader.onload = async () => {
        setImg(reader.result);
        setTimeout(() => {
          document.querySelector(".img__in")?.focus();
        }, 600);
      };
    });
  };
  const sendVid = async (f) => {
    if (f["type"].split("/")[0] === "video") {
      let type = f.name.split(".")[1] ?? "Vids";
      let name = f.name;
      let dd = new Date();
      let dds = dd.getTime() - (3 * 60 * 60 * 1000)
      dds = new Date(dds);
      let mid = Math.random() * 100;
      let cid = activeChat?.id_from_whatsapp;
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = async () => {
          let payload = {
            receiver_chat_id: cid.split("+")[0],
            media: reader.result,
            sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
            id: "true_",
            fromMe: 1,
            file_name: name,
            body: name,
            file_ext: type,
            updated_at: dd.toISOString(),
            loading: true,
            ack: "sent",
            sent_at: date.format(dds, "YYYY-MM-DD HH:mm:ss"),
            timestamp: Math.floor(Date.now() / 1000),
            type: "video",
            cid: activeChat?.id,
            mid: mid,
            chat_id: activeChat?.id,
            quotedMsg:
              replyMessage
          };
          dispatch({
            type: "SEND_TEXT",
            payload: payload,
            mid: mid,
            cid: cid,
          });
          setTimeout(() => {
            scrollToLastMsg()
          }, 200);
        };
      });
      let pat = await upload(f);
      let objs = {
        receiver_mobile_phone: cid.split("@")[0],
        media: FILE_SERVER + pat,
        body: null,
        type: "video",
        updated_at: dd.toISOString(),
        msg_id_from_whatsapp: replyMessage?.id_from_whatsapp.toString(),
      };
      let groubObj = {
        group_contact_id: `${activeChat?.id_from_whatsapp.split("+")[0]}`,
        body: newMessage,
        media: FILE_SERVER + pat,
        mentions: isMention.length > 0 && `${isMention.map((s, index) => (`${s.number}${index !== isMention.length - 1 ? "," : ""}`))}`,
        msg_id_from_whatsapp: replyMessage?.id_from_whatsapp.toString(),
        type: "video",
        updated_at: dd.toISOString(),
      };
      dispatch({
        type: "SEND_TEXT_SAGA",
        pay: activeChat?.isGroup === 1 ? groubObj : objs,
        isGroup: activeChat?.isGroup === 1,
        cid: activeChat?.id,
        mid: mid,
      });
    }
  }
  const clear = () => {
    setNewMessage("");
    setImg(null);
    setFile(null);
  };
  const sendAudio = async (e) => {
    handleAudio(e)
  };
  let cid = useSelector(state => state?.whatsapp?.activeChat?.id_from_whatsapp);
  let chat_id = useSelector(state => state?.whatsapp?.activeChat?.id);
  let chat = useSelector(state => state?.whatsapp?.activeChat);
  const handleAudio = async (e) => {
    //useSelector(state => state?.whatsapp?.scr)
    var reader = new FileReader();
    reader.readAsDataURL(e);
    //for backend
    let mid = Math.random();
    let dd = new Date();
    let dds = dd.getTime() - (3 * 60 * 60 * 1000)
    dds = new Date(dds);
    var file = new File([e], "wave-" + parseInt(mid * 1000));
    reader.onloadend = async function () {
      var base64data = reader.result;


      dispatch({
        type: "SEND_TEXT",
        payload: {
          body: null,

          ack: "sent",
          sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
          cid: cid,
          fromMe: 1,
          mid: mid,
          chat_id: chat_id,
          loading: false,
          id: "true_" + mid,
          sent_at: date.format(dds, "YYYY-MM-DD HH:mm:ss"),
          timestamp: Math.floor(Date.now() / 1000),
          type: "audio",
          quotedMsg:
            replyMessage,
          media: base64data,
        },
      });
      let pat = await upload(file);
      let objs = {
        receiver_mobile_phone: cid.split("@")[0],
        media: FILE_SERVER + pat,
        body: null,
        type: "audio",
        updated_at: dd.toISOString(),
        msg_id_from_whatsapp: replyMessage?.id_from_whatsapp.toString(),
      };
      let groubObj = {
        group_contact_id: `${activeChat?.id_from_whatsapp.split("+")[0]}`,
        body: newMessage,
        media: FILE_SERVER + pat,
        mentions: isMention.length > 0 && `${isMention.map((s, index) => (`${s.number}${index !== isMention.length - 1 ? "," : ""}`))}`,
        msg_id_from_whatsapp: replyMessage?.id_from_whatsapp.toString(),
        type: "audio",
        updated_at: dd.toISOString(),
      };
      dispatch({
        type: "SEND_TEXT_SAGA",
        pay: chat.isGroup === 1 ? groubObj : objs,
        isGroup: chat.isGroup === 1,
        cid: activeChat?.id,
        mid: mid,
      });
    };
  };
  const sendDocument = async (f) => {
    if (f["type"].split("/")[0] === "image") {
      handlePhoto(f);
    } else {
      let type = f.name.split(".")[1] ?? "FILE";
      let name = f.name;
      let dd = new Date();
      let dds = dd.getTime() - (3 * 60 * 60 * 1000)
      dds = new Date(dds);
      let mid = Math.random() * 100;
      let cid = activeChat?.id_from_whatsapp;
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = async () => {
          let payload = {
            receiver_chat_id: cid.split("+")[0],
            media: reader.result,
            sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
            file_name: name,
            file_ext: type,
            updated_at: dd.toISOString(),
            loading: true,
            ack: "sent",
            sent_at: date.format(dds, "YYYY-MM-DD HH:mm:ss"),
            timestamp: Math.floor(Date.now() / 1000),
            type: "document",
            cid: activeChat?.id,
            mid: mid,
            chat_id: activeChat?.id,
            quotedMsg:
              replyMessage
          };
          dispatch({
            type: "SEND_TEXT",
            payload: payload,
            mid: mid,
            cid: cid,
          });
          setTimeout(() => {
            scrollToLastMsg()
          }, 200);
        };
      });
      let pat = await upload(f);
      let objs = {
        receiver_mobile_phone: `+${cid.split("@")[0]}`,
        media: FILE_SERVER + pat,
        body: name,
        type: "document",
        updated_at: dd.toISOString(),
        filename: name,
        msg_id_from_whatsapp: replyMessage?.id_from_whatsapp.toString(),
      };
      let groubObj = {
        group_contact_id: `${activeChat?.id_from_whatsapp.split("+")[0]}`,
        body: newMessage,
        filename: name,
        media: FILE_SERVER + pat,
        mentions: isMention.length > 0 && `${isMention.map((s, index) => (`${s.number}${index !== isMention.length - 1 ? "," : ""}`))}`,
        msg_id_from_whatsapp: replyMessage?.id_from_whatsapp.toString(),
        type: "document",
        updated_at: dd.toISOString(),
      };
      dispatch({
        type: "SEND_TEXT_SAGA",
        pay: activeChat?.isGroup === 1 ? groubObj : objs,
        isGroup: activeChat?.isGroup === 1,
        cid: activeChat?.id,
        mid: mid,
      });
    }
  }
  const divRef = useRef(null);
  return (
    <div className={`chat ${(id || openContacts) && "activeC"}`}>
      {!openContacts ? <>
        {(imgs || vid) &&

          <div className="fixed-img-prev">
            <div className="bac-drop"></div>
            <div className="svv" onClick={() => { setVid(null); setImgs(null) }}> <Icon id="cancel" /></div>
            {vid ? <video src={vid} controls><source src={vid} /></video> : <img alt="" src={imgs} />}
          </div>}
        {img && (
          <div className="image_prev">
            <Icon
              id="cancel"
              style={{ position: "absolute", top: "20px", left: "20px" }}
              onClick={() => clear()}
            />

            <img alt="" src={img} />
            <div className="MessageCaption">
              <input
                className="chat__input img__in"
                placeholder="write a caption"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <div className="send_button_img" onClick={() => uploadImage()}>
                <Icon id="send" />
              </div>
            </div>
          </div>
        )}
        {!img && (
          <>
            <div
              className={
                "chat__body " +
                `${showSearchSidebar || (showProfileSidebar && "wid0px")}`
              }
            >
              <div className="chat__bg"></div>

              <Header
                close={() => {
                  dispatch({ type: "CHAT_ID", payload: null })
                }}
                user={activeChat?.whatsapp_contact ?? activeChat}
                id={activeChat?.id_from_whatsapp}
                openProfileSidebar={() => openSidebar(setShowProfileSidebar)}
                openSearchSidebar={() => openSidebar(setShowSearchSidebar)}
              />

              <div
                id="XD-1"
                tabIndex={"-1"}
                ref={divRef}
                style={{
                  flexDirection: "column-reverse",
                  overflowAnchor: "none"
                }}
                className="chat__content"
                onWheel={(e) => {
                  showButt()
                  document
                    .querySelector(".chat__date-wrapper")
                    ?.classList.add("scroled");
                  let els = document.querySelector("#XD-1")
                  if (!is_fetch && divRef.current && ((-els.scrollTop * 100) / (els.scrollHeight - els.clientHeight)) > 80) {
                    onScroll(e)
                  }
                  clearTimeout(timeout);
                  timeout = setTimeout(() => {
                    document
                      .querySelector(".chat__date-wrapper")
                      ?.classList?.remove("scroled");
                  }, 8000);
                }}
              >
                {activeChat && (
                  <div style={
                    {
                      display: "flex",
                      flexDirection: "column",
                      overflowAnchor: "none"
                    }
                  }>
                    <Convo
                      getAudioMessages={() => getAudioMessages()}
                      img={imgs}
                      setImg={(s) => setImgs(s)}
                      setVid={(s) => setVid(s)}
                      vid={vid}
                      // dateOfConversation={dateOfConversation}
                      setReplyMessage={(value) => setReplyMessage(value)}
                      lastMsgRef={lastMsgRef}
                      messages={messagesAll}
                    />

                    <div
                      ref={lastMsgRef}
                      className="ref-bot"
                      style={{
                        position: "static",
                        bottom: "0px",
                        opacity: "0",
                        height: "2px",
                      }}
                    ></div>
                  </div>
                )}
              </div>


              <footer className="chat__footer">
                {replyMessage && (
                  <div className="reply-sc">
                    <div
                      className="reply-cancel"
                      onClick={() => (setReplyMessage(null))}
                    >
                      <Icon id="cancel" />
                    </div>
                    <div className="replay-mes">
                      <div className="bar-c"></div>
                      <div className="reply-container">
                        <div className="group_name reply-name">
                          {replyMessage?.fromMe === 1
                            ? "You"
                            : activeChat?.isGroup === 1 ? (
                              (activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === replyMessage.received_from_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === replyMessage.received_from_contact_id)[0]?.pushname)
                                ? (activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === replyMessage.received_from_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === replyMessage.received_from_contact_id)[0]?.pushname)
                                : "+" + activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === replyMessage.received_from_contact_id)[0]?.number
                            )
                              :
                              ((activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                                ? (activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                                : "+" + activeChat?.whatsapp_contact?.number)}
                          {/* {replyMessage?.fromMe === 1
                            ? "You"
                            : contacts.filter((s)=>s.id===replyMessage?.received_from_contact_id)[0]?.name
                              ? contacts.filter((s)=>s.id===replyMessage?.received_from_contact_id)[0]?.name
                              : "+" + contacts.filter((s)=>s.id===replyMessage?.received_from_contact_id)[0].number} */}
                        </div>
                        <div className="reply-desc">
                          {(replyMessage?.type === "image" || replyMessage?.type === "ptt" || replyMessage?.type === "audio" || replyMessage?.type === "sticker" || replyMessage?.type === "video") && (
                            <svg data-src={replyMessage?.type === "image" ? cam : (replyMessage?.type === "ptt" || replyMessage?.type === "audio") ? voc : vide}></svg>
                          )}
                          {replyMessage?.type === "document" && (
                            <img alt="" width={14} height={16} src={out} />
                          )}
                          {" "}
                          {replyMessage?.body}
                        </div>
                      </div>
                      {replyMessage?.media && (
                        <div className="replay-img">
                          {replyMessage?.type === "image" && <img alt="" src={replyMessage.media} />}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {rec &&
                  <Recorder
                    send={(e) => {

                      if (e) sendAudio(e);
                      setRec(false);
                    }}
                    style={{ display: "block" }}
                    rec={rec}
                    setRec={setRec}
                  />
                }
                {!rec && (
                  <>
                    {downButton && (
                      <button
                        className="chat__scroll-btn"
                        aria-label="scroll down"
                        onClick={() => { scrollToLastMsg() }}
                      >
                        {activeChat?.unread > 0 && <span className="sidebar-contact__unread unre">{activeChat?.unread}</span>}
                        <Icon id="downArrow" />
                      </button>
                    )}
                    <EmojiTray
                      showEmojis={showEmojis}
                      newMessage={newMessage}
                      setNewMessage={setNewMessage}
                      caretPossition={caretPossition}
                    />
                    <ChatInput
                      replyMessage={replyMessage}
                      sendAud={(e) => sendAudio(e)}
                      showEmojis={showEmojis}
                      sendDocument={(e) => sendDocument(e)}
                      sendVid={(e) => sendVid(e)}
                      onFocus={(e) => { setCaretPossition(e.target?.selectionStart); }}
                      onBlur={() => { ; }}
                      setRec={setRec}

                      handlePhoto={(e) => handlePhoto(e)}
                      img={img}
                      rec={rec}
                      setShowEmojis={setShowEmojis}
                      showAttach={showAttach}
                      isMention={isMention}
                      setShowAttach={setShowAttach}
                      newMessage={newMessage}
                      setNewMessage={setNewMessage}
                      submitNewMessage={submitNewMessage}
                    />
                  </>
                )}
              </footer>
            </div>
            <ChatSidebar
              searchedMessages={searchedMessages()}
              search={search}
              goToMessage={(iden) => goToMessage(iden)}
              onChange={(e) => setSearch(e.target.value)}
              heading="Search Messages"
              active={showSearchSidebar}
              closeSidebar={() => setShowSearchSidebar(false)}
            >
              <Search goToMessage={(iden) => goToMessage(iden)} searchedMessages={searchedMessages()} search={search} onChange={(e) => setSearch(e.target.value)} />
            </ChatSidebar>
            {activeChat?.id && !activeChat?.new && <ChatSidebar
              searchedMessages={forwarderMessage}
              heading="Forward Message"
              active={forwarderMessage?.id}
              closeSidebar={() => dispatch({ type: "FORWARD-MESSAGE", payload: null })}>
              <ForwardList closeSidebar={() => dispatch({ type: "FORWARD-MESSAGE", payload: null })} chatss={chats} chats={chats.filter((cha) => cha.id !== activeChat?.id)} />
            </ChatSidebar>}
            <ChatSidebar
              heading="Contact Info"
              active={showProfileSidebar}
              closeSidebar={() => setShowProfileSidebar(false)}
            >
              <Profile id_f={activeChat?.id_from_whatsapp} isGroup={activeChat?.isGroup === 1} activeChat={activeChat} id={activeChat?.id} user={activeChat?.whatsapp_contact ?? user} />
            </ChatSidebar>
          </>
        )}
      </> :
        !vcard ? <ContactsList data={contacts} small={!viewDisplay} close={() => setOpen(false)} /> :
          <ChatSidebar
            searchedMessages={vcard}
            sendVcard={true}
            heading="Forward Message"
            active={vcard?.length > 0}
            closeSidebar={() => dispatch({ type: "VCARD-MESSAGE", payload: null })}>
            <ForwardList sendVcard={true} closeSidebar={() => { dispatch({ type: "VCARD-MESSAGE", payload: null }); setOpen(false); }} chatss={chats} chats={chats} />
          </ChatSidebar>}

    </div>
  );
};

export default Chat;
