// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import store from './redux/store';
// var firebaseConfig = {
//     apiKey: "AIzaSyDjgDmdv9S_jwFjWe40QPkRqhWS851CHGc",
//     authDomain: "vcg-ddd93.firebaseapp.com",
//     projectId: "vcg-ddd93",
//     storageBucket: "vcg-ddd93.appspot.com",
//     messagingSenderId: "1081274223991",
//     appId: "1:1081274223991:web:70e73921369464b9852572"
// };

// let firebaseApp,messaging
// try{
//    firebaseApp = initializeApp(firebaseConfig);
//    messaging =  getMessaging(firebaseApp);
// }catch(e){
//   console.error(e)
// }
// export const requestFirebaseNotificationPermission = () =>
// {
//   return getToken(messaging)
//   .then((currentToken) => {
//     if (currentToken) {
//       return currentToken
//       // Perform any other neccessary action with the token
//     } else {
//       // Show permission request UI
// ;
//     }
//   })
//   .catch((err) => {
//     console.error('An error occurred while retrieving token. ', err);
//   });
// }
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log(payload)
//       resolve(payload);
//     });
// });


import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
var firebaseConfig = {
  apiKey: "AIzaSyDjgDmdv9S_jwFjWe40QPkRqhWS851CHGc",
  authDomain: "vcg-ddd93.firebaseapp.com",
  projectId: "vcg-ddd93",
  storageBucket: "vcg-ddd93.appspot.com",
  messagingSenderId: "1081274223991",
  appId: "1:1081274223991:web:70e73921369464b9852572"
};
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const requestFirebaseNotificationPermission = async () => {
  return getToken(messaging).then((currentToken) => {
    if (currentToken) {
      
      return (currentToken)
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
     

      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    
    // catch error while creating client token
  });
}
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      
      resolve(payload);
    });
  });