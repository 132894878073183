import { combineReducers } from "redux";
import { authReducer } from "./auth/reducer";
import categoriesReducer from "./categories/reducer";
import { TransferReducer } from "./Transfer/reducer";
import { HRMReducer } from "./HRM/departments/reducer";
import { JobReducer } from "./HRM/JobTitle/reducer";
import { LangReducer } from "./LinkedLanguages/reducer";
import { CurrReducer } from "./Currenciess/currencies/reducer";
import { NumReducer } from "./Currenciess/numcats/reducer";
import { CountriesReducer } from "./countries/countries/reducer";
import { CompainesReducer } from "./countries/companies/reducer";
import { OfficesReducer } from "./countries/offices/reducer";
import { LiscensesReducer } from "./countries/Liscense/reducer";
import { languagesReducer } from "./languages/reducer";
import { ChatReducer } from "./chat/reducer";
import { EmployeeReducer } from "./HRM/employee/reducer";
import { whatsappReducer } from "./whatsapp/reducer";
import {
  offline,
} from 'redux-offline-status/lib/reducer';
import { RegionsReducer } from "./regions/reducer";
import { tablePropsInit } from './translate/tableReducer'
import { translationsReducer } from './translate/translationsReducer'
const rootReducer = combineReducers({
  offline,
  auth: authReducer,
  categories: categoriesReducer,
  transfer: TransferReducer,
  department: HRMReducer,
  jobs: JobReducer,
  langs: LangReducer,
  currency: CurrReducer,
  numcats: NumReducer,
  countries: CountriesReducer,
  companies: CompainesReducer,
  offices: OfficesReducer,
  liscenses: LiscensesReducer,
  languages: languagesReducer,
  chat: ChatReducer,
  employee: EmployeeReducer,
  whatsapp: whatsappReducer,
  tablePropsInit,
  translationsReducer,
  RegionsReducer
});

export default rootReducer;
