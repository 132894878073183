import React, { useEffect, useState } from "react";
import "./App.css";
import "./assets/css/index.css";
import Home from "./pages/Home";
import Sidebar from "./components/Sidebar";
import Chat from "./pages/Chat";
import { useDispatch, useSelector } from "react-redux";
import ChattingScreen from "../chat/ChattingScreen";

const userPrefersDark =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

function App(props) {

  const dispatch = useDispatch();
  let { WAwidth, setWAwidth } = props
  let id = useSelector(state => state?.whatsapp?.activeChat) || null;
  let idchat = useSelector(state => state?.chat?.activeChat) || null;
  const [user, setUser] = useState(null);
  const setId = (ml) => {
    id = ml;
    dispatch({ type: "GET_WA_ONE_CHAT", id: ml.id })
    dispatch({ type: "CHAT_ID", payload: ml });
  };
  const chats = useSelector(state => state?.whatsapp?.chats) || [];

  useEffect(() => {
    if (userPrefersDark) document.body.classList.add("dark-theme");
  }, []);
  const [openContacts, setOpen] = useState(false)
  const change = () => {
    if (props.viewDisplay) {
      props.make(false)
      setWAwidth(490)
    }
    else {
      props.make(true)
      let shades = document.querySelector(".lang-modalDisable")
      setWAwidth(shades?.clientWidth - 200)
    }
  }
  // useEffect(()=>{
  //   let container=document.querySelector(".whats-modal")
  //   var isDown=false
  //  let shades=document.querySelector(".lang-modalDisable")

  //   let element=document.querySelector("#change")
  //   element.addEventListener("mousedown",function(){
  //     isDown=true
  //   })
  //   window.addEventListener("mousemove",function(e){
  //     if(isDown){


  //       if(e.movementX>0){
  //         container=document.querySelector(".whats-modal")
  //         shades=document.querySelector(".lang-modalDisable")

  //         if((container.clientWidth-50)>455){
  //           if(container)
  //         container.style.width=`${container.clientWidth-50}px`}
  //       }
  //       else{
  //         if(shades?.clientWidth-50>container?.clientWidth+50){


  //         container=document.querySelector(".whats-modal")
  //        if(container)
  //         container.style.width=`${container.clientWidth+50}px`}
  //       }
  //       if(container?.clientWidth<550){
  //         // setWAwidth(null)
  //         props.make(false)
  //       }
  //       else{
  //         // setWAwidth(null)
  //         props.make(true)
  //       }
  //     }
  //   })
  //   window.addEventListener("mouseup",function(e){
  //     container=document.querySelector(".whats-modal")
  //     if(container&&isDown)
  //     setWAwidth(container.clientWidth)
  //     isDown=false

  //   })
  // },[])
  return (
    <div className="app">
      <p className="app__mobile-message"> Only available on desktop 😊. </p>

      {/*{chats?.length > 0 && (*/}
      <div id="app-cont" className="app-content">
        <div id="change" className="change-view" onClick={() => change()} >


        </div>
        {(!(props.viewDisplay && !id?.id && !idchat?.id && !openContacts) || (!props.viewDisplay)) ?
          (idchat?.id ? <ChattingScreen WAwidth={WAwidth} dis={props.viewDisplay} active={idchat} /> : <Chat WAwidth={WAwidth} openContacts={openContacts} setOpen={setOpen} viewDisplay={props.viewDisplay} change={() => props.change()} user={user} id={id} />)
          :
          <Home />
        }
        <Sidebar setOpen={setOpen} openContacts={openContacts} chats={chats}
          setId={(e) => setId(e)}
          setUser={(e) => setUser(e)} />
      </div>
      {/*)}*/}
    </div>
  );
}

export default App;
