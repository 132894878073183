import axios from "axios";
// import { API } from "./API";
import { store } from "./redux/store";
const base =
  store?.getState().whatsapp.fetched_url === true
    ? store?.getState()?.whatsapp.base_url
    : process.env.REACT_APP_BASE_URL;
const uploadFile = async (file_name, file, onUploadProgress) => {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("file_name", file_name);

  return axios.post(
    base + "/wallet-full-backend/public/s3/upload_file",
    formData
  );
};

export const upload = async (file) => {
  let currentFile = file;
  let a = "";
  await uploadFile(currentFile.name.split(".")[0], file)
    .then((response) => {
      a = response.data.data.file_path;
      return response.data.data.file_path;
    })
    .catch((e) => {
      console.error(e);
    });
  return a;
};
