import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { forwardMessage } from '../../../../../constants';
import Alert from './Alert';
import ChatUsers from './ChatUsers';
import Contact from './Contact';
import date from "date-and-time";

function ForwardList(props) {
  const dispatch = useDispatch()
  let forwarderMessage = useSelector(state => state.whatsapp?.forwarderMessage) || null;
  const chatsArray = useSelector((state) => state.chat.data)
  let vcard = useSelector(state => state.whatsapp?.vcard) || null;
  const setId = (ml) => {
    dispatch({ type: "CHAT_ID", payload: ml });
  };
  function handleSetUser(contact, id) {
    setId(null);
    dispatch({ type: "OPEN-CHAT", payload: null })


    setId(contact);
    dispatch({ type: "READ_CHAT", id: contact.id })

    forwardMessage(forwarderMessage, contact)
    setId(contact);

  }
  const sendVcard = (contact) => {
    setId(null);
    dispatch({ type: "OPEN-CHAT", payload: null })
    setId(contact);
    dispatch({ type: "READ_CHAT", id: contact.id })
    setId(contact);
    let dd = new Date();
    let dds = dd.getTime() - (3 * 60 * 60 * 1000)
    dds = new Date(dds);
    let mid = Math.random() * 1000;
    let cid = contact?.id;
    let a = vcard;
    let obj = {
      body: a,
      sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
      fromMe: 1,
      ack: "sent",
      id: "true_" + mid,
      sent_at: date.format(dds, "YYYY-MM-DD HH:mm:ss"),
      timestamp: Math.floor(Date.now() / 1000),
      type: "vcard",
      vcard: a,
      cid: contact?.id,
      mid: mid,
      chat_id: contact?.id,
      quotedMsg: null
    };
    let objs = {
      receiver_mobile_phone: !contact?.new ? `${!contact?.id_from_whatsapp?.split("@")[0].includes("+") ? "+" : ""
        }${contact?.id_from_whatsapp?.split("@")[0]}` : contact?.name,
      body: a,
      vcard: a,
      media: null,
      type: "vcard",
      // updated_at: dd.toISOString(),
      msg_id_from_whatsapp: null,
    };

    let groubObj = {
      group_contact_id: `${contact?.id_from_whatsapp.split("+")[0]}`,
      body: a,
      vcard: a,
      media: null,
      mentions: [],
      type: "vcard",
      // updated_at: dd.toISOString(),
      msg_id_from_whatsapp: null,
    };


    dispatch({
      type: "SEND_TEXT",
      payload: obj,
      isGroup: contact?.isGroup === 1,
      pay: contact?.isGroup === 1 ? groubObj : objs,
      mid: mid,
      cid: cid,
    });

    dispatch({
      type: "SEND_TEXT_SAGA",

      isGroup: contact?.isGroup === 1,
      pay: contact?.isGroup === 1 ? groubObj : objs,
      mid: mid,
      cid: cid,
    });
    dispatch({ type: "READ_CHAT", id: contact.id })
    setId(contact);
    props.closeSidebar()
  }
  const openAdmin = () => {

    if (props.chatss.length > 0) {
      if (vcard) {
        sendVcard(props.chatss.filter((s) => s.is_admin === 1)[0])
      }
      else
        handleSetUser(props.chatss.filter((s) => s.is_admin === 1)[0], props.chatss.filter((s) => s.is_admin === 1)[0].id);
    }
  }
  return (
    <>
      {<ChatUsers forwarderMessage={forwarderMessage} forward={() => { ; }} chats={props.sendVcard ? [] : chatsArray} />}
      <div className="sidebar__contacts">
        {props.chats.length > 0 && <Alert noAdmin={false} noGroup={true} chat={props.chatss.filter((s) => s.is_admin === 1)[0]} name={props.chatss.filter((s) => s.is_admin === 1)[0]?.whatsapp_contact?.name ? props.chatss.filter((s) => s.is_admin === 1)[0]?.whatsapp_contact?.name : "+" + props.chatss.filter((s) => s.is_admin === 1)[0]?.whatsapp_contact?.number} new={0}
          onClick={() => openAdmin()} />}
        {props.chats.filter((s, ind) => !(s.is_admin === 1)).map((contact, index) => {
          return (
            <Contact
              onClick={() => {
                if (vcard) {
                  sendVcard(contact)
                }
                else
                  handleSetUser(contact, contact?.id)
              }}
              key={index}
              contact={contact}
            />

          )
        })}
      </div>
    </>
  )
}

export default ForwardList