import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CountriesModal from './CountriesModal'
import "../countries.css"
import CountryItem from './CountryItem'
function LicensesList(props) {
  const dispatch = useDispatch()
  const ActiveAdd = (e) => {
    dispatch({ type: "Active-add", payload: e })
  }
  const { lang_id, setLang_id } = props
  useEffect(() => {


    if (lang_id === 0)
      Add()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang_id])
  const countries = useSelector(state => state.liscenses.offices)
  const { makeFollower } = props
  const data = useSelector(state => state.liscenses.newDepartment)
  const Active_Ids = useSelector(state => state.liscenses.Active_Ids)
  const open = useSelector(state => state.liscenses.open)
  const setOpen = (e) => {
    dispatch({ type: "OPEN-LES", payload: e })
  }
  useEffect(() => {
    dispatch({ type: "GET-LES" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const setMode = (e) => {
    dispatch({ type: "MODE-LES", payload: e })
  }
  const setModalData = (e) => {
    dispatch({ type: "MOD-DATA-LES", payload: { name: e.name, icon: e.icon } })
  }
  const setData = (e) => {
    dispatch({ type: "NEW-LES", payload: { ...e } })
  }
  const Update = async (e, post) => {
    dispatch({ type: "request-running-LES-red", paylod: null })
    setMode("update");
    let obj = { country_id: "", name: "", domain: "", post_code: "", tax: "", No: "", date: "", approval_code: "", pdf_files: [] }
    setData(obj);
    setData({ ...e })
    setMode("update");
    setOpen(true);
  }
  const Read = async (e, post) => {
    setData({ ...e });
    setMode("read");
    setOpen(true);
  }
  const Close = () => {
    setLang_id(null)
    dispatch({ type: "OFF-POST-REDUCER", payload: {} })
    let obj = { country_id: "", name: "", domain: "", post_code: "", tax: "", No: "", date: "", approval_code: "", pdf_files: [] }
    setOpen(false);
    setData(obj);
    setModalData({ name: "", icon: null, code: "" })
  }
  const Add = async (e) => {
    setOpen(false);
    dispatch({ type: "request-running-LES-red", paylod: null })

    let obj = { country_id: "", name: "", domain: "", post_code: "", tax: "", No: "", date: "", approval_code: "", pdf_files: [] }
    setData(obj);
    if (e)
      obj = { ...obj, country_id: e.country_id ? e.country_id : e.id }
    else
      obj = { ...obj }
    setData(obj)
    setMode("add")
    setTimeout(() => {
      setOpen(true);
    }, 100);
  }

  return (
    <>

      {(lang_id === 0 || lang_id === null) && open && <CountriesModal setOpen={(a) => setOpen(a)} open={open} Add={(e) => Add(e)} Close={() => Close()} Active_Ids={Active_Ids} ActiveAdd={() => ActiveAdd(data.id)} languageFollower={props.languageFollower} setExpand={(e) => { }} data={data} {...props} />}
      <div className='lang-body countries'>
        {
          countries.map((dept) => (
            <CountryItem
              lang={props.lang}
              ActiveAdd={(e) => ActiveAdd(e)}
              open={open}
              Close={() => Close()}
              Add={(e) => Add(e)}
              Update={(e, p) => Update(e, p)}
              Read={(e, p) => Read(e, p)}
              setModalData={(e) => setModalData(e)}
              setOpen={(e) => setOpen(e)}
              child={dept}
              Active_Ids={Active_Ids}
              languageFollower={props.languageFollower}
              level={1}
              makeFollower={(level, value, id) => makeFollower(level, value, id)}
              lang_id={lang_id}
              setLang_id={(e) => setLang_id(e)}
            />
          ))
        }

      </div>
    </>
  )
}

export default LicensesList