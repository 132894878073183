import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import AxiosInstance, {
} from "../../../helpers/axiosInstance";
import { toast } from "react-toastify";
import {
  ADD_DEPT_URL,
  GET_BY_POST,
  GET_DEPT_URL,
  UPD_DEPT_URL,
} from "./constants";
import store from "../../store";
import { GET_LANGS_URL } from "../../LinkedLanguages/constants";
function* getAllDepartments(action) {
  let response;
  try {
    yield put({ type: "GENERAL-LOADING-OFF" });
    response = yield AxiosInstance.get(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public" +
        GET_DEPT_URL
        : process.env.REACT_APP_BASE_URL +
        "/wallet-full-backend/public" +
        GET_DEPT_URL
    );
    let langs = yield AxiosInstance.get(GET_LANGS_URL);
    yield put({ type: "GET-LANG-REDUCER", payload: langs.data.data });
    yield put({ type: "GENERAL-DONE" });
    yield put({ type: "GET-OFF-REDUCER", payload: response.data.data });
    yield put({ type: "GENERAL-DONE-OFF" });
  } catch (e) {
    yield put({ type: "GENERAL-DONE-OFF" });

    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchGetAllDepartments() {
  yield takeEvery("GET-OFF", getAllDepartments);
}
function* AddDepartments(action) {
  let responses;
  try {
    yield put({ type: "GENERAL-DONE-OFF" });
    yield put({ type: "request-running-OFF", payload: action.payload });
    yield AxiosInstance.post(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public" +
        ADD_DEPT_URL
        : process.env.REACT_APP_BASE_URL +
        "/wallet-full-backend/public" +
        ADD_DEPT_URL,
      JSON.stringify(action.payload)
    );
    yield put({ type: "request-running-OFF-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-OFF-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-OFF" });
    responses = yield AxiosInstance.get(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public" +
        GET_DEPT_URL
        : process.env.REACT_APP_BASE_URL +
        "/wallet-full-backend/public" +
        GET_DEPT_URL
    );
    yield put({ type: "GET-OFF-REDUCER", payload: responses.data.data });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-OFF",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchAddDepartments() {
  yield takeEvery("ADD-OFF", AddDepartments);
}
function* UpdateDepartments(action) {
  let responses;
  try {
    yield put({ type: "GENERAL-DONE-OFF" });
    yield put({ type: "request-running-OFF", payload: action.payload });
    yield AxiosInstance.post(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public" +
        UPD_DEPT_URL
        : process.env.REACT_APP_BASE_URL +
        "/wallet-full-backend/public" +
        UPD_DEPT_URL,
      JSON.stringify(action.payload)
    );
    yield put({ type: "request-running-OFF-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-OFF-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-OFF" });
    responses = yield AxiosInstance.get(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public" +
        GET_DEPT_URL
        : process.env.REACT_APP_BASE_URL +
        "/wallet-full-backend/public" +
        GET_DEPT_URL
    );

    yield put({ type: "GET-OFF-REDUCER", payload: responses.data.data });
    yield put({ type: "GENERAL-DONE-OFF" });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-OFF",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}
function* getByPost(action) {
  try {
    yield put({ type: "OFF-LOADING" });
    const res = yield AxiosInstance.get(GET_BY_POST + action.payload);
    if (res.data.data.parent_ids) {
      let offices = store.getState().offices.offices;

      yield put({
        type: "CORD",
        payload: {
          cor: [{ region_lat: "34.333", region_long: "33.3333" }],
          phone_code: offices.filter(
            (a) =>
              parseInt(a.id) ===
              (res.data.data.country_id
                ? parseInt(res.data.data.country_id)
                : res.data.data.id)
          )[0].phone_code,
        },
      });
      yield put({
        type: "OFF-POST-REDUCER",
        payload: {
          ...res.data.data,
          parent_ids: [res.data.data.id, ...res.data.data.parent_ids].reverse(),
        },
      });
    } else toast.error("No regions for this post code");
    yield put({ type: "OFF-DONE" });
  } catch (e) {
    yield put({ type: "OFF-DONE" });
  }
}
function* watchGetByPost() {
  yield takeEvery("OFF-POST", getByPost);
}
function* watchUpdDepartments() {
  yield takeEvery("UPD-OFF", UpdateDepartments);
}

export function* OfficesSaga() {
  yield all([
    fork(watchGetAllDepartments),
    fork(watchUpdDepartments),
    fork(watchAddDepartments),
    fork(watchGetByPost),
  ]);
}
