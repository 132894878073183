import React, { useEffect, useState } from "react";
import Info from "../../../../../assets/svg/Info";
import Docs from "../../../../../assets/svg/Docs";
import Images from "../../../../../assets/svg/Images";
import Links from "../../../../../assets/svg/Links";
import imgUp from "../../../../../assets/svg/imgUp.svg";
import passup from "../../../../../assets/svg/passUp.svg";
import avat from "../../../../../assets/svg/avat.png";
import DrivUp from "../../../../../assets/svg/DrivUp.svg";
import Card from "../../../../../assets/svg/Card";
import qr from "../../../../../assets/svg/QR.svg";
import Accounting from "../../../../../assets/svg/Accounting";
import { useDispatch, useSelector } from "react-redux";
import { FILE_SERVER } from "../../../../../constants";
import { upload } from "../../../../../uploadServiceChat";
import contrac from "../../../../../assets/svg/contract.svg";
import LoadingImage from "../../../LoadingImage";
import InputText from "./inputs/InputText";
import AsyncInput from "./inputs/AsyncInput";
import "./employee.css";
import Spinner from "../../../Spinner";
import { textMarshal } from "text-marshal";
import AddPdf from "../../Countries/Liscenses/inputs/AddPdf";
import PDFElem from "../../Countries/Liscenses/inputs/PDFElem";
import HoursInput from "./inputs/HoursInput";
import SelectInput from "./inputs/SelectInput";
import SelectDay from "./inputs/SelectDay";
import QRCode from "react-qr-code";
import { trans } from "../../../../../Internationalization"

function DepartmentModal(props) {
  console.log(props)
  let weekdays = [
    {
      "id": 1,
      "name": "Sunday",
      "is_weekend": 1
    },
    {
      "id": 2,
      "name": "Monday",
      "is_weekend": 0
    },
    {
      "id": 3,
      "name": "Tuesday",
      "is_weekend": 0
    },
    {
      "id": 4,
      "name": "Wednesday",
      "is_weekend": 0
    },
    {
      "id": 5,
      "name": "Thursday",
      "is_weekend": 0
    },
    {
      "id": 6,
      "name": "Friday",
      "is_weekend": 0
    },
    {
      "id": 7,
      "name": "Saturday",
      "is_weekend": 1
    }
  ]
  const currencies = useSelector(state => state.currency.currencies)
  const jobs = useSelector(state => state.jobs.jobs)
  const worktypes = useSelector(state => state.jobs.worktypes)
  const getNum = (a) => {
    let d = textMarshal({
      input: a ? a.toString() : a,
      template: `xx`,
      disallowCharacters: [/[a-z]/],
    });
    return d.marshaltext;
  }
  const getNumSalary = (a) => {
    let d = textMarshal({
      input: a ? a.toString() : a,
      template: `xxxxxx`,
      disallowCharacters: [/[a-z]/],
    });
    return d.marshaltext;
  }
  const updatPhotoPDF = (index) => {
    if (mode !== "read") {
      let Image = document.createElement("input");
      Image.onchange = (e) => {
        updateImagePDF(e.target.files[0], index);
      };
      Image.type = "file";
      Image.hidden = true;
      Image.accept = "application/pdf";
      Image.style = { position: "absolute", opacity: "0" };
      let i = document.body.appendChild(Image);
      i.click();
    }
  };
  const updateImagePDF = async (file, index) => {
    dispatch({
      type: "IMG-UPLOAD-COM",
      payload: { status: true, type: index },
    });

    let pat = await upload(file, index);
    let tmp = {
      ...data,
      uploaded_files: [...data?.uploaded_files, pat],
    };
    dispatch({ type: "NEW-EMP", payload: { ...tmp } });
    dispatch({
      type: "IMG-UPLOAD-COM",
      payload: { status: false, type: null },
    });
    dispatch({ type: "LEVEL-DONE-COM" });
  };
  const [sourceFocuse, setSourceFocuse] = useState(false);
  const [sourceFocuseReg1, setSourceFocuseReg1] = useState(false);
  const [sourceFocuseReg2, setSourceFocuseReg2] = useState(false);
  const [sourceFocuseReg4, setSourceFocuseReg4] = useState(false);
  const mode = useSelector((state) => state.employee.mode);
  const loading = useSelector((state) => state.employee.async_loading);
  const reg_loading = useSelector((state) => state.employee.reg_loading);
  const depts = useSelector((state) => state.employee.departments);
  const regs = useSelector((state) => state.employee.regions);
  const workoffices = useSelector((state) => state.jobs.workoffices);
  const lis = useSelector((state) => state.jobs.lis);
  const lis_loading = useSelector((state) => state.jobs.lis_loading);
  const img_upload = useSelector((state) => state.employee.img_upload);
  useEffect(() => {

    if (mode !== "add") {

      if (data?.work_office_region_id || data?.citizen_country_id || data?.license_id)
        dispatch({ type: "GET_EMP_DATA", payload: data });
      let arr = []

      data?.vacation_days?.forEach((a) => arr.push(a?.id))
      if (data?.work_office_region_id && !data?.office_work_name) {
        dispatch({ type: "GET_EMP_DATA", payload: data });

      }
      dispatch({ type: "NEW-EMP", payload: { ...data, vacation_days: arr } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const data = props.data;
  const shake = (e) => {
    document.querySelector(".lang-modal.open").classList.add("shake-modal");
    setTimeout(() => {
      document
        .querySelector(".lang-modal.open")
        .classList.remove("shake-modal");
    }, 400);
  };
  const {
    open,
    setOpen
  } = props;
  const dispatch = useDispatch();
  const ButtonValidate = () => {
    let bool = false;
    if (
      data?.full_name?.length > 0

    ) {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  };
  const updateImage = async (file, type) => {
    dispatch({ type: "IMG-UPLOAD-EMP", payload: { status: true, type: type } });

    let pat = await upload(file);
    let tmp = { ...data };
    tmp[`${type}`] = pat;

    dispatch({ type: "NEW-EMP", payload: { ...tmp } });
    dispatch({
      type: "IMG-UPLOAD-EMP",
      payload: { status: false, type: null },
    });
    dispatch({ type: "LEVEL-DONE-EMP" });
  };
  const updatPhoto = (name) => {
    if (mode !== "read") {
      let Image = document.createElement("input");
      Image.onchange = (e) => {
        let tmp = { ...data };
        tmp[`${name}`] = "pending";

        dispatch({ type: "NEW-EMP", payload: { ...tmp } });
        updateImage(e.target.files[0], name);
      };
      Image.type = "file";
      Image.hidden = true;
      Image.accept = "image/*";
      Image.style = { position: "absolute", opacity: "0" };
      let i = document.body.appendChild(Image);
      i.click();
    }
  };
  const sendRequest = () => {
    if (true) {
      let arr = []
      data?.contact_infos?.forEach((sd) => {
        if (sd.phone_number && sd.phone_number.length > 0) {
          arr.push(sd)
        }
      })
      if (!data?.id) {
        dispatch({ type: "ADD-EMP", payload: { ...data, contact_infos: arr } });
      } else {
        dispatch({ type: "UPD-EMP", payload: { ...data, contact_infos: arr } });
      }
    }
  };
  const getDates = (a) => {
    if (a && a.toString().length > 11) {
      let d = new Date(a)
      let str = ""
      str = `${d.getDate() > 9 ? d.getDate() : "0" + d.getDate()}.${(d.getMonth() + 1) > 9 ? (d.getMonth() + 1) : "0" + (d.getMonth() + 1)}.${d.getFullYear()}`
      return (str)
    }
    else
      return (a)
  }
  const getPhone = (a) => {
    let d = textMarshal({
      input: a,
      template: `+xx  xxx  xxxxxxxx`,
      disallowCharacters: [/[a-z]/],
    });
    return d.marshaltext;
  };
  const [modal_sec, setModal_sec] = useState(1);
  const showData = () => {
    if (modal_sec === 6) {
      return (
        <>
          <>
            <div className="modal-input-section" style={{
              marginTop: "25px",
              alignItems: "center",
              flexDirection: "row",
            }}>
              <div className="qr-container">
                <div className="avatar-img-emp">
                  {data?.avatar ? <img alt="" src={FILE_SERVER + data?.avatar} /> : <img alt="" src={avat} />}

                </div>
              </div>
              <div className="job-emp">
                <div className="jop-code">RTA-700{data.id ?? "XX"}</div>
                <div className="dept-emp" style={{ marginTop: "7px" }}>
                  {data?.department && data?.department[0] && <svg
                    className="dept-child"
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="15"
                    viewBox="0 0 23 15"
                  >
                    <g id="_15x23_flag_photo" data-name="15x23 flag photo">
                      <rect
                        id="Rectangle_4601"
                        data-name="Rectangle 4601"
                        width="23"
                        height="15"
                        rx="2"
                        fill="#ddd"
                      />
                    </g>
                    <text
                      textAnchor="middle"
                      id="p8"
                      transform="translate(0 2)"
                      fill="#404040"
                      fontSize="8"
                      fontFamily="SegoeUI, Segoe UI"
                      letterSpacing="0.025em"
                    >
                      <tspan x="50%" y="9">
                        {data?.department[0].short_name}
                      </tspan>
                    </text>
                  </svg>}

                  <span style={{ marginLeft: "5px" }} className="job-name">
                    Officer
                  </span>
                  <span className="emp-name-mod">
                    {data?.full_name}
                  </span>
                </div>

              </div>
            </div>

            <div className="modal-input-section"
              style={{
                marginTop: "19px",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <div className="input-section" style={{ width: "109px", justifyContent: "flex-start", alignItems: "flex-start" }}>
                <div className="input-label">{trans("emp_app_date")}</div>
                <div className="input-holder" style={{ height: "25px" }}>
                  <input
                    type={"date"}
                    className="cal"
                    onChange={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, date_of_application: e.target.value },
                      })
                    }
                    value={
                      data?.date_of_application?.includes(" ")
                        ? data?.date_of_application?.split(" ")[0]
                        : data?.date_of_application
                    }
                  />
                  <InputText
                    type="date"
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    lang={data?.date_of_application}
                    setLang={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, date_of_application: e.target.value },
                      })
                    }
                    clear={() =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, date_of_application: "" },
                      })
                    }
                  />
                </div>

              </div>
              {data?.contract_image ? <img alt="" style={{ width: "60px", height: "40px", borderRadius: "10px" }} src={FILE_SERVER + data?.contract_image} onClick={() => updatPhoto("contract_image")} /> : <svg onClick={() => updatPhoto("contract_image")} style={{ marginLeft: "20px" }} data-src={contrac}></svg>}
              <div className="input-section" style={{ width: "180px", marginLeft: "20px", justifyContent: "flex-start", alignItems: "flex-start" }}>

                <div className="input-label">{trans("con_date")}</div>
                <div className="input-holder" style={{ height: "25px" }}>
                  <input
                    type="date"
                    className="cal"
                    onChange={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, contract_date: e.target.value },
                      })
                    }
                    value={
                      data?.contract_date?.includes(" ")
                        ? data?.contract_date.split(" ")[0]
                        : data?.contract_date
                    }
                  />
                  <InputText
                    type="date"
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    lang={data?.contract_date}
                    setLang={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, contract_date: e.target.value },
                      })
                    }
                    clear={() =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, contract_date: "" },
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="modal-input-section"
              style={{
                marginTop: "2px",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <div className="input-section" style={{ width: "109px", justifyContent: "flex-start", alignItems: "flex-start" }}>
                <div className="input-label">{trans("wo_st_date")}</div>
                <div className="input-holder" style={{ height: "25px" }}>
                  <input
                    type={"date"}
                    className="cal"
                    onChange={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, work_contract: e.target.value },
                      })
                    }
                    value={
                      data?.work_contract?.includes(" ")
                        ? data?.work_contract.split(" ")[0]
                        : data?.work_contract
                    }
                  />
                  <InputText
                    type="date"
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    lang={(data?.work_contract)}
                    setLang={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, work_contract: e.target.value },
                      })
                    }
                    clear={() =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, work_contract: "" },
                      })
                    }
                  />
                </div>

              </div>
              <div className="input-section" style={{ width: "109px", justifyContent: "flex-start", marginLeft: "29px", alignItems: "flex-start" }}>
                <div className="input-label">{trans("con_dur")}</div>
                <div className="input-holder" style={{ height: "25px" }}>

                  <InputText
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    lang={getNum(data?.contract_duration)}
                    setLang={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, contract_duration: e.target.value },
                      })
                    }
                    clear={() =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, contract_duration: "" },
                      })
                    }
                  />
                  {data?.contract_duration && data?.contract_duration.length > 0 && <span style={{ left: `19px`, color: mode === "read" && "#8e8e8e" }} className="inpt-text-helper" >Years</span>}
                </div>

              </div>
              <div className="input-section" style={{ width: "109px", justifyContent: "flex-start", marginLeft: "29px", alignItems: "flex-start" }}>
                <div className="input-label">{trans("wo_con_exp")}</div>
                <div className="input-holder" style={{ height: "25px" }}>
                  <input
                    type={"date"}
                    className="cal"
                    onChange={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, work_contract_expire: e.target.value },
                      })
                    }
                    value={
                      data?.work_contract_expire?.includes(" ")
                        ? data?.work_contract_expire.split(" ")[0]
                        : data?.work_contract_expire
                    }
                  />
                  <InputText
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    lang={data?.work_contract_expire}
                    setLang={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, work_contract_expire: e.target.value },
                      })
                    }
                    clear={() =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, work_contract_expire: "" },
                      })
                    }
                  />
                </div>

              </div>
            </div>
            <div className="modal-input-section"
              style={{
                marginTop: "2px",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <div className="input-section" style={{ width: "100%", justifyContent: "flex-start", alignItems: "flex-start" }}>
                <div className="input-label">{trans("emp_reg_com")}</div>
                <div className="input-holder" style={{ height: "25px" }}>

                  <AsyncInput
                    onFocus={() => setSourceFocuseReg1(true)}
                    onBlur={() => setSourceFocuseReg1(false)}
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    lang={data?.liscense_name ? data?.liscense_name : ""}
                    setLang={(e) => {
                      dispatch({ type: "ASYNC_LIS", payload: e.target.value });
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, liscense_name: e.target.value },
                      })
                    }
                    }
                    clear={() =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, liscense_name: "", license_id: null },
                      })
                    }
                  />
                  {lis_loading && sourceFocuseReg1 && (
                    <span
                      className="locat locat_static"
                      style={{ top: "-4px" }}
                    >
                      <Spinner />
                    </span>
                  )}
                  {data?.liscense_name &&
                    !lis_loading &&
                    data?.liscense_name.length > 0 &&
                    sourceFocuseReg1 &&
                    lis && lis.length > 0 && (
                      <div className="input-sync">
                        {lis?.forEach((a) => (
                          <div
                            className="input-sync-item"
                            onClick={() => {
                              dispatch({
                                type: "NEW-EMP",
                                payload: {
                                  ...data,
                                  liscense_name:
                                    a.name,
                                  license_id: a.id
                                },
                              });
                              setTimeout(() => {
                                dispatch({
                                  type: "NEW-EMP",
                                  payload: {
                                    ...data,
                                    license_id: a.id,
                                    liscense_name:
                                      a.name,
                                  },
                                });
                              }, 600);
                            }}
                          >
                            <div className="input-sync-text">
                              {
                                a.name
                              }
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                </div>

              </div>

            </div>
            <div className="modal-input-section">
              <div className="modal-icon-name">
                {trans("ssk_off_et_no")}
              </div>
              <div className="input-section lisc-inp">
                <div
                  id="no_w"
                  className="trans-input-holder no-trans-hold"
                  style={{ width: "100%" }}
                >
                  <div
                    className="trans-input-element"
                    style={{ width: "109px" }}
                  >

                    <InputText
                      noFloat={true}
                      disabled={mode === "read"}
                      code={false}
                      pad={false}
                      clear={() => {
                        dispatch({
                          type: "NEW-EMP",
                          payload: { ...data, ssk_office: "" },
                        });
                      }}
                      lang={
                        data?.ssk_office
                      }
                      setLang={(e) => {
                        dispatch({
                          type: "NEW-EMP",
                          payload: { ...data, ssk_office: e.target.value },
                        });
                      }}
                    />
                  </div>
                  <div
                    className="trans-input-element no-trans-inp"
                    style={{ width: "268px", marginLeft: "10px" }}
                  >
                    <InputText
                      noFloat={true}
                      placeholder={"No  :"}
                      disabled={mode === "read"}
                      code={false}
                      pad={false}
                      clear={() => {
                        dispatch({
                          type: "NEW-EMP",
                          payload: { ...data, ssk_number: "" },
                        });
                      }}
                      lang={getNumSalary(data?.ssk_number)}
                      setLang={(e) => {
                        dispatch({
                          type: "NEW-EMP",
                          payload: { ...data, ssk_number: e.target.value },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-input-section">
              <div className="modal-icon-name">
                {trans("st_date_an_app_cod")}
              </div>
              <div className="input-section lisc-inp">
                <div
                  id="no_w"
                  className="trans-input-holder no-trans-hold"
                  style={{ width: "100%" }}
                >
                  <div
                    className="trans-input-element"
                    style={{ width: "109px" }}
                  >

                    <InputText
                      type="date"
                      noFloat={true}
                      disabled={mode === "read"}
                      code={false}
                      pad={false}
                      clear={() => {
                        dispatch({
                          type: "NEW-EMP",
                          payload: { ...data, approval_date: "" },
                        });
                      }}
                      lang={
                        data?.approval_date
                      }
                      setLang={(e) => {
                        dispatch({
                          type: "NEW-EMP",
                          payload: { ...data, approval_date: e.target.value },
                        });
                      }}
                    />
                  </div>
                  <div
                    className="trans-input-element no-trans-inp"
                    style={{ width: "268px", marginLeft: "10px" }}
                  >
                    <InputText
                      noFloat={true}
                      placeholder={"No  :"}
                      disabled={mode === "read"}
                      code={false}
                      pad={false}
                      clear={() => {
                        dispatch({
                          type: "NEW-EMP",
                          payload: { ...data, approval_code: "" },
                        });
                      }}
                      lang={data?.approval_code}
                      setLang={(e) => {
                        dispatch({
                          type: "NEW-EMP",
                          payload: { ...data, approval_code: e.target.value },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-input-section"
              style={{
                marginTop: "2px",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <div className="input-section" style={{ width: "100%", justifyContent: "flex-start", alignItems: "flex-start" }}>
                <div className="input-label">{trans("emp_wo_off")}</div>
                <div className="input-holder" style={{ height: "25px" }}>


                  <AsyncInput
                    onFocus={() => setSourceFocuseReg4(true)}
                    onBlur={() => setSourceFocuseReg4(false)}
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    lang={data?.office_work_name}
                    setLang={(e) => {
                      dispatch({ type: "GET_WORKOFF", payload: e.target.value });
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, office_work_name: e.target.value },
                      })
                    }
                    }
                    clear={() =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, office_work_name: "", work_office_region_id: null },
                      })
                    }
                  />

                  {data?.office_work_name &&
                    data?.office_work_name.length > 0 &&
                    sourceFocuseReg4 &&
                    workoffices.length > 0 && (
                      <div className="input-sync" style={{ width: "100%", maxHeight: "300px" }}>
                        {workoffices?.forEach((a) => (
                          <>
                            {a.country && a.city &&
                              <div
                                className="input-sync-item"
                                onClick={() => {
                                  dispatch({
                                    type: "NEW-EMP",
                                    payload: {
                                      ...data,
                                      office_work_name:
                                        a.name,
                                      work_office_region_id: a.id
                                    },
                                  });
                                  setSourceFocuseReg4(false)
                                  setTimeout(() => {
                                    dispatch({
                                      type: "NEW-EMP",
                                      payload: {
                                        ...data,
                                        work_office_region_id: a.id,
                                        office_work_name:
                                          `${a.country.region_translations.filter((t) => t.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].region_name} ${a.city ? a.city.region_translations.filter((t) => t.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].region_name : ""} ${a.district ? (a.district.region_translations.filter((t) => t.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].region_name) : ""} ${a.region_translations.filter((t) => t.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].region_name}`
                                      },
                                    });
                                  }, 600);
                                }}
                              >
                                {a.flag_photo_path ?
                                  <svg data-src={FILE_SERVER + a.flag_photo_path} width="23" height={"15"}></svg> :
                                  a.country && a.country.flag_photo_path ?
                                    <svg data-src={FILE_SERVER + a.country.flag_photo_path} width="23" height={"15"}></svg> :

                                    <svg className={`no-trans sm`} xmlns="http://www.w3.org/2000/svg" width="23" height="15" viewBox="0 0 23 15">
                                      <g textAnchor='middle' id="Group_7626" data-name="Group 7626" transform="translate(-274 -138)">
                                        <g id="_15x23_flag_photo" data-name="15x23 flag photo" transform="translate(274 138)">
                                          <rect id="Rectangle_4601" data-name="Rectangle 4601" width="23" height="15" rx="2" fill="#404040" />
                                        </g>
                                        <text textAnchor='middle' id="CFO" transform="translate(274 139)" fill="#F7F7F7" fontSize="9" fontFamily="SegoeUI, Segoe UI"><tspan textAnchor='middle' x="50%" y="10">{a.region_translations && a.region_translations.filter((a) => a.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].region_description}</tspan></text>
                                      </g>
                                    </svg>}
                                {a.country && a.city && <div className="input-sync-text">

                                  {
                                    ` ${a?.country?.region_translations?.filter((t) => t.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0]?.region_name} ${a?.city ? a?.city.region_translations.filter((t) => t.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].region_name : ""} ${a.district ? (a?.district.region_translations.filter((t) => t.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].region_name) : ""} ${a?.region_translations.filter((t) => t.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].region_name}`
                                  }
                                </div>}
                              </div>}
                          </>
                        ))}
                      </div>
                    )}
                </div>

              </div>

            </div>
            <div className="modal-input-section"
              style={{
                marginTop: "2px",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <div className="input-section" style={{ width: "100%", justifyContent: "flex-start", alignItems: "flex-start" }}>
                <div className="input-label">{trans("emp_lic_att_pdf")}</div>
                <div className="input-holder">
                  <div className="modal-icons mic-ico">
                    {data?.uploaded_files?.forEach((a, index) => (
                      <PDFElem clear={() => { let arr = []; data.uploaded_files.forEach((m, ind) => { if (ind !== index) { arr.push(m); } dispatch({ type: "NEW-EMP", payload: { ...data, uploaded_files: arr } }) }) }} path={FILE_SERVER + a} />
                    ))}
                    {img_upload.status ? (
                      <div className="pdf-loading">
                        <LoadingImage />
                      </div>
                    ) : (
                      <AddPdf
                        action={() => {
                          updatPhotoPDF(data?.uploaded_files.length);
                        }}
                      />
                    )}
                  </div>

                </div>

              </div>

            </div>
            <div className="modal-input-section" style={{ flexDirection: "row" }}>
              <div className="input-holder" style={{ width: "179px", flexDirection: "column", alignItems: "flex-start" }}>
                <div className="input-label">{trans("emp_wo_type")}</div>
                <div className="input-section" style={{ marginTop: "4px" }}>
                  <SelectInput
                    data={worktypes}
                    lang={data?.work_type_id}
                    setLang={(e) => dispatch({ type: "NEW-EMP", payload: { ...data, work_type_id: e } })}
                    clear={() => { dispatch({ type: "NEW-EMP", payload: { ...data, work_type_id: null } }) }} />
                </div>
              </div>
              <div className="input-holder" style={{ width: "179px", marginLeft: "29px", flexDirection: "column", alignItems: "flex-start" }}>
                <div className="input-label">{trans("emp_job_title")}</div>
                <div className="input-section" style={{ marginTop: "4px" }}>
                  <SelectInput
                    data={jobs}
                    lang={data?.job_title_id}
                    setLang={(e) => dispatch({ type: "NEW-EMP", payload: { ...data, job_title_id: e } })}
                    clear={() => { dispatch({ type: "NEW-EMP", payload: { ...data, job_title_id: null } }) }} />
                </div>
              </div>
            </div>
            <div className="modal-input-section" style={{
              marginTop: "2px",
              flexDirection: "row",
              alignItems: "flex-end",
            }}>
              <div className="input-section" style={{ width: "62px", alignItems: "flex-start" }}>
                <div className="input-label" style={{ marginTop: "5px" }}>{trans("job_hours")}</div>
                <div className="input-holder">
                  <InputText
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    lang={getNum(data?.working_hours)}
                    setLang={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, working_hours: e.target.value },
                      })
                    }
                    clear={() =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, working_hours: "" },
                      })
                    } />
                  {data?.working_hours && data?.working_hours.length > 0 && <span style={{ left: `${19}px`, color: mode === "read" && "#8e8e8e" }} className="inpt-text-helper">Hours</span>}

                </div>
              </div>
              <div className="input-section" style={{ width: "106px", marginLeft: "10px", alignItems: "flex-start" }}>
                <div className="input-label">{trans("st_end")}</div>
                <div className="input-holder">
                  <HoursInput

                    noFloat={true}
                    disabled={mode === "read"}
                    placeholder="09:00 - 17:00"
                    pad={false}
                    lang={[data?.start_working_at, data?.end_working_at]}
                    setLangStart={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, start_working_at: e.target.value },
                      })
                    }
                    setLangEnd={(e) => {
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, end_working_at: e.target.value },
                      })
                    }}
                    clear={() =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, start_working_at: "", end_working_at: "" },
                      })
                    } />
                </div>
              </div>
              <div className="input-section" style={{ width: "62px", marginLeft: "32px", alignItems: "flex-start" }}>
                <div className="input-label" style={{ marginTop: "5px" }}>{trans("emp_break_hours")}</div>
                <div className="input-holder">
                  <InputText
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    lang={getNum(data?.break_time_hours)}
                    setLang={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, break_time_hours: e.target.value },
                      })
                    }
                    clear={() =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, break_time_hours: "" },
                      })
                    } />
                  {data?.break_time_hours && data?.break_time_hours.length > 0 && <span style={{
                    left: `${19}px`, color: mode === "read" &&
                      "#8e8e8e"
                  }} className="inpt-text-helper" >{trans("hours")}</span>}

                </div>
              </div>
              <div className="input-section" style={{ width: "106px", marginLeft: "10px", alignItems: "flex-start" }}>
                <div className="input-label">{trans("st_end")}</div>
                <div className="input-holder">
                  <HoursInput

                    noFloat={true}
                    disabled={mode === "read"}
                    placeholder="09:00 - 17:00"
                    pad={false}
                    lang={[data?.start_break_at, data?.end_break_at]}
                    setLangStart={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, start_break_at: e.target.value },
                      })
                    }
                    setLangEnd={(e) => {
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, end_break_at: e.target.value },
                      })
                    }}
                    clear={() =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, start_break_at: "", end_break_at: "" },
                      })
                    } />
                </div>
              </div>
            </div>
            {props.forCustomer && <div className="modal-input-section" style={{
              marginTop: "2px",
              flexDirection: "row",
              alignItems: "flex-end",
            }}>
              <div className="input-section" style={{ width: "62px", alignItems: "flex-start" }}>
                <div className="input-label" style={{ whiteSpace: "nowrap" }}>{trans("off_salary")}</div>
                <div className="input-holder" style={{ marginTop: "5px" }}>
                  <SelectInput
                    noFloat={true}
                    data={currencies}
                    disabled={mode === "read"}
                    pad={false}
                    lang={data?.official_salary_currency_id}
                    setLang={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, official_salary_currency_id: e },
                      })
                    }
                  />
                </div>
              </div>
              <div className="input-section" style={{ width: "106px", marginLeft: "10px", alignItems: "flex-start" }}>
                <div className="input-label"></div>
                <div className="input-holder">
                  <InputText
                    noFloat={true}
                    disabled={mode === "read"}
                    placeholder="09:00 - 17:00"
                    pad={false}
                    lang={getNumSalary(data?.official_salary)}
                    setLang={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, official_salary: e.target.value },
                      })
                    }
                    clear={() =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, official_salary: "" },
                      })
                    } />
                </div>
              </div>
              <div className="input-section" style={{ width: "62px", marginLeft: "32px", alignItems: "flex-start" }}>
                <div className="input-label"> {trans("emp_salar")}</div>
                <div className="input-holder" style={{ marginTop: "5px" }}>
                  <SelectInput
                    data={currencies}
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    lang={data?.salary_currency_id}
                    setLang={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, salary_currency_id: e },
                      })
                    }
                  />
                </div>
              </div>
              <div className="input-section" style={{ width: "109px", marginLeft: "10px", alignItems: "flex-start" }}>
                <div className="input-label"></div>
                <div className="input-holder">
                  <InputText
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    lang={getNumSalary(data?.salary)}
                    setLang={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, salary: e.target.value },
                      })
                    }
                    clear={() =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, salary: "" },
                      })
                    } />
                </div>
              </div>
            </div>}
            <div className="modal-input-section" style={{
              marginTop: "2px",
              flexDirection: "row",
              alignItems: "flex-end",
            }}>
              <div className="input-section" style={{ width: "179px", alignItems: "flex-start" }}>
                <div className="input-label">{trans("emp_vac_days_week")}</div>
                <div className="input-holder">
                  <SelectDay
                    data={weekdays}
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    lang={data?.vacation_days}
                    setLang={(e) =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, vacation_days: e },
                      })
                    }
                    clear={() =>
                      dispatch({
                        type: "NEW-EMP",
                        payload: { ...data, vacation_days: [] },
                      })
                    } />
                </div>
              </div>
            </div>
          </>
        </>)
    }
    else if (modal_sec === 1) {

      return (
        <>
          <div
            className="modal-input-section"
            style={{
              marginTop: "25px",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div className="qr-container">
              {data.id ? <QRCode
                size={50}
                style={{ height: "50px", maxWidth: "50px", width: "50px" }}
                value={data.id?.toString()}
                viewBox={`0 0 50 50`}
              /> :
                <svg data-src={qr}></svg>
              }
            </div>
            <div className="job-emp">
              <div className="jop-code">RTA-700{data.id ?? "XX"}</div>
              <div className="dept-emp" style={{ marginTop: "7px" }}>
                {data?.department && data?.department[0] && data?.department[0].short_name && <svg
                  className="dept-child"
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="15"
                  viewBox="0 0 23 15"
                >
                  <g id="_15x23_flag_photo" data-name="15x23 flag photo">
                    <rect
                      id="Rectangle_4601"
                      data-name="Rectangle 4601"
                      width="23"
                      height="15"
                      rx="2"
                      fill="#ddd"
                    />
                  </g>
                  <text
                    textAnchor="middle"
                    id="p8"
                    transform="translate(0 2)"
                    fill="#404040"
                    fontSize="8"
                    fontFamily="SegoeUI, Segoe UI"
                    letterSpacing="0.025em"
                  >
                    <tspan x="50%" y="9">
                      {data?.department[0].short_name}
                    </tspan>
                  </text>
                </svg>}

                <span style={{ marginLeft: "5px" }} className="job-name">
                  {data?.job_title_id && jobs?.filter((j) => j.id === data?.job_title_id)[0]?.translations?.filter((t) => t.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].name}
                </span>
              </div>
            </div>
          </div>
          <div
            className="modal-input-section"
            style={{
              marginTop: "15px",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <div
              className="img-comp"
              style={{ display: "flex", minWidth: "60px", width: "60px", height: "70px" }}
            >
              {data?.avatar === "pending" ? (
                <LoadingImage onClick={() => updatPhoto("avatar")} />
              ) : !data?.avatar ? (
                <svg
                  onClick={() => updatPhoto("avatar")}
                  data-src={imgUp}
                ></svg>
              ) : (
                <img alt=""
                  onClick={() => updatPhoto("avatar")}
                  style={{
                    width: "60px",
                    height: "70px",
                    borderRadius: "10px",
                  }}
                  className="id_photo"
                  src={FILE_SERVER + data?.avatar}
                />
              )}
            </div>
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "20px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "8px" }}>
                {trans("emp_full_name")}
              </div>
              <div className="input-holder" style={{ width: "308px" }}>
                <InputText
                  noFloat={true}
                  disabled={mode === "read"}
                  pad={false}
                  lang={data?.full_name}
                  setLang={(e) =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, full_name: e.target.value },
                    })
                  }
                  clear={() =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, full_name: "" },
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div
            className="modal-input-section"
            style={{
              marginTop: "12px",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <div
              className="img-comp"
              style={{ display: "flex", minWidth: "60px", height: "41px" }}
            >
              {data?.passport_photo_path === "pending" ? (
                <LoadingImage onClick={() => updatPhoto("passport_photo_path")} />
              ) : !data?.passport_photo_path ? (
                <svg
                  onClick={() => updatPhoto("passport_photo_path")}
                  data-src={passup}
                ></svg>
              ) : (
                <img alt=""
                  onClick={() => updatPhoto("passport_photo_path")}
                  style={{
                    width: "60px",
                    height: "41px",
                    borderRadius: "10px",
                  }}
                  className="id_photo"
                  src={FILE_SERVER + data?.passport_photo_path}
                />
              )}
            </div>
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "20px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("passport_no")}
              </div>
              <div className="input-holder" style={{ width: "308px" }}>
                <InputText
                  noFloat={true}
                  pad={false}
                  disabled={mode === "read"}
                  lang={data?.passport_number}
                  setLang={(e) =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, passport_number: e.target.value },
                    })
                  }
                  clear={() =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, passport_number: "" },
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div
            className="modal-input-section"
            style={{
              marginTop: "12px",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <div
              className="img-comp"
              style={{ display: "flex", minWidth: "60px", width: "60px", height: "41px" }}
            >
              {data?.id_photo_path === "pending" ? (
                <LoadingImage onClick={() => updatPhoto("id_photo_path")} />
              ) : !data?.id_photo_path ? (
                <svg
                  onClick={() => updatPhoto("id_photo_path")}
                  data-src={passup}
                ></svg>
              ) : (
                <img alt=""
                  onClick={() => updatPhoto("id_photo_path")}
                  style={{
                    width: "60px",
                    height: "41px",
                    borderRadius: "10px",
                  }}
                  className="id_photo"
                  src={FILE_SERVER + data?.id_photo_path}
                />
              )}
            </div>
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "20px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("emp_id_no")}
              </div>
              <div className="input-holder" style={{ width: "98px" }}>
                <InputText
                  noFloat={true}
                  pad={false}
                  lang={data?.id_no}
                  disabled={mode === "read"}
                  setLang={(e) =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, id_no: e.target.value },
                    })
                  }
                  clear={() =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, id_no: "" },
                    })
                  }
                />
              </div>
            </div>
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "30px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("emp_iss_from")}
              </div>
              <div
                className="input-holder"
                id="issued_from1"
                style={{ width: "179px" }}
              >
                <AsyncInput
                  noFloat={true}
                  disabled={mode === "read"}
                  pad={false}
                  lang={data?.id_issued_from}
                  setLang={(e) => {
                    // dispatch({ type: "ASYNC_REG", payload: e.target.value });
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, id_issued_from: e.target.value },
                    });
                  }}
                  clear={() =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, id_issued_from: "" },
                    })
                  }
                />

              </div>
            </div>
          </div>
          <div
            className="modal-input-section"
            style={{
              marginTop: "12px",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >

            <div
              className="img-comp"
              style={{ display: "flex", minWidth: "60px", width: "60px", height: "41px" }}
            >
              {data?.driving_license_photo_path === "pending" ? (
                <LoadingImage />
              ) : !data?.driving_license_photo_path ? (
                <svg
                  onClick={() => updatPhoto("driving_license_photo_path")}
                  data-src={DrivUp}
                ></svg>
              ) : (
                <img alt=""
                  style={{
                    width: "60px",
                    height: "41px",
                    borderRadius: "10px",
                  }}
                  className="id_photo"
                  src={FILE_SERVER + data?.driving_license_photo_path}
                />
              )}
            </div>

            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "20px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("emp_drv_lic_no")}
              </div>
              <div className="input-holder" style={{ width: "98px" }}>
                <InputText
                  noFloat={true}
                  pad={false}
                  disabled={mode === "read"}
                  lang={data?.driving_license_no}
                  setLang={(e) =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: {
                        ...data,
                        driving_license_no: e.target.value,
                      },
                    })
                  }
                  clear={() =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, driving_license_no: "" },
                    })
                  }
                />
              </div>
            </div>
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "30px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("emp_iss_from")}
              </div>
              <div
                className="input-holder"
                id="issued_from2"
                style={{ width: "179px" }}
              >
                <AsyncInput
                  onFocus={() => setSourceFocuseReg1(true)}
                  onBlur={() => setSourceFocuseReg1(false)}
                  noFloat={true}
                  disabled={mode === "read"}
                  pad={false}
                  lang={data?.driving_license_issued_from}
                  setLang={(e) => {
                    // dispatch({ type: "ASYNC_REG", payload: e.target.value });
                    dispatch({
                      type: "NEW-EMP",
                      payload: {
                        ...data,
                        driving_license_issued_from: e.target.value,
                      },
                    });
                  }}
                  clear={() =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, driving_license_issued_from: "" },
                    })
                  }
                />

              </div>
            </div>
          </div>
          <div
            className="modal-input-section"
            style={{
              marginTop: "12px",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "2px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("emp_citizen")}
              </div>
              <div
                className="input-holder"
                id="issued_from5"
                style={{ width: "179px" }}
              >
                <AsyncInput
                  onFocus={() => setSourceFocuseReg2(true)}
                  onBlur={() => setSourceFocuseReg2(false)}
                  noFloat={true}
                  disabled={mode === "read"}
                  pad={false}
                  lang={data?.citizen_country_name}
                  setLang={(e) => {
                    dispatch({ type: "ASYNC_REG_count", payload: e.target.value });
                    dispatch({
                      type: "NEW-EMP",
                      payload: {
                        ...data,
                        citizen_country_name: e.target.value,
                      },
                    });
                  }}
                  clear={() =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, citizen_country_name: "" },
                    })
                  }
                />
                {reg_loading && sourceFocuseReg2 && (
                  <span
                    className="locat locat_static"
                    style={{ top: "-4px" }}
                  >
                    <Spinner />
                  </span>
                )}
                {data?.citizen_country_name &&
                  data?.citizen_country_name.length > 0 &&
                  sourceFocuseReg2 &&
                  !reg_loading &&
                  regs.length > 0 && (
                    <div className="input-sync">
                      {regs?.forEach((a) => (
                        <div
                          className="input-sync-item"
                          onClick={() => {
                            dispatch({
                              type: "NEW-EMP",
                              payload: {
                                ...data,
                                citizen_country_name:
                                  a.region_translations.filter(
                                    (a) => a.language_code === (localStorage.getItem("APP_LANG") ?? "en")
                                  )[0].region_name,
                              },
                            });
                            setTimeout(() => {
                              dispatch({
                                type: "NEW-EMP",
                                payload: {
                                  ...data,
                                  citizen_country_id: a.id,
                                  citizen_country_name:
                                    a.region_translations.filter(
                                      (a) => a.language_code === (localStorage.getItem("APP_LANG") ?? "en")
                                    )[0].region_name,
                                },
                              });
                            }, 600);
                          }}
                        >
                          <div className="input-sync-text">
                            {
                              a.region_translations.filter(
                                (a) => a.language_code === (localStorage.getItem("APP_LANG") ?? "en")
                              )[0].region_name
                            }
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </div>
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "30px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("emp_plc_of_res")}
              </div>
              <div
                className="input-holder"
                id="issued_from3"
                style={{ width: "179px" }}
              >
                <InputText
                  noFloat={true}
                  disabled={mode === "read"}
                  pad={false}
                  lang={data?.residence}
                  setLang={(e) => {
                    // dispatch({ type: "ASYNC_REG", payload: e.target.value });
                    dispatch({
                      type: "NEW-EMP",
                      payload: {
                        ...data,
                        residence: e.target.value,
                      },
                    });
                  }}
                  clear={() =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, residence: "" },
                    })
                  }
                />
                {/*<AsyncInput*/}
                {/*  onFocus={() => setSourceFocuseReg3(true)}*/}
                {/*  onBlur={() => setSourceFocuseReg3(false)}*/}
                {/*  noFloat={true}*/}
                {/*  disabled={mode === "read"}*/}
                {/*  pad={false}*/}
                {/*  lang={data?.residence}*/}
                {/*  setLang={(e) => {*/}
                {/*                          // dispatch({ type: "ASYNC_REG", payload: e.target.value });*/}
                {/*    dispatch({*/}
                {/*      type: "NEW-EMP",*/}
                {/*      payload: { ...data, residence: e.target.value },*/}
                {/*    });*/}
                {/*  }}*/}
                {/*  clear={() =>*/}
                {/*    dispatch({*/}
                {/*      type: "NEW-EMP",*/}
                {/*      payload: { ...data, residence: "" },*/}
                {/*    })*/}
                {/*  }*/}
                {/*/>*/}

              </div>
            </div>
          </div>
          <div
            className="modal-input-section"
            style={{
              marginTop: "12px",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "2px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("emp_birthday")}
              </div>
              <div className="input-holder" style={{ width: "179px" }}>
                <input
                  type={"date"}
                  className="cal"
                  onChange={(e) =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, birthdate: e.target.value },
                    })
                  }
                  value={
                    data?.birthdate?.includes(" ")
                      ? data?.birthdate.split(" ")[0]
                      : data?.birthdate
                  }
                />
                <InputText
                  noFloat={true}
                  disabled={mode === "read"}
                  pad={false}
                  lang={getDates(data?.birthdate)}
                  setLang={(e) =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, birthdate: e.target.value },
                    })
                  }
                  clear={() =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, birthdate: "" },
                    })
                  }
                />
              </div>
            </div>
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "30px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("plac_of_bir")}
              </div>
              <div
                className="input-holder"
                id="issued_from4"
                style={{ width: "179px" }}
              >
                <AsyncInput

                  noFloat={true}
                  disabled={mode === "read"}
                  pad={false}
                  lang={data?.city}
                  setLang={(e) => {
                    // dispatch({ type: "ASYNC_REG", payload: e.target.value });
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, city: e.target.value },
                    });
                  }}
                  clear={() =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, city: "" },
                    })
                  }
                />


              </div>
            </div>
          </div>
          <div
            className="modal-input-section"
            style={{
              marginTop: "12px",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "2px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("emp_address")}
              </div>
              <div className="input-holder" style={{ width: "100%" }}>
                <InputText
                  noFloat={true}
                  pad={false}
                  disabled={mode === "read"}
                  lang={data?.full_address}
                  setLang={(e) =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, full_address: e.target.value },
                    })
                  }
                  clear={() =>
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, full_address: "" },
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div
            className="modal-input-section"
            style={{
              marginTop: "12px",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "2px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("gsm")}
              </div>
              <div className="input-holder" style={{ width: "179px" }}>
                <InputText
                  noFloat={true}
                  disabled={mode === "read"}
                  pad={false}
                  lang={getPhone(data?.contact_infos[0].phone_number)}
                  setLang={(e) => {
                    let arr = [];
                    data?.contact_infos?.forEach((a, index) => {
                      if (index === 0) {
                        arr.push({ ...a, phone_number: e.target.value });
                      } else {
                        arr.push({ ...a });
                      }
                    });
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, contact_infos: [...arr] },
                    });
                  }}
                  clear={() => {
                    let arr = [];
                    data?.contact_infos?.forEach((a, index) => {
                      if (index === 0) {
                        arr.push({ ...a, phone_number: "" });
                      } else {
                        arr.push({ ...a });
                      }
                    });
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, contact_infos: [...arr] },
                    });
                  }}
                />
              </div>
            </div>
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "30px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("gsm_2")}
              </div>
              <div className="input-holder" style={{ width: "179px" }}>
                <InputText
                  noFloat={true}
                  disabled={mode === "read"}
                  pad={false}
                  lang={getPhone(data?.contact_infos[1].phone_number)}
                  setLang={(e) => {
                    let arr = [];
                    data?.contact_infos?.forEach((a, index) => {
                      if (index === 1) {
                        arr.push({ ...a, phone_number: e.target.value });
                      } else {
                        arr.push({ ...a });
                      }
                    });
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, contact_infos: [...arr] },
                    });
                  }}
                  clear={() => {
                    let arr = [];
                    data?.contact_infos?.forEach((a, index) => {
                      if (index === 1) {
                        arr.push({ ...a, phone_number: "" });
                      } else {
                        arr.push({ ...a });
                      }
                    });
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, contact_infos: [...arr] },
                    });
                  }}
                />{" "}
              </div>
            </div>
          </div>
          <div
            className="modal-input-section"
            style={{
              marginTop: "12px",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "2px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("close_pers_name")}
              </div>
              <div className="input-holder" style={{ width: "179px" }}>
                <InputText
                  noFloat={true}
                  disabled={mode === "read"}
                  pad={false}
                  lang={data?.contact_infos[2].contact_name}
                  setLang={(e) => {
                    let arr = [];
                    data?.contact_infos?.forEach((a, index) => {
                      if (index === 2) {
                        arr.push({ ...a, contact_name: e.target.value });
                      } else {
                        arr.push({ ...a });
                      }
                    });
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, contact_infos: [...arr] },
                    });
                  }}
                  clear={() => {
                    let arr = [];
                    data?.contact_infos?.forEach((a, index) => {
                      if (index === 2) {
                        arr.push({ ...a, contact_name: "" });
                      } else {
                        arr.push({ ...a });
                      }
                    });
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, contact_infos: [...arr] },
                    });
                  }}
                />{" "}
              </div>
            </div>
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "30px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("close_pers_gsm")}
              </div>
              <div className="input-holder" style={{ width: "179px" }}>
                <InputText
                  disabled={mode === "read"}
                  noFloat={true}
                  pad={false}
                  lang={getPhone(data?.contact_infos[2].phone_number)}
                  setLang={(e) => {
                    let arr = [];
                    data?.contact_infos?.forEach((a, index) => {
                      if (index === 2) {
                        arr.push({ ...a, phone_number: e.target.value });
                      } else {
                        arr.push({ ...a });
                      }
                    });
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, contact_infos: [...arr] },
                    });
                  }}
                  clear={() => {
                    let arr = [];
                    data?.contact_infos?.forEach((a, index) => {
                      if (index === 2) {
                        arr.push({ ...a, phone_number: "" });
                      } else {
                        arr.push({ ...a });
                      }
                    });
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, contact_infos: [...arr] },
                    });
                  }}
                />{" "}
              </div>
            </div>
          </div>
          <div
            className="modal-input-section"
            style={{
              marginTop: "12px",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "2px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("ref_by")}
              </div>
              <div className="input-holder" style={{ width: "179px" }}>
                <InputText
                  noFloat={true}
                  pad={false}
                  disabled={mode === "read"}
                  lang={data?.contact_infos[3].contact_name}
                  setLang={(e) => {
                    let arr = [];
                    data?.contact_infos?.forEach((a, index) => {
                      if (index === 3) {
                        arr.push({ ...a, contact_name: e.target.value });
                      } else {
                        arr.push({ ...a });
                      }
                    });
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, contact_infos: [...arr] },
                    });
                  }}
                  clear={() => {
                    let arr = [];
                    data?.contact_infos?.forEach((a, index) => {
                      if (index === 3) {
                        arr.push({ ...a, contact_name: "" });
                      } else {
                        arr.push({ ...a });
                      }
                    });
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, contact_infos: [...arr] },
                    });
                  }}
                />{" "}
              </div>
            </div>
            <div
              className="input-section"
              style={{
                marginTop: "0px",
                alignItems: "flex-start",
                marginLeft: "30px",
              }}
            >
              <div className="input-label" style={{ marginBottom: "7px" }}>
                {trans("ref_gsm")}
              </div>
              <div className="input-holder" style={{ width: "179px" }}>
                <InputText
                  noFloat={true}
                  disabled={mode === "read"}
                  pad={false}
                  lang={getPhone(data?.contact_infos[3].phone_number)}
                  setLang={(e) => {
                    let arr = [];
                    data?.contact_infos?.forEach((a, index) => {
                      if (index === 3) {
                        arr.push({ ...a, phone_number: e.target.value });
                      } else {
                        arr.push({ ...a });
                      }
                    });
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, contact_infos: [...arr] },
                    });
                  }}
                  clear={() => {
                    let arr = [];
                    data?.contact_infos?.forEach((a, index) => {
                      if (index === 3) {
                        arr.push({ ...a, phone_number: "" });
                      } else {
                        arr.push({ ...a });
                      }
                    });
                    dispatch({
                      type: "NEW-EMP",
                      payload: { ...data, contact_infos: [...arr] },
                    });
                  }}
                />{" "}
              </div>
            </div>
          </div>
        </>
      );
    }

  }


  return (
    <>
      {mode !== "read" && (
        <div
          onClick={(e) => shake(e)}
          className={`lang-modalDisable ${open && "open"}`}
        ></div>
      )}
      <div
        className={`lang-modal ${open && "open"} ${props.fixModal}`}
        style={{ height: `${data?.department_id ? "862px" : "171px"}` }}
      >
        <div className="modal-top">
          <div className="modal-top-title">
            {mode === "add" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <defs>
                  <clipPath id="clip-path">
                    <rect
                      id="Rectangle_4527"
                      data-name="Rectangle 4527"
                      width="15"
                      height="15"
                      transform="translate(324 333)"
                      fill="none"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Mask_Group_16"
                  data-name="Mask Group 16"
                  transform="translate(-324 -333)"
                  clipPath="url(#clip-path)"
                >
                  <path
                    id="add-3"
                    d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z"
                    transform="translate(324 333)"
                    fill="#404040"
                  />
                </g>
              </svg>
            )}
            {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

            {mode === "add" ? (
              <div className="modal-title">{trans("add_new_emp")}</div>
            ) : (
              <div className="modal-title">
                {mode === "update" ? trans("update") : ""} {trans("emp")}
              </div>
            )}
          </div>
          {mode !== "read" ? (
            <div className="modal-top-buttons">
              <div
                className={`add-button ${!ButtonValidate() && "disabled-button"
                  }`}
                onClick={() => {
                  sendRequest();
                  if (ButtonValidate()) {
                    props.Close();
                  }
                }}
              >
                {mode === "add" ? trans("add") : trans("update")}
              </div>
              <div
                className="close-button"
                onClick={() => {
                  setOpen(false);
                  props.Close();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.9"
                  height="9.899"
                  viewBox="0 0 9.9 9.899"
                >
                  <g
                    id="Group_4637"
                    data-name="Group 4637"
                    transform="translate(-224.153 -513.36) rotate(45)"
                  >
                    <line
                      id="Line_962"
                      data-name="Line 962"
                      x1="12"
                      transform="translate(522.5 204.5)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                    <line
                      id="Line_963"
                      data-name="Line 963"
                      x1="12"
                      transform="translate(528.5 198.5) rotate(90)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </div>
            </div>
          ) : (
            <div className="modal-top-buttons">
              <div
                className="icon-lang marg"
                onClick={() => {
                  props.Update(props.data);
                }}
              >
                <div className="icon-lang-desc">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <filter
                        id="Ellipse_319"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.2" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#Ellipse_319)"
                    >
                      <circle
                        id="Ellipse_319-2"
                        data-name="Ellipse 319"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(9 6)"
                        fill="#404040"
                      />
                    </g>
                  </svg>
                  {/* ATTENTION!! ADDING CONDITION NEXT LINE */}
                  <div
                    className="icon-lang-desc-bu"
                    style={{ whiteSpace: "nowrap", left: "-24px" }}
                  >
                    {trans("edit")}
                  </div>
                </div>
                <svg
                  className="gray-color"
                  id="_15x15_photo_back"
                  data-name="15x15 photo back"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                >
                  <g data-name="Mask Group 65">
                    <g
                      id="Group_4666"
                      data-name="Group 4666"
                      transform="translate(0.063 0)"
                    >
                      <g id="user" transform="translate(0.258)">
                        <path
                          id="Path_16021"
                          data-name="Path 16021"
                          d="M13.436,24.162l1.583-.366L13.8,22.579Z"
                          transform="translate(-6.428 -9.162)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16022"
                          data-name="Path 16022"
                          d="M9.213,18.943l.262-1.136v-.047l.006-.014v-.009l.008-.014v-.008L9.5,17.7l.006-.008.011-.012h0l3.531-3.531a5.421,5.421,0,0,0-4.867-3.239A5.239,5.239,0,0,0,4.19,12.762a7.346,7.346,0,0,0-1.668,4.726A13.977,13.977,0,0,0,8.178,19a10.04,10.04,0,0,0,1.035-.055Z"
                          transform="translate(-2.521 -4.985)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16023"
                          data-name="Path 16023"
                          d="M0,0H5.927V2.156H0Z"
                          transform="translate(7.595 12.882) rotate(-45)"
                          fill="#8e8e8e"
                        />
                        <circle
                          id="Ellipse_318"
                          data-name="Ellipse 318"
                          cx="2.694"
                          cy="2.694"
                          r="2.694"
                          transform="translate(2.963)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16024"
                          data-name="Path 16024"
                          d="M23.32,15.209a1.078,1.078,0,0,0-1.84-.762L23,15.966A1.07,1.07,0,0,0,23.32,15.209Z"
                          transform="translate(-9.309 -6.137)"
                          fill="#8e8e8e"
                        />
                      </g>
                      <rect
                        id="Rectangle_4536"
                        data-name="Rectangle 4536"
                        width="14.875"
                        height="14.875"
                        transform="translate(0 0.125)"
                        fill="none"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div
                className="close-button"
                onClick={() => {
                  props.Close();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.9"
                  height="9.899"
                  viewBox="0 0 9.9 9.899"
                >
                  <g
                    id="Group_4637"
                    data-name="Group 4637"
                    transform="translate(-224.153 -513.36) rotate(45)"
                  >
                    <line
                      id="Line_962"
                      data-name="Line 962"
                      x1="12"
                      transform="translate(522.5 204.5)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                    <line
                      id="Line_963"
                      data-name="Line 963"
                      x1="12"
                      transform="translate(528.5 198.5) rotate(90)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </div>
            </div>
          )}
        </div>
        <div className="modal-body">
          {data?.department_id ? (
            <>
              <div
                className="modal-input-section"
                style={{ marginTop: "-5px" }}
              >
                <div
                  className="icons-header"
                  style={{ position: "relative", width: "100%" }}
                >
                  <div
                    onClick={() => setModal_sec(4)}
                    className={
                      `icon-header  ${modal_sec === 4 && "active"}`
                    }
                  >
                    <Links />
                  </div>
                  <div
                    onClick={() => setModal_sec(3)}
                    className={
                      `icon-header  ${modal_sec === 3 && "active"}`
                    }
                  >
                    <Images />
                  </div>
                  <div
                    onClick={() => setModal_sec(2)}
                    className={
                      `icon-header  ${modal_sec === 2 && "active"}`
                    }
                  >
                    <Docs />
                  </div>
                  <div
                    onClick={() => setModal_sec(5)}
                    className={
                      `icon-header  ${modal_sec === 5 && "active"}`
                    }
                  >
                    <Accounting />
                  </div>
                  <div
                    onClick={() => setModal_sec(6)}
                    className={
                      `icon-header  ${modal_sec === 6 && "active"}`
                    }
                  >
                    <Card />
                  </div>
                  <div
                    onClick={() => setModal_sec(1)}
                    className={
                      `icon-header  ${modal_sec === 1 && "active"}`
                    }
                  >
                    <Info />
                  </div>
                  <div
                    className="icon-header"
                    style={{ position: "absolute", right: "0px" }}
                  >
                    <svg
                      id="_15x15_photo_back"
                      data-name="15x15 photo back"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                    >
                      <g id="Mask_Group_271" data-name="Mask Group 271">
                        <g id="scan" transform="translate(-1.5 -1.5)">
                          <path
                            id="Path_21255"
                            data-name="Path 21255"
                            d="M15.122,11.455a.6.6,0,0,0-.612.614V13.6a.932.932,0,0,1-.918.92H12.061a.6.6,0,0,0-.612.614.564.564,0,0,0,.612.614h1.531A2.142,2.142,0,0,0,15.735,13.6V12.068a.659.659,0,0,0-.612-.614Z"
                            fill="#4b68ff"
                          />
                          <path
                            id="Path_21256"
                            data-name="Path 21256"
                            d="M12.061,3.477h1.531a.932.932,0,0,1,.918.92V5.932a.6.6,0,0,0,.612.614.564.564,0,0,0,.612-.614V4.4A2.142,2.142,0,0,0,13.592,2.25H12.061a.614.614,0,0,0,0,1.227Z"
                            fill="#4b68ff"
                          />
                          <path
                            id="Path_21257"
                            data-name="Path 21257"
                            d="M5.939,14.523H4.408a.932.932,0,0,1-.918-.92V12.068a.612.612,0,1,0-1.224,0V13.6A2.142,2.142,0,0,0,4.408,15.75H5.939a.614.614,0,0,0,0-1.227Z"
                            fill="#4b68ff"
                          />
                          <path
                            id="Path_21258"
                            data-name="Path 21258"
                            d="M2.878,6.545a.6.6,0,0,0,.612-.614V4.4a.932.932,0,0,1,.918-.92H5.939a.614.614,0,0,0,0-1.227H4.408A2.193,2.193,0,0,0,2.265,4.4V5.932a.659.659,0,0,0,.612.614Z"
                            fill="#4b68ff"
                          />
                          <path
                            id="Path_21259"
                            data-name="Path 21259"
                            d="M12.061,13.6a1.537,1.537,0,0,0,1.531-1.534V10.688H4.408v1.381A1.537,1.537,0,0,0,5.939,13.6Z"
                            fill="#4b68ff"
                          />
                          <path
                            id="Path_21260"
                            data-name="Path 21260"
                            d="M15.888,8.386h-2.3V5.932A1.537,1.537,0,0,0,12.061,4.4H5.939A1.537,1.537,0,0,0,4.408,5.932V8.54h-2.3a.6.6,0,0,0-.612.614.564.564,0,0,0,.612.614H15.888a.6.6,0,0,0,.612-.614.757.757,0,0,0-.612-.767Z"
                            fill="#4b68ff"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              {showData()}
            </>
          ) : (
            <>
              <div className="modal-input-section" style={{ marginTop: "0px" }}>
                <div className="modal-icon-name">{trans("emps_dep")}</div>
                <div className="input-section">
                  <div className="input-holder">
                    <div
                      className="full-input-element pa"
                      style={{ width: "100%" }}
                    >
                      <AsyncInput
                        onFocus={() => setSourceFocuse(true)}
                        onBlur={() => setSourceFocuse(false)}
                        placeholder="Department"
                        noFloat={true}
                        disabled={mode === "read"}
                        pad={false}
                        clear={() => { }}
                        lang={data?.dept ? data?.dept : ""}
                        setLang={(e) => {
                          dispatch({
                            type: "ASYNC_DEPT",
                            payload: e.target.value,
                          });
                          dispatch({
                            type: "NEW-EMP",
                            payload: { ...data, dept: e.target.value },
                          });
                        }}
                      />
                      {loading ? (
                        <span className="locat locat_static">
                          <Spinner />
                        </span>
                      ) : (
                        <span className="locat">
                          <svg
                            style={{ position: "static" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.004"
                            height="12.004"
                            viewBox="0 0 12.004 12.004"
                          >
                            <g
                              id="Group_7628"
                              data-name="Group 7628"
                              transform="translate(0 0.001)"
                            >
                              <circle
                                id="Ellipse_328"
                                data-name="Ellipse 328"
                                cx="2.201"
                                cy="2.201"
                                r="2.201"
                                transform="translate(3.801 3.8)"
                                fill="#8e8e8e"
                              />
                              <path
                                id="Path_18940"
                                data-name="Path 18940"
                                d="M7.5,1.5a6,6,0,1,0,6,6A6,6,0,0,0,7.5,1.5Zm0,10.2a4.2,4.2,0,1,1,4.2-4.2,4.2,4.2,0,0,1-4.2,4.2Z"
                                transform="translate(-1.5 -1.501)"
                                fill="#8e8e8e"
                              />
                            </g>
                          </svg>
                        </span>
                      )}
                      {
                        sourceFocuse &&
                        !loading &&
                        depts.length > 0 && (
                          <div className="input-sync">
                            {depts?.map((a) => (
                              <div
                                className="input-sync-item"
                                onClick={() => {
                                  dispatch({
                                    type: "NEW-EMP",
                                    payload: { ...data, dept: a.name },
                                  });
                                  setTimeout(() => {
                                    dispatch({
                                      type: "NEW-EMP",
                                      payload: {
                                        ...data,
                                        department: [a],
                                        department_id: a.id,
                                      },
                                    });
                                  }, 600);
                                }}
                              >
                                <div className="input-sync-text">{a.name}</div>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default DepartmentModal;
