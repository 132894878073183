import React from 'react'
import X from "./xicon.svg"
import FloatingCharacter from './floatingCharacter'
import pl from './plus.svg'
function InputText(props) {
    const {lang, setLang} = props
  return (
    <>
    {props.icon&&
    <svg className={` abs-icon ${props.lang.length>0&&"fill-norm"}`} data-src={props.icon} width="10" height={"10"}></svg>}
<input autoComplete={false}  prefix='USD' onClick={(e)=>{props.onFocus?props.onFocus(e):console.log()}} onBlur={(e)=>{if(props.setFocuse)setTimeout(() => {
  props.setFocuse(false)
}, 150); }} onFocus={(e)=>{props.setFocuse?props.setFocuse(true):console.log()}} id={props.id} placeholder={props.placeholder}  className={`${props.pad&&"pad35"} `} style={props.pad?{paddingLeft:"20px"}:{paddingLeft:"0px"}} readOnly={props.disabled} onChange={(e)=>{setLang(e)}} value={lang} inputMode={props.numeric?props.numeric[0]:""} type={props.numeric?props.numeric[0]:"text"}/>
{(props.loca)?
<>
  <svg onClick={()=>{setTimeout(() => {
    document.querySelector("#caland").focus()
  }, 100); }} className='cal' data-src={props.xIcon} width="15" height={"15"} ></svg>
  <input onfocus={(e)=>{this.showPicker()}} id="caland" type="date" value={props.date} onChange={(e)=>props.setDate(e)} style={{opacity:0}} className='cal'/>
</>

  : props.phone&&props.isEnd?
   <img alt="" className='pl' src={pl} onClick={(e)=>props.addPhone()}/>:
  props.amount?<img alt="" src={props.xIcon}/>:  <img onClick={()=>props.clear()} alt="close" src={X}/>}
{!props.noFloat&&<FloatingCharacter value={-(30 - props.lang&&lang.length)}/>}
    </>
  )
}

export default InputText