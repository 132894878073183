import axios from "axios";
import * as types from "./constants";
import { store } from "../store";
import { deleteToken } from "firebase/messaging";
import { messaging } from "../../firebaseInit";
export const LoginAction = (payload) => {
  return {
    type: types.LOGIN_SAGA,
    payload,
  };
};
export const LoginSucces = (payload) => {
  return {
    type: types.LOGIN_REDUCER,
    payload,
  };
};
export const logout = (callback) => {
  const AxiosInstance = axios.create({
    baseURL:
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public"
        : process.env.REACT_APP_BASE_URL +
        "/wallet-full-backend/public",
    timeout: 0,
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).access_token),
      current_role_id:
        localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          ? localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          : "-1",
      // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
      "Content-Type": "application/json",
    },
  });
  const AxiosInstance2 = axios.create({
    baseURL:
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url
        : process.env.REACT_APP_BASE_URL + "/wallet-full-backend/public",
    timeout: 0,
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).access_token),
      current_role_id:
        localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          ? localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          : "-1",
      "Content-Type": "application/json",
    },
  });
  const remoteServer =
    store.getState().whatsapp.fetched_url === true
      ? store.getState()?.whatsapp.base_url + "/api/v1/auth/logout"
      : types.remote_server + "/api/v1/auth/logout";
  AxiosInstance2.delete(
    "/api/v1/firebase_device_tokens/" + localStorage.getItem("firebase_id"),
  ).then(data => {
    deleteToken(messaging)
    AxiosInstance.post(remoteServer)
  })
};
export const Signup = (payload) => {
  return {
    type: types.SIGNUP_SAGA,
    payload,
  };
};
export const SignupSucces = (payload) => {
  return {
    type: types.SIGNUP_REDUCER,
    payload,
  };
};
export const Forget = (payload) => {
  return {
    type: types.FORGET_SAGA,
    payload,
  };
};
export const ForgetSucces = (payload) => {
  return {
    type: types.FORGET_REDUCER,
    payload,
  };
};
export const wait = () => {
  return {
    type: types.WAIT,
  };
};
export const done = () => {
  return {
    type: types.DONE,
  };
};
export const error = () => {
  return {
    type: types.ERROR,
  };
};
export const set_session = (payload) => {
  return {
    type: "SESSION",
    payload,
  };
};
export const verify = (payload) => {
  return {
    type: types.VERIFY,
    payload,
  };
};
export const send_sms = (payload) => {
  return {
    type: types.SMS,
    payload,
  };
};
export const set_token = (payload) => {
  return {
    type: "TOKEN",
    payload,
  };
};
export const register = (payload) => {
  return {
    type: "REGISTER",
    payload,
  };
};
export const _setCountries = (payload) => {
  return {
    type: "STORE_COUNTRIES",
    payload,
  };
};
export const _getCountriesSaga = () => {
  return {
    type: types.GET_COUNTRIES_SAGA,
  };
};

