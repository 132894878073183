import React, {  useEffect, useState } from "react";
import InputText from "./inputs/InputText";
import { textMarshal } from "text-marshal";
import { useDispatch, useSelector } from "react-redux";
import { FILE_SERVER } from "../../../../../constants";
import { upload } from "../../../../../uploadService";
import Empty from "../../../../../assets/svg/empty.svg";
import LoadingImage from "../../../LoadingImage";
import "./liscenses.css";
import Info from "../../../../../assets/svg/Info";
import Docs from "../../../../../assets/svg/Docs";
import Images from "../../../../../assets/svg/Images";
import Links from "../../../../../assets/svg/Links";
import Spinner from "../../../Spinner";
import AddPdf from "./inputs/AddPdf";
import PDFElem from "./inputs/PDFElem";
import { trans } from '../../../../../Internationalization'
function CompainesModal(props) {
  const getPhone = (a) => {
    let d = textMarshal({
      input: a,
      template: `xxxxxxxxxxxxxxx`,
      disallowCharacters: [/[a-z]/],
    });
    return d.marshaltext;
  };
 const mode = useSelector((state) => state.liscenses.mode);
  const floading = useSelector((state) => state.liscenses.floading);
  const regs = useSelector((state) => state.liscenses.regs);
  const img_upload = useSelector((state) => state.liscenses.img_upload);
   const offices = useSelector((state) => state.liscenses.offices);
  const modalData = useSelector((state) => state.liscenses.modalData);
  const data = useSelector((state) => state.liscenses.newDepartment);

  const shake = (e) => {
    document.querySelector(".lang-modal.open").classList.add("shake-modal");
    setTimeout(() => {
      document
        .querySelector(".lang-modal.open")
        .classList.remove("shake-modal");
    }, 400);
  };
  const {
    open,
    setOpen,
  } = props;
  const dispatch = useDispatch();
  const ButtonValidate = () => {
    let bool = true;
    if (
      regs &&
      regs !== "error" &&
      data.name &&
      data.name.length > 0 &&
      data.domain &&
      data.domain.length > 0 &&
      data.country_id &&
      data.tax &&
      toString(data.tax).length > 0 &&
      data.approval_code &&
      data.approval_code.length > 0 &&
      data.post_code &&
      data.post_code.length > 0 &&
      data.No &&
      data.No.length > 0 &&
      data.date &&
      data.date.length > 0 &&
      data.pdf_files.length > 0
    )
      bool = true;
    else bool = false;
    return bool;
  };
  const updateImage = async (file, index) => {
    dispatch({
      type: "IMG-UPLOAD-LES",
      payload: { status: true, type: index },
    });

    let pat = await upload(file, index);
    let tmp = {
      ...data,
      pdf_files: [...data.pdf_files, { file_path: pat, description: "desc" }],
    };
    dispatch({ type: "NEW-LES", payload: { ...tmp } });
    dispatch({
      type: "IMG-UPLOAD-LES",
      payload: { status: false, type: null },
    });
    dispatch({ type: "LEVEL-DONE-LES" });
  };
  const updatPhoto = (index) => {
    if (mode !== "read") {
      let Image = document.createElement("input");
      Image.onchange = (e) => {
        updateImage(e.target.files[0], index);
      };
      Image.type = "file";
      Image.hidden = true;
      Image.accept = "application/pdf";
      Image.style = { position: "absolute", opacity: "0" };
      let i = document.body.appendChild(Image);
      i.click();
    }
  };
  useEffect(() => {
    if (mode !== "add") {
      dispatch({ type: "GET_REG_POST", payload: data.post_code });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);
  const sendRequest = () => {
    if (ButtonValidate()) {
      dispatch({ type: "LES-REG-DONE", payload: null });
      if (mode === "add") {
        dispatch({ type: "ADD-LES", payload: { ...data, no: data.No } });
      } else {
        dispatch({ type: "UPD-LES", payload: { ...data, no: data.No } });
      }
    } else {
      if (data.pdf_files.length === 0) {
        let a = document.querySelector(".pdf-cont");
        a.classList.add("shake-modal");

        setTimeout(() => {
          a.classList.remove("shake-modal");
        }, 400);
      }
      document.querySelectorAll(".modal-input-section input").forEach((inp) => {
        if (inp.value.length < 2) {
          inp.parentElement.classList.add("shake-modal");
          inp.classList.add("red-bord");
          setTimeout(() => {
            inp.parentElement.classList.remove("shake-modal");
          }, 400);
          setTimeout(() => {
            inp.classList.remove("red-bord");
          }, 1000);
        }
      });
    }
  };
  const [modal_sec, setModal_sec] = useState(1);
  const ShowData = () => {
    if (data.country_id) {
      return (
        <>
          <div className="modal-input-section" style={{ marginTop: "-5px" }}>
            <div className="icons-header">
              <div
                onClick={() => setModal_sec(4)}
                className={`icon-header  ${modal_sec === 4 && "active"}`}
              >
                <Links />
              </div>
              <div
                onClick={() => setModal_sec(3)}
                className={`icon-header ${modal_sec === 3 && "active"}`}
              >
                <Images />
              </div>
              <div
                onClick={() => setModal_sec(2)}
                className={`icon-header  ${modal_sec === 2 && "active"}`}
              >
                <Docs />
              </div>
              <div
                onClick={() => setModal_sec(1)}
                className={`icon-header  ${modal_sec === 1 && "active"}`}
              >
                <Info />
              </div>
            </div>
          </div>
          <div className="modal-input-section" style={{ marginTop: "22px" }}>
            <div className="modal-icon-name">{trans("lic_coun_pc")}</div>
            <div className="country-flags">
              {offices.forEach((a) => (
                <svg
                  className={`${data.country_id && data.country_id === a.id && "svg-act"
                    }`}
                  onClick={() => {
                    if (mode === "add")
                      dispatch({
                        type: "NEW-LES",
                        payload: { ...data, country_id: a.id },
                      });
                  }}
                  width={"23"}
                  height={"15"}
                  data-src={FILE_SERVER + a.flag_photo_path}
                  viewBox={"0 0 23 15"}
                ></svg>
              ))}
            </div>
            <div className="modal-input-section">
              <div className="modal-icon-name">{trans("lig_lic_nam")}</div>
              <div className="input-section lisc-inp">
                <div className="full-input-element" style={{ width: "100%" }}>
                  <InputText
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    clear={() => {
                      dispatch({
                        type: "NEW-LES",
                        payload: { ...data, name: "" },
                      });
                    }}
                    lang={data.name}
                    setLang={(e) => {
                      dispatch({
                        type: "NEW-LES",
                        payload: { ...data, name: e.target.value },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-input-section">
              <div className="modal-icon-name">{trans("lig_dom")}</div>
              <div className="input-section lisc-inp">
                <div className="full-input-element" style={{ width: "100%" }}>
                  <InputText
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    clear={() => {
                      dispatch({
                        type: "NEW-LES",
                        payload: { ...data, domain: "" },
                      });
                    }}
                    lang={data.domain}
                    setLang={(e) => {
                      dispatch({
                        type: "NEW-LES",
                        payload: { ...data, domain: e.target.value },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-input-section">
              <div className="modal-icon-name">{trans("lic_pc")}</div>
              <div className="input-section lisc-inp">
                {regs && regs !== "error" && !floading && (
                  <div className="reg-comp" style={{ marginTop: "0px" }}>
                    <div style={{ margin: "0px" }} className="reg-item">
                      {regs}
                    </div>
                  </div>
                )}
                <div className="input-holder">
                  <div
                    className="full-input-element pa"
                    style={{ width: "100%" }}
                  >
                    <InputText
                      placeholder="Post Code"
                      noFloat={true}
                      disabled={mode === "read"}
                      pad={false}
                      clear={() => { }}
                      lang={data.post_code}
                      setLang={(e) => {
                        dispatch({ type: "LES-REG-DONE", payload: null });
                        dispatch({
                          type: "NEW-LES",
                          payload: { ...data, post_code: e.target.value },
                        });
                      }}
                    />
                    {floading ? (
                      <span className="locat">
                        <Spinner />
                      </span>
                    ) : (
                      <>
                        {regs !== "error" ? (
                          <>
                            {" "}
                            {mode !== "read" && (
                              <span
                                className="locat"
                                onClick={() =>
                                  dispatch({
                                    type: "GET_REG_POST",
                                    payload: data.post_code,
                                  })
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12.004"
                                  height="12.004"
                                  viewBox="0 0 12.004 12.004"
                                >
                                  <g
                                    id="Group_7628"
                                    data-name="Group 7628"
                                    transform="translate(0 0.001)"
                                  >
                                    <circle
                                      id="Ellipse_328"
                                      data-name="Ellipse 328"
                                      cx="2.201"
                                      cy="2.201"
                                      r="2.201"
                                      transform="translate(3.801 3.8)"
                                      fill="#8e8e8e"
                                    />
                                    <path
                                      id="Path_18940"
                                      data-name="Path 18940"
                                      d="M7.5,1.5a6,6,0,1,0,6,6A6,6,0,0,0,7.5,1.5Zm0,10.2a4.2,4.2,0,1,1,4.2-4.2,4.2,4.2,0,0,1-4.2,4.2Z"
                                      transform="translate(-1.5 -1.501)"
                                      fill="#8e8e8e"
                                    />
                                  </g>
                                </svg>
                              </span>
                            )}
                          </>
                        ) : (
                          <span className="locat">
                            {
                              <svg
                                id="_15x15_photo_back"
                                data-name="15x15 photo back"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                              >
                                <g
                                  id="Mask_Group_128"
                                  data-name="Mask Group 128"
                                >
                                  <g
                                    id="Layer_1"
                                    transform="translate(-0.5 -0.5)"
                                  >
                                    <path
                                      id="Path_18928"
                                      data-name="Path 18928"
                                      d="M8,.5A7.5,7.5,0,1,0,15.5,8,7.5,7.5,0,0,0,8,.5Zm0,12a1,1,0,1,1,1-1A1,1,0,0,1,8,12.5ZM9.145,4.745,8.81,8.755a.813.813,0,0,1-1.62,0l-.335-4.01A1.149,1.149,0,1,1,9.15,4.65a.605.605,0,0,1-.005.095Z"
                                      fill="#ff6174"
                                    />
                                  </g>
                                </g>
                              </svg>
                            }
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-input-section">
              <div className="modal-icon-name">{trans("tax_off_no")}</div>
              <div className="input-section lisc-inp">
                <div
                  id="no_w"
                  className="trans-input-holder no-trans-hold"
                  style={{ width: "100%", flexWrap: "nowrap !important" }}
                >
                  <div
                    className="trans-input-element"
                    style={{ width: "109px" }}
                  >
                    <InputText
                      noFloat={true}
                      disabled={mode === "read"}
                      code={false}
                      pad={false}
                      clear={() => {
                        dispatch({
                          type: "NEW-LES",
                          payload: { ...data, tax: "" },
                        });
                      }}
                      lang={data.tax.toString()}
                      setLang={(e) => {
                        dispatch({
                          type: "NEW-LES",
                          payload: { ...data, tax: e.target.value },
                        });
                      }}
                    />
                  </div>
                  <div
                    className="trans-input-element no-trans-inp"
                    style={{ width: "268px", marginLeft: "10px" }}
                  >
                    <InputText
                      noFloat={true}
                      placeholder={"No  :"}
                      disabled={mode === "read"}
                      code={false}
                      pad={false}
                      clear={() => {
                        dispatch({
                          type: "NEW-LES",
                          payload: { ...data, no: "" },
                        });
                      }}
                      lang={getPhone(data.No)}
                      setLang={(e) => {
                        dispatch({
                          type: "NEW-LES",
                          payload: { ...data, No: e.target.value },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-input-section">
              <div className="modal-icon-name">
                {trans("st_dat_app_cd")}
              </div>
              <div className="input-section lisc-inp">
                <div
                  id="no_w"
                  className="trans-input-holder no-trans-hold"
                  style={{ width: "100%" }}
                >
                  <div
                    className="trans-input-element"
                    style={{ width: "109px" }}
                  >
                    <input
                      type={"date"}
                      className="cal"
                      onChange={(e) =>
                        dispatch({
                          type: "NEW-LES",
                          payload: { ...data, date: e.target.value },
                        })
                      }
                      value={
                        data.date.includes(" ")
                          ? data.date.split(" ")[0]
                          : data.date
                      }
                    />
                    <InputText
                      noFloat={true}
                      disabled={mode === "read"}
                      code={false}
                      pad={false}
                      clear={() => {
                        dispatch({
                          type: "NEW-LES",
                          payload: { ...data, date: "" },
                        });
                      }}
                      lang={
                        data.date.includes(" ")
                          ? data.date.split(" ")[0]
                          : data.date
                      }
                      setLang={(e) => { }}
                    />
                  </div>
                  <div
                    className="trans-input-element no-trans-inp"
                    style={{ width: "268px", marginLeft: "10px" }}
                  >
                    <InputText
                      noFloat={true}
                      placeholder={"No  :"}
                      disabled={mode === "read"}
                      code={false}
                      pad={false}
                      clear={() => {
                        dispatch({
                          type: "NEW-LES",
                          payload: { ...data, approval_code: "" },
                        });
                      }}
                      lang={data.approval_code}
                      setLang={(e) => {
                        dispatch({
                          type: "NEW-LES",
                          payload: { ...data, approval_code: e.target.value },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-input-section">
              <div className="modal-icon-name">{trans("lic_atta_pdf")}</div>
              <div className="input-section">
                <div
                  className="input-holder no-trans-hold"
                  style={{ width: "100%" }}
                >
                  <div className="pdf-cont">
                    {data.pdf_files.forEach((a, index) => (
                      <PDFElem clear={() => { let arr = []; data.pdf_files.forEach((item, ind) => { if (ind !== index) { arr.push(item); } dispatch({ type: "NEW-LES", payload: { ...data, pdf_files: arr } }); }) }} path={FILE_SERVER + a.file_path} />
                    ))}
                    {img_upload.status ? (
                      <div className="pdf-loading">
                        <LoadingImage />
                      </div>
                    ) : (
                      <AddPdf
                        action={() => {
                          updatPhoto(data.pdf_files.length);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="modal-input-section" style={{ marginTop: "0px" }}>
            <div className="modal-icon-name">{trans("lic_coun_pc")}</div>
            <div className="country-flags">
              {offices.forEach((a) => (
                <svg
                  className={`${data.country_id && data.country_id === a.id && "svg-act"
                    }`}
                  onClick={() =>
                    dispatch({
                      type: "NEW-LES",
                      payload: { ...data, country_id: a.id },
                    })
                  }
                  width={"23"}
                  height={"15"}
                  data-src={FILE_SERVER + a.flag_photo_path}
                  viewBox={"0 0 23 15"}
                ></svg>
              ))}
            </div>
            {/* <div className='input-section'>
              <div className='input-holder'>
           
              </div>
              </div> */}
          </div>
        </>
      );
    }
  };
  return (
    <>
      {mode !== "read" && (
        <div
          onClick={(e) => shake(e)}
          className={`lang-modalDisable ${open && "open"}`}
        ></div>
      )}
      <div
        style={{ width: "428px", height: data.country_id ? "862px" : "171px" }}
        className={`lang-modal ${open && "open"} ${props.fixModal}`}
      >
        <div className="modal-top">
          <div className="modal-top-title">
            {mode === "add" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <defs>
                  <clipPath id="clip-path">
                    <rect
                      id="Rectangle_4527"
                      data-name="Rectangle 4527"
                      width="15"
                      height="15"
                      transform="translate(324 333)"
                      fill="none"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Mask_Group_16"
                  data-name="Mask Group 16"
                  transform="translate(-324 -333)"
                  clipPath="url(#clip-path)"
                >
                  <path
                    id="add-3"
                    d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z"
                    transform="translate(324 333)"
                    fill="#404040"
                  />
                </g>
              </svg>
            ) : (
              <>
                {data.country_id ? (
                  <svg
                    className="emp-dept no-fill"
                    data-src={
                      FILE_SERVER +
                      offices.filter((a) => a.id === data.country_id)[0]
                        .flag_photo_path
                    }
                    width="23"
                    height="15"
                  ></svg>
                ) : (
                  <svg
                    className="emp-dept"
                    data-src={Empty}
                    width="23"
                    height="15"
                  ></svg>
                )}
              </>
            )}
            {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

            {mode === "add" ? (
              <div className="modal-title">{trans("add")} {trans("new_lic")}</div>
            ) : (
              <div className="modal-title">
                {mode === "update" ? trans("update") : ""}{" "}
                {`${modalData.name}`}{trans("lic")}
              </div>
            )}
          </div>
          {mode !== "read" ? (
            <div className="modal-top-buttons">
              <div
                className={`add-button ${!ButtonValidate() && "disabled-button"
                  }`}
                onClick={() => {
                  sendRequest();
                  if (ButtonValidate()) {
                    props.Close();
                   
                  }
                }}
              >
                {mode === "add" ? trans("add") : trans("update")}
              </div>
              <div
                className="close-button"
                onClick={() => {
                  setOpen(false);
                  props.Close();
                  dispatch({ type: "LES-REG-DONE", payload: null });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.9"
                  height="9.899"
                  viewBox="0 0 9.9 9.899"
                >
                  <g
                    id="Group_4637"
                    data-name="Group 4637"
                    transform="translate(-224.153 -513.36) rotate(45)"
                  >
                    <line
                      id="Line_962"
                      data-name="Line 962"
                      x1="12"
                      transform="translate(522.5 204.5)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                    <line
                      id="Line_963"
                      data-name="Line 963"
                      x1="12"
                      transform="translate(528.5 198.5) rotate(90)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </div>
            </div>
          ) : (
            <div className="modal-top-buttons">
              <div
                className="icon-lang marg"
                onClick={() => {
                  props.Update(props.data, props.data.parent_post_code);
                }}
              >
                <div className="icon-lang-desc">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <filter
                        id="Ellipse_319"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.2" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#Ellipse_319)"
                    >
                      <circle
                        id="Ellipse_319-2"
                        data-name="Ellipse 319"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(9 6)"
                        fill="#404040"
                      />
                    </g>
                  </svg>
                  {/* ATTENTION!! ADDING CONDITION NEXT LINE */}
                  <div
                    className="icon-lang-desc-bu"
                    style={{ whiteSpace: "nowrap", left: "-24px" }}
                  >
                    {trans("edit")}
                  </div>
                </div>
                <svg
                  className="gray-color"
                  id="_15x15_photo_back"
                  data-name="15x15 photo back"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                >
                  <g data-name="Mask Group 65">
                    <g
                      id="Group_4666"
                      data-name="Group 4666"
                      transform="translate(0.063 0)"
                    >
                      <g id="user" transform="translate(0.258)">
                        <path
                          id="Path_16021"
                          data-name="Path 16021"
                          d="M13.436,24.162l1.583-.366L13.8,22.579Z"
                          transform="translate(-6.428 -9.162)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16022"
                          data-name="Path 16022"
                          d="M9.213,18.943l.262-1.136v-.047l.006-.014v-.009l.008-.014v-.008L9.5,17.7l.006-.008.011-.012h0l3.531-3.531a5.421,5.421,0,0,0-4.867-3.239A5.239,5.239,0,0,0,4.19,12.762a7.346,7.346,0,0,0-1.668,4.726A13.977,13.977,0,0,0,8.178,19a10.04,10.04,0,0,0,1.035-.055Z"
                          transform="translate(-2.521 -4.985)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16023"
                          data-name="Path 16023"
                          d="M0,0H5.927V2.156H0Z"
                          transform="translate(7.595 12.882) rotate(-45)"
                          fill="#8e8e8e"
                        />
                        <circle
                          id="Ellipse_318"
                          data-name="Ellipse 318"
                          cx="2.694"
                          cy="2.694"
                          r="2.694"
                          transform="translate(2.963)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16024"
                          data-name="Path 16024"
                          d="M23.32,15.209a1.078,1.078,0,0,0-1.84-.762L23,15.966A1.07,1.07,0,0,0,23.32,15.209Z"
                          transform="translate(-9.309 -6.137)"
                          fill="#8e8e8e"
                        />
                      </g>
                      <rect
                        id="Rectangle_4536"
                        data-name="Rectangle 4536"
                        width="14.875"
                        height="14.875"
                        transform="translate(0 0.125)"
                        fill="none"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div
                className="close-button"
                onClick={() => {
                  props.Close();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.9"
                  height="9.899"
                  viewBox="0 0 9.9 9.899"
                >
                  <g
                    id="Group_4637"
                    data-name="Group 4637"
                    transform="translate(-224.153 -513.36) rotate(45)"
                  >
                    <line
                      id="Line_962"
                      data-name="Line 962"
                      x1="12"
                      transform="translate(522.5 204.5)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                    <line
                      id="Line_963"
                      data-name="Line 963"
                      x1="12"
                      transform="translate(528.5 198.5) rotate(90)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </div>
            </div>
          )}
        </div>
        <div className="modal-body">{ShowData()}</div>
      </div>
    </>
  );
}

export default CompainesModal;
