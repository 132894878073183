import React from 'react'
import { useState } from 'react';
import X from "./xicon.svg"

function SelectDay(props) {
    const { lang, setLang, data } = props
    const [focused, setFocused] = useState(false)
    const setValue = (a) => {

        setLang([...lang, a.id]);
    }
    const delValue = (a) => {
        setLang(lang.filter((d) => d !== a.id))
    }
    const getValue = () => {
        if (lang) {
            let str = ""
            let dd = data.filter((a) => lang.includes(a.id))
            dd.forEach((d, index) => {
                if (index === 0)
                    str = d.name

                else
                    str = str + " / " + d.name
            })
            return (str)
        }

        else
            return ""
    }
    return (
        <>
            {props.code &&
                <svg className='lang-input-icon' xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14">
                    <text id="AR" transform="translate(0 11)" fill="#CECDCD" fontSize="10" fontFamily="SegoeUI, Segoe UI" letterSpacing="0.025em"><tspan x="0" y="0">{props.code.toUpperCase()}</tspan></text>
                </svg>}
            <input onBlur={() => {
                setTimeout(() => {
                    setFocused(false)
                }, 3000);
            }} onClick={(e) => { setFocused(true) }} onFocus={(e) => { props.onFocus ? props.onFocus(e) : console.log() }} id={props.id} placeholder={props.lang && props.lang.length === 0 ? props.placeholder : ""} className={`${props.pad && "pad20"}`} style={props.pad ? { paddingLeft: "20px", cursor: "pointer" } : { paddingLeft: "0px", cursor: "pointer" }} readOnly={props.disabled} value={getValue()} inputMode={props.numeric ? props.numeric[0] : ""} type={props.numeric ? props.numeric[0] : "text"} /><img style={{ bottom: "6px" }} onClick={() => props.clear()} alt="close" src={X} />
            {focused && <div className="input-sync" style={{ top: "30px", width: "120px", overflow: "scroll", maxHeight: "200px" }}>
                {data.map((a) => (
                    <div
                        className={`input-sync-item  ${lang?.filter((da) => a?.id === da?.id).length > 0 && "active-day"}`} onClick={() => { lang.filter((da) => a?.id === da).length > 0 ? delValue(a) : setValue(a) }}>
                        <div className="input-sync-text" style={{ margin: "0px" }}>{a.name}</div>
                    </div>
                ))}
            </div>}

        </>
    )
}

export default SelectDay