import { createStore, applyMiddleware, compose } from 'redux';

// middlewares
//import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga';
import rootReducer from '.';
import {
    offlineStatus,

} from 'redux-offline-status/lib/middleware'
//import logger from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
// Import custom components

import rootSaga from './sagas';



const sagaMiddleware = createSagaMiddleware();
const middlewares = [offlineStatus(), sagaMiddleware, thunkMiddleware];
function loadFromLocalStorage() {
    try {
        // const serializedState = localStorage.getItem('staete1wss')
        //  if(serializedState === null) return undefined
        //  return JSON.parse(serializedState)
    } catch (e) {

        // return undefined
    }
}
const persistedState = loadFromLocalStorage()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(...middlewares)));
sagaMiddleware.run(rootSaga);
export default store;