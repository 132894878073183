import * as types from "./constants"
import {
    ONLINE,
    OFFLINE
} from 'redux-offline-status/lib/actions'
export function authReducer(state = {
    user: null,
    idToken: null,
    sessionInfo: null,
    error: false,
    done: false,
    token: null,
    loading: false,
    countries: [],
    languages: [],
    pass: false,
    lang: "en",
    offline: false,
    roles: [],
    time: 0
}, action) {
    switch (action.type) {
        case "ROLES_AUTH": {
            return ({
                ...state,
                roles: action.payload
            })
        }
        case "SET_TIME_Off": {
            return ({
                ...state,
                time: action.payload
            })
        }
        case "SET_TIME_ONLINE": {
            return ({
                ...state,
                time: action.payload,
                user: {
                    ...state.user,
                    employee: {
                        ...state.user.employee,
                        employee_daily_log: {
                            ...action.payload
                        }
                    }
                }
            })
        }

        case OFFLINE: {
            return ({
                ...state,
                offline: true
            })
        }
        case ONLINE: {
            return ({
                ...state,
                offline: false
            })
        }
        case "log_out": {
            return ({
                ...state,
                user: null
            })
        }
        case "PASS": {
            return ({
                ...state,
                pass: action.payload
            })
        }
        case "SET_LANGUAGES": {
            return {
                ...state,
                languages: action.data
            }
        }
        case "TRANSLATE": {
            return {
                ...state,
                lang: action.payload
            }
        }
        case "SET_TOKEN": {
            if (typeof window !== "undefined" && action.user && action?.user?.id) {
                localStorage.setItem("token", action.data)
                localStorage.setItem("user", JSON.stringify(action.user))

            }
            return {
                ...state,
                token: action.data,
                user: action.user
            }
        }
        case types.LOGIN_REDUCER: {
            localStorage.setItem("time", (action.payload?.employee?.employee_daily_log?.executed_working_time || 0))
            return ({
                ...state,
                user: action.payload,
                error: false
            })
        }
        case types.FORGET_REDUCER: {
            return ({
                ...state
            })
        }
        case types.DONE: {
            return ({
                ...state,
                loading: false,
                error: false
            })
        }
        case types.ERROR: {
            return ({
                ...state,
                error: true,
                done: false,
                loading: false
            })
        }
        case types.WAIT: {
            return ({
                ...state,
                loading: true
            })
        }
        case "TOKEN": {
            return ({
                ...state,
                idToken: action.payload,
                sessionInfo: null
            })
        }
        case "SESSION": {
            return ({
                ...state,
                sessionInfo: action.payload
            })
        }
        case "STORE_COUNTRIES": {
            return ({
                ...state,
                countries: action.payload
            })
        }
        case "REGISTER": {
            return ({
                ...state,
                user: action.payload
            })
        }
        case types.SIGNUP_REDUCER: {
            return ({
                ...state,
                user: action.payload
            })
        }
        default:
    }
    return state;
}