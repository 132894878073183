import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import AxiosInstance, {
} from "../../../helpers/axiosInstance";
import { toast } from "react-toastify";
import { ADD_DEPT_URL, GET_DEPT_URL, UPD_DEPT_URL } from "./constants";
import store from "../../store";
function* getAllDepartments(action) {
  let response;
  try {
    yield put({ type: "GENERAL-LOADING-LES" });
    response = yield AxiosInstance.post(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public" +
        GET_DEPT_URL
        : process.env.REACT_APP_BASE_URL +
        "/wallet-full-backend/public" +
        GET_DEPT_URL,
      JSON.stringify({ datatables: false, filter: { region_type_id: 1 } })
    );
    yield put({ type: "GENERAL-DONE" });
    yield put({ type: "GET-LES-REDUCER", payload: response.data.data });
    yield put({ type: "GENERAL-DONE-LES" });
  } catch (e) {
    yield put({ type: "GENERAL-DONE-LES" });

    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchGetAllDepartments() {
  yield takeEvery("GET-LES", getAllDepartments);
}
function* AddDepartments(action) {
  try {
    yield put({ type: "GENERAL-DONE-LES" });
    yield put({ type: "request-running-LES", payload: action.payload });
    yield AxiosInstance.post(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public" +
        ADD_DEPT_URL
        : process.env.REACT_APP_BASE_URL +
        "/wallet-full-backend/public" +
        ADD_DEPT_URL,
      JSON.stringify(action.payload)
    );
    yield put({ type: "request-running-LES-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-LES-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-LES" });
    yield put({ type: "GET-LES" });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-LES",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchAddDepartments() {
  yield takeEvery("ADD-LES", AddDepartments);
}
function* UpdateDepartments(action) {
  try {
    yield put({ type: "GENERAL-DONE-LES" });
    yield put({ type: "request-running-LES", payload: action.payload });
    yield AxiosInstance.post(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public" +
        UPD_DEPT_URL
        : process.env.REACT_APP_BASE_URL +
        "/wallet-full-backend/public" +
        UPD_DEPT_URL,
      JSON.stringify(action.payload)
    );
    yield put({ type: "request-running-LES-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-LES-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-LES" });
    yield put({ type: "GET-LES" });
    yield put({ type: "GENERAL-DONE-LES" });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-LES",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchUpdDepartments() {
  yield takeEvery("UPD-LES", UpdateDepartments);
}
function* getByPost(action) {
  try {
    yield put({ type: "LES-REG-LOADING" });
    const res = yield AxiosInstance.get(
      "/api/v1/regions/region_with_parent_ids_by_post_code/" + action.payload
    );
    yield put({
      type: "LES-REG-DONE",
      payload: res.data.data.region_translations.filter(
        (d) => d.language_code === "en"
      )[0].region_name,
    });
  } catch (e) {
    yield put({ type: "LES-REG-DONE", payload: "error" });
  }
}
function* watchGetByPost() {
  yield takeEvery("GET_REG_POST", getByPost);
}
export function* LiscensesSaga() {
  yield all([
    fork(watchGetAllDepartments),
    fork(watchUpdDepartments),
    fork(watchAddDepartments),
    fork(watchGetByPost),
  ]);
}
