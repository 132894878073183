import { GET_CHAT, SEND_MES } from "./constants";
import {
  all,
  fork,
  put,
  takeLeading,
  takeEvery,
  takeLatest,
} from "@redux-saga/core/effects";
import axios from "axios";
import WA from "../../assets/wa.mp3"
import { pusher } from "./constants";
import store from "../store";
function* get_chats(action) {
  const AxiosInstance = axios.create({
    baseURL:
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public"
        : process.env.REACT_APP_BASE_URL + "/wallet-full-backend/public",
    timeout: 0,
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).access_token),
      current_role_id:
        localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          ? localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          : "-1",
      "Content-Type": "application/json",
    },
  });

  try {
    if (!action.payload) yield put({ type: "CHAT_LOADING" });
    let resp = yield AxiosInstance.get(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public" +
        GET_CHAT
        : process.env.REACT_APP_BASE_URL +
        "/wallet-full-backend/public" +
        GET_CHAT
    );
    if (!action.payload) {
      let channels = [];
      yield resp.data.data.channels.forEach(async (cg) => {
        let chs = await pusher.subscribe(`presence-video-call-${cg.id?.toString(16)}`)
        await chs.bind(`client-signal-${JSON.parse(localStorage.getItem("user")).id}`, (signal) => {
          let caller = cg.channel_members.filter(one => one.user_id !== JSON.parse(localStorage.getItem("user")).id)[0]
          let callerChannel = cg
          store.dispatch({
            type: "INCOMING_CALL", payload: {
              signal,
              caller,
              callerChannel
            }
          })
        })
        await chs.bind(`client-signal-voice-${JSON.parse(localStorage.getItem("user")).id}`, (signal) => {
          let caller = cg.channel_members.filter(one => one.user_id !== JSON.parse(localStorage.getItem("user")).id)[0]
          let callerChannel = cg
          store.dispatch({
            type: "INCOMING_VOICE_CALL", payload: {
              signal,
              caller,
              callerChannel
            }
          })
        })
        let ch = pusher.subscribe(`presence-typing-${cg.id?.toString(16)}`);
        let channel = pusher.subscribe(cg.id?.toString(16));

        channels.push({ id: cg.id, channel: ch });

        channel.bind("ChannelWatchedEvent", (data) => {
          store.dispatch({
            type: "WATCH_CHANNEL_RED",
            payload: data.channel_id,
          });

        });
        await channel.bind("TextMessageEvent", (data) => {

          if (data.message.sender_user_id !== JSON.parse(localStorage.getItem("user")).id) {
            let not = new Audio(WA);
            not.volume = 0.5
            if (!store.getState().whatsapp.WAP)
              not.play()
          }
          store.dispatch({ type: "REFS" })
          store.dispatch({ type: "REC_CHA", payload: data.message.channel.id })
          if (data.message.sender_user_id !== JSON.parse(localStorage.getItem("user")).id) store.dispatch({ type: "SEND_MES_RED", payload: { ...data.message, cid: data.message.channel.id, recive: true } })
        })
        await channel.bind("ChannelReceivedEvent", (data) => {
          store.dispatch({ type: "REC_CHANNEL_RED", payload: data.channel_id });

        });
        await ch.bind("pusher:subscription_succeeded", (data) => {
          store.dispatch({ type: "PUSHER_CHANNEL", payload: { id: cg.id, channel: ch } })
        });
        // await ch.bind("pusher:subscription_error", (data) => {
        //   // console.log(
        //   //   "pusher:subscription_error" +
        //   //   " on " +
        //   //   `presence-typing-${cg.id?.toString(16)}`
        //   // );
        // });
        await ch.bind("client-TypingEvent", (data) => {

          if (
            parseInt(data.uid) !== JSON.parse(localStorage.getItem("user")).id
          )
            store.dispatch({ type: "IS_TYPING_TRUE", payload: data });
        });
      });

      yield put({ type: "PUSHER_RED", payload: channels });
    }

    yield put({ type: "GET_CHAT_RED", payload: resp.data.data.channels });
    yield put({ type: "CHAT_DONE" });
  } catch (e) {
    yield put({ type: "CHAT_DONE" });
  }
}
function* watchGetChat() {
  yield takeEvery("GET_CHAT", get_chats);
}
function* send(action) {
  const AxiosInstance = axios.create({
    baseURL:
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public"
        : process.env.REACT_APP_BASE_URL + "/wallet-full-backend/public",
    timeout: 0,
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).access_token),
      current_role_id:
        localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          ? localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          : "-1", // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
      "Content-Type": "application/json",
    },
  });
  let message = action.payload;
  try {
    let a = yield AxiosInstance.post(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public" +
        SEND_MES
        : process.env.REACT_APP_BASE_URL +
        "/wallet-full-backend/public" +
        SEND_MES,
      JSON.stringify(message)
    );
    if (a.data.data) {
      if (action.new) {
        yield put({ type: "GET_CHAT", payload: true });
        let ch = pusher.subscribe(
          `presence-typing-${a.data.data.channel.id?.toString(16)}`
        );
        let channel = pusher.subscribe(a.data.data.channel.id?.toString(16));

        store.dispatch({
          type: "PUSHER_CH",
          payload: { id: a.data.data.channel.id, channel: ch },
        });
        channel.bind("pusher:subscription_succeeded", (data) => {
          // console.log(
          //   "pusher:subscription_succeeded" +
          //   " on " +
          //   a.data.data.channel.id?.toString(16)
          // );
        });
        channel.bind("ChannelWatchedEvent", (data) => {
          store.dispatch({
            type: "WATCH_CHANNEL_RED",
            payload: data.channel_id,
          });

        });
        channel.bind("ChannelReceivedEvent", (data) => {
          store.dispatch({ type: "REC_CHANNEL_RED", payload: data.channel_id });

        });
        ch.bind("client-TypingEvent", (data) => {

          if (
            parseInt(data.uid) !== JSON.parse(localStorage.getItem("user")).id
          )
            store.dispatch({ type: "IS_TYPING_TRUE", payload: data });
        });
        yield put({
          type: "SEND_MES_RED_NEW",
          payload: {
            channel: {
              ...a.data.data.channel,
              channel_members: action.channel_members,
              messages: [{ ...a.data.data }],
            },
          },
        });

      } else
        yield put({
          type: "SEND_MES_RED",
          payload: {
            ...a.data.data,
            mid: action.payload.mid,
            cid: action.payload.cid,
          },
        });

    }

  } catch (e) {
    console.error(e);
  }
}
function* watchSend() {
  yield takeEvery("SEND_MES", send);
}
function* StoreToken(action) {
  try {
    const AxiosInstance = axios.create({
      baseURL:
        store?.getState().whatsapp.fetched_url === true
          ? store?.getState()?.whatsapp.base_url
          : process.env.REACT_APP_BASE_URL + "/wallet-full-backend/public",
      timeout: 0,
      headers: {
        Authorization:
          "Bearer " +
          action.payload.user.access_token,
        current_role_id:
          action.payload.user &&
            action.payload.user.role_id
            ? action.payload.user &&
            action.payload.user.role_id
            : "-1",
        "Content-Type": "application/json",
      },
    });
    let res = yield AxiosInstance.post(
      "/api/v1/firebase_device_tokens",
      JSON.stringify({
        device_token: action.payload.token
      })
    );
    localStorage.setItem("firebase_id", res?.data?.data?.id)

  } catch (e) {
    console.error(e);
  }
}
function* watchStoreToken() {
  yield takeLatest("STORE_TOKEN", StoreToken);
}
function* DeleteToken(action) {
  try {
    const AxiosInstance = axios.create({
      baseURL:
        store?.getState().whatsapp.fetched_url === true
          ? store?.getState()?.whatsapp.base_url
          : process.env.REACT_APP_BASE_URL + "/wallet-full-backend/public",
      timeout: 0,
      headers: {
        Authorization:
          "Bearer " +
          (localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).access_token),
        current_role_id:
          localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).role_id
            ? localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).role_id
            : "-1",
        "Content-Type": "application/json",
      },
    });
    yield AxiosInstance.delete(
      "/api/v1/firebase_device_tokens/" + action.payload,

    );
  } catch (e) {
    console.error(e);
  }
}
function* watchDeleteToken() {
  yield takeLatest("DEL_TOKEN", DeleteToken);
}
function* watchChannel(action) {
  try {
    const AxiosInstance = axios.create({
      baseURL:
        store?.getState().whatsapp.fetched_url === true
          ? store?.getState()?.whatsapp.base_url
          : process.env.REACT_APP_BASE_URL + "/wallet-full-backend/public",
      timeout: 0,
      headers: {
        Authorization:
          "Bearer " +
          (localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).access_token),
        current_role_id:
          localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).role_id
            ? localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).role_id
            : "-1", // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
        "Content-Type": "application/json",
      },
    });
    yield AxiosInstance.get(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public" +
        `/api/v1/channels/${action.payload}/watched`
        : process.env.REACT_APP_BASE_URL +
        "/wallet-full-backend/public" +
        `/api/v1/channels/${action.payload}/watched`
    );
  } catch (e) { }
}
function* watchWatchChannel() {
  yield takeEvery("WATCH_CHANNEL", watchChannel);
}
function* StartChat(action) {
  try {
    const AxiosInstance = axios.create({
      baseURL:
        store?.getState().whatsapp.fetched_url === true
          ? store?.getState()?.whatsapp.base_url
          : process.env.REACT_APP_BASE_URL + "/wallet-full-backend/public",
      timeout: 0,
      headers: {
        Authorization:
          "Bearer " +
          (localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).access_token),
        current_role_id:
          localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).role_id
            ? localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).role_id
            : "-1", // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
        "Content-Type": "application/json",
      },
    });
    yield put({ type: "CHAT_LOADING_USER" });
    const base =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url
        : process.env.REACT_APP_BASE_URL;
    let resp = yield AxiosInstance.get(
      base +
      "/wallet-full-backend/public/api/v1/employees/search/" +
      action.payload
    );
    yield put({ type: "SEARCH_USER_RED", payload: resp.data.data });
    yield put({ type: "CHAT_DONE_USER" });
  } catch (e) {
    yield put({ type: "SEARCH_USER_RED", payload: [] });
  }
}
function* watchStart() {
  yield takeEvery("START_CHAT", StartChat);
}
function* deleteChat(action) {
  try {
    const AxiosInstance = axios.create({
      baseURL:
        store?.getState().whatsapp.fetched_url === true
          ? store?.getState()?.whatsapp.base_url
          : process.env.REACT_APP_BASE_URL + "/wallet-full-backend/public",
      timeout: 0,
      headers: {
        Authorization:
          "Bearer " +
          (localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).access_token),
        current_role_id:
          localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).role_id
            ? localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).role_id
            : "-1", // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
        "Content-Type": "application/json",
      },
    });
    yield AxiosInstance.post(
      "/api/v1/channels/destroy",
      JSON.stringify({ id: action.payload })
    );
  } catch (e) {
    console.error(e);
  }
}
function* watchDelete() {
  yield takeEvery("DEL_CHAT", deleteChat);
}
function* Recive(action) {
  const AxiosInstance = axios.create({
    baseURL:
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public"
        : process.env.REACT_APP_BASE_URL + "/wallet-full-backend/public",
    timeout: 0,
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).access_token),
      current_role_id:
        localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          ? localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          : "-1", // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
      "Content-Type": "application/json",
    },
  });
  try {
    yield AxiosInstance.get(`/api/v1/channels/${action.payload}/received`);
  } catch (e) { }
}
function* watchRec() {
  yield takeEvery("REC_CHA", Recive);
}
function* getPage(action) {
  try {
    const AxiosInstance = axios.create({
      baseURL:
        store?.getState().whatsapp.fetched_url === true
          ? store?.getState()?.whatsapp.base_url +
          "/wallet-full-backend/public"
          : process.env.REACT_APP_BASE_URL + "/wallet-full-backend/public",
      timeout: 0,
      headers: {
        Authorization:
          "Bearer " +
          (localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).access_token),
        current_role_id:
          localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).role_id
            ? localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).role_id
            : "-1", // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
        "Content-Type": "application/json",
      },
    });
    let channel_id = action.channel
    let mid = action.mid
    let res = yield AxiosInstance.post(`api/v1/messages/messages_of_channel/${channel_id}?message_id=${mid}&limit=10`)
    yield put({ type: "GRP", payload: { mes: res.data.data, ch: channel_id } })
  }
  catch (e) {

  }

}
function* watchgetPage() {
  yield takeLeading("GET_CHAT_PAGE", getPage)
}
export function* ChatSaga() {
  yield all([
    fork(watchGetChat),
    fork(watchSend),
    fork(watchStoreToken),
    fork(watchWatchChannel),
    fork(watchStart),
    fork(watchDelete),
    fork(watchRec),
    fork(watchgetPage),
    fork(watchDeleteToken)
  ]);
}
