import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import AxiosInstance from "../../helpers/axiosInstance";
import { toast } from "react-toastify";
import {
  ADD_LANGS_URL,
  GET_LANGS_URL,
  UPD_LANGS_URL,
} from "./constants";
import store from "../store";
function* getAllLanguages(action) {
  let response;
  try {
    yield put({ type: "GENERAL-LOADING" });
    response = yield AxiosInstance.get(GET_LANGS_URL, {
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = parseInt(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        store.dispatch({ type: "GENERAL-LOADING", percentCompleted });
      },
    });
    yield put({ type: "GET-TRF-REDUCER", payload: response.data.data });
    yield put({ type: "GENERAL-DONE" });
  } catch (e) {
    yield put({ type: "GENERAL-LOADING" });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchGetAllLanguages() {
  yield takeEvery("GET-TRF", getAllLanguages);
}
function* AddLanguage(action) {
  let responses;
  try {
    yield put({ type: "GENERAL-DONE" });
    yield put({ type: "request-running-TRF", payload: action.payload });
    yield AxiosInstance.post(
      ADD_LANGS_URL,
      JSON.stringify(action.payload)
    );
    if (action.payload.parent_language_code === null) {
      let data = action.payload;
      data.translations = [
        ...data.translations,
        {
          name: data.name_in_native_language,
          language_code: data.language_code,
        },
      ];
      yield AxiosInstance.post(UPD_LANGS_URL, JSON.stringify(data));
    }

    yield put({ type: "request-running-red-TRF", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-red-TRF", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE" });
    responses = yield AxiosInstance.get(GET_LANGS_URL);
    yield put({ type: "GET-TRF-REDUCER", payload: responses.data.data });
    // toast.success("Added Successfuly")
  } catch (e) {
    yield put({
      type: "GENERAL-ERR",
      payload: { data: action.payload, message: e.message },
    });
    // if(e.message){
    //     toast.error(e.message)
    // }
    // else
    // toast.error("failed! please check conection")
    toast.error("failed! BACK END ERROR");
  }
}

function* watchAddLanguages() {
  yield takeEvery("ADD-TRF", AddLanguage);
}
function* UpdateLanguages(action) {
  let responses;
  try {
    yield put({ type: "GENERAL-DONE" });
    yield put({ type: "request-running-TRF", payload: action.payload });
    yield AxiosInstance.post(
      UPD_LANGS_URL,
      JSON.stringify(action.payload)
    );
    yield put({ type: "request-running-red-TRF", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-red-TRF", payload: null });
    }, 4000);

    yield put({ type: "GENERAL-DONE" });
    responses = yield AxiosInstance.get(GET_LANGS_URL);
    yield put({ type: "GET-TRF-REDUCER", payload: responses.data.data });
    // toast.success("Updated Successfuly")
    yield put({ type: "GENERAL-DONE" });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchUpdLanguages() {
  yield takeEvery("UPD-TRF", UpdateLanguages);
}
function* getCountries(action) {
  try {
    let a = yield AxiosInstance.get(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet-full-backend/public" +
        "/api/v1/regions/get_countries"
        : process.env.REACT_APP_BASE_URL +
        "/wallet-full-backend/public" +
        "/api/v1/regions/get_countries"
    );
    yield put({ type: "REGION_ASYNC", payload: a.data.data });
    // let b = yield AxiosInstance.get(
    //   store?.getState().whatsapp.fetched_url === true
    //     ? store?.getState()?.whatsapp.base_url +
    //         "/wallet_wallets_service/public" +
    //         "/api/v1/transaction_payment_types"
    //     : process.env.REACT_APP_BASE_URL +
    //         "/wallet_wallets_service/public" +
    //         "/api/v1/transaction_payment_types"
    // );
    // yield put({ type: "TRF-TYPES", payload: b.data.data });

    // let c = yield AxiosInstance.get(
    //   store?.getState().whatsapp.fetched_url === true
    //     ? store?.getState()?.whatsapp.base_url +
    //         "/wallet_wallets_service/public" +
    //         "/api/v1/commission_types"
    //     : process.env.REACT_APP_BASE_URL +
    //         "/wallet_wallets_service/public" +
    //         "/api/v1/commission_types"
    // );
    // yield put({ type: "TRF-COMM", payload: c.data.data });
    // let d = yield AxiosInstance.get(
    //   store?.getState().whatsapp.fetched_url === true
    //     ? store?.getState()?.whatsapp.base_url +
    //         "/wallet_wallets_service/public" +
    //         "/api/v1/contact_methods"
    //     : process.env.REACT_APP_BASE_URL +
    //         "/wallet_wallets_service/public" +
    //         "/api/v1/contact_methods"
    // );
    // yield put({ type: "TRF-COMM-TYPE", payload: d.data.data });
  } catch (e) { }
}
function* watchCountries() {
  yield takeEvery("REGION_ASYNC_SAGA", getCountries);
}
function* getClients(action) {
  try {
    let a = yield AxiosInstance.get(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
        "/wallet_wallets_service/public/api/v1/beneficiaries/search/" +
        action.payload
        : process.env.REACT_APP_BASE_URL +
        "/wallet_wallets_service/public/api/v1/beneficiaries/search/" +
        action.payload
    );
    yield put({ type: "CLIENT_ASYNC", payload: a.data.data });
  } catch (e) {
    yield put({ type: "CLIENT_ASYNC", payload: [] });
  }
}
function* watchClients() {
  yield takeEvery("CLIENT_ASYNC_SAGA", getClients);
}
export function* TransferSaga() {
  yield all([
    fork(watchGetAllLanguages),
    fork(watchUpdLanguages),
    fork(watchAddLanguages),
    fork(watchCountries),
    fork(watchClients),
  ]);
}
