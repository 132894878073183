import React, { useEffect, useState } from "react";
import InputText from "./inputs/InputText";
import photoCity from "./Icons/PhotoIcon.svg";
import X from "../../Languages/svgs/xicon.svg";
import flagCity from "./Icons/flagCity.svg";
import { useDispatch, useSelector } from "react-redux";
import { FILE_SERVER } from "../../../../../constants";
import { upload } from "../../../../../uploadService";
import { toast } from "react-toastify";
import Empty from "../../../../../assets/svg/empty.svg";
import LoadingImage from "../../../LoadingImage";
import "../countries.css";
import AddPdf from "../Liscenses/inputs/AddPdf";
import PDFElem from "../Liscenses/inputs/PDFElem";
import { textMarshal } from "text-marshal";
import Spinner from "../../../Spinner";
import { trans } from "../../../../../Internationalization"
function CompainesModal(props) {
  const mode = useSelector((state) => state.companies.mode);
  useEffect(() => {
    let arr = [];
    if (mode !== "add") {
      data.regions &&
        data.regions.forEach((a) => {
          arr.push(
            a.region_translations.filter((s) => s.language_code === "en")[0]
              .region_name
          );
        });
      dispatch({
        type: "NEW-COM",
        payload: { ...data, company_regions: [...arr] },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);
  useEffect(() => {

  }, [props.languageFollower]);
  const img_upload = useSelector((state) => state.companies.img_upload);
   const countries = useSelector((state) => state.companies.countries);
  const reg_loading = useSelector((state) => state.employee.reg_loading);
  const regs = useSelector((state) => state.employee.regions);
  const [focuses, setFocuse] = useState(false);
  const modalData = useSelector((state) => state.companies.modalData);
  const data = props.data;
  const shake = (e) => {
    document.querySelector(".lang-modal.open").classList.add("shake-modal");
    setTimeout(() => {
      document
        .querySelector(".lang-modal.open")
        .classList.remove("shake-modal");
    }, 400);
  };
  const ButtonValidateServ = () => {
    let bool = false;
    if (
      data.translations.filter((a) => a.name.length === 0).length > 0 ||
      (data.short_name && data.short_name.length === 0)
    ) {
      bool = false;
    } else bool = true;

    return bool;
  };
  const ButtonValidateSubs = () => {
    let bool = false;
    if (
      data.registered_as &&
      data.registered_as.length > 0 &&
      data.subscription_date &&
      data.subscription_date.length > 0 &&
      data.subscription_data.files.length > 0 &&
      data.subscription_no.length > 0
    ) {
      bool = true;
    } else bool = false;
    return bool;
  };
  const sendRequestServ = () => {
    if (ButtonValidateServ()) {

      if (mode === "add" && props.level === 1) {
        //add company
        dispatch({ type: "ADD-COM", payload: data, view: view });
      }
      else if (mode === "add" && props.level === 2) {
        //add service
        dispatch({
          type: "ADD-COM",
          payload: data,
          id: data.company_id,
          view: view,
        });
      }
      else if (mode === "update" && props.level === 2) {
        //update company
        dispatch({ type: "UPD-COM", payload: data, view: view });
      }
      else if (mode === "update" && props.level === 3) {
        //update service
        dispatch({
          type: "UPD-COM",
          payload: data,
          id: data.company_id,
          view: view,
        });
      }
    } else {
      document.querySelectorAll(".modal-input-section input").forEach((inp) => {
        if (inp.value.length < 2) {
          inp.parentElement.classList.add("shake-modal");
          inp.classList.add("red-bord");
          setTimeout(() => {
            inp.parentElement.classList.remove("shake-modal");
          }, 400);
          setTimeout(() => {
            inp.classList.remove("red-bord");
          }, 1000);
        }
      });
    }
  };
  const sendRequestSubs = () => {
    if (ButtonValidateSubs()) {
      if (mode === "add") {
        dispatch({
          type: "ADD-COM",
          payload: data,
          id: data.service_id,
          view: view,
          serv: true,
        });
      } else {
        dispatch({
          type: "UPD-COM",
          payload: data,
          id: data.id,
          view: view,
          serv: true,
        });
      }
    } else {
      document.querySelectorAll(".modal-input-section input").forEach((inp) => {
        if (inp.value.length < 2) {
          inp.parentElement.classList.add("shake-modal");
          inp.classList.add("red-bord");
          setTimeout(() => {
            inp.parentElement.classList.remove("shake-modal");
          }, 400);
          setTimeout(() => {
            inp.classList.remove("red-bord");
          }, 1000);
        }
      });
    }
  };
  const updatPhotoPDF = (index) => {
    if (mode !== "read") {
      let Image = document.createElement("input");
      Image.onchange = (e) => {
        updateImagePDF(e.target.files[0], index);
      };
      Image.type = "file";
      Image.hidden = true;
      Image.accept = "application/pdf";
      Image.style = { position: "absolute", opacity: "0" };
      let i = document.body.appendChild(Image);
      i.click();
    }
  };
  const updateImagePDF = async (file, index) => {
    dispatch({
      type: "IMG-UPLOAD-COM",
      payload: { status: true, type: index },
    });

    let pat = await upload(file, index);
    let tmp = {
      ...data,
      subscription_data: { files: [...data.subscription_data.files, pat] },
    };
    dispatch({ type: "NEW-COM", payload: { ...tmp } });
    dispatch({
      type: "IMG-UPLOAD-COM",
      payload: { status: false, type: null },
    });
    dispatch({ type: "LEVEL-DONE-COM" });
  };
  const getPhone = (a) => {
    if (
      data.country_id &&
      countries.filter((sd) => sd.id === data.country_id).length > 0
    ) {
    ;
    }
    let d = textMarshal({
      input: a,
      template: `+xx  xxx  xxxxxxxx`,
      disallowCharacters: [/[a-z]/],
    });
    return d.marshaltext;
  };
  const showContent = () => {
    if ((props.level === 1 && mode === "add") || (props.level === 2 && (mode === "update" || mode === "read"))) {
      return (
        <>
          <div className="modal-top">
            <div className="modal-top-title">
              {mode === "add" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectangle_4527"
                        data-name="Rectangle 4527"
                        width="15"
                        height="15"
                        transform="translate(324 333)"
                        fill="none"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Mask_Group_16"
                    data-name="Mask Group 16"
                    transform="translate(-324 -333)"
                    clipPath="url(#clip-path)"
                  >
                    <path
                      id="add-3"
                      d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z"
                      transform="translate(324 333)"
                      fill="#404040"
                    />
                  </g>
                </svg>
              ) : (
                <>
                  {data.flat_photo_path ? (
                    <img alt=""
                      className="emp-dept no-fill"
                      src={FILE_SERVER + data.flat_photo_path}
                      width="23"
                      height="15"
                    />
                  ) : (
                    <svg
                      className="emp-dept"
                      data-src={Empty}
                      width="23"
                      height="15"
                    ></svg>
                  )}
                </>
              )}
              {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

              {mode === "add" ? (
                <div className="modal-title">{showModal()}</div>
              ) : (
                <div className="modal-title">
                  {mode === "update" ? trans("update") : ""}{" "}
                  {`${modalData.name}`} {trans("company")}
                </div>
              )}
            </div>
            {mode !== "read" ? (
              <div className="modal-top-buttons">
                <div
                  className={`add-button ${!ButtonValidate() && "disabled-button"
                    }`}
                  onClick={() => {
                    sendRequest();
                    if (ButtonValidate()) {
                      props.Close();
                    }
                  }}
                >
                  {mode === "add" ? trans("add") : trans("update")}
                </div>
                <div
                  className="close-button"
                  onClick={() => {
                    setOpen(false);
                    props.Close();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.9"
                    height="9.899"
                    viewBox="0 0 9.9 9.899"
                  >
                    <g
                      id="Group_4637"
                      data-name="Group 4637"
                      transform="translate(-224.153 -513.36) rotate(45)"
                    >
                      <line
                        id="Line_962"
                        data-name="Line 962"
                        x1="12"
                        transform="translate(522.5 204.5)"
                        fill="none"
                        stroke="#ff2e2e"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                      <line
                        id="Line_963"
                        data-name="Line 963"
                        x1="12"
                        transform="translate(528.5 198.5) rotate(90)"
                        fill="none"
                        stroke="#ff2e2e"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            ) : (
              <div className="modal-top-buttons">
                <div
                  className="icon-lang marg"
                  onClick={() => {
                    props.Add(data);
                    props.ActiveAdd();
                    setExpand(true);
                  }}
                >
                  <div className="icon-lang-desc">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <defs>
                        <filter
                          id="Ellipse_319"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="3" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="3" result="blur" />
                          <feFlood floodOpacity="0.2" />
                          <feComposite operator="in" in2="blur" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g
                        transform="matrix(1, 0, 0, 1, 0, 0)"
                        filter="url(#Ellipse_319)"
                      >
                        <circle
                          id="Ellipse_319-2"
                          data-name="Ellipse 319"
                          cx="3"
                          cy="3"
                          r="3"
                          transform="translate(9 6)"
                          fill="#404040"
                        />
                      </g>
                    </svg>
                    <div
                      className="icon-lang-desc-bu"
                      style={{
                        whiteSpace: "nowrap",
                        width: "auto",
                        padding: "7px",
                        left: "-27px",
                      }}
                    >
                      {showModal()}
                    </div>
                  </div>
                  <svg
                    className="gray-color"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                  >
                    <g
                      id="Mask_Group_16"
                      data-name="Mask Group 16"
                      transform="translate(-324 -333)"
                      clipPath="url(#clip-path)"
                    >
                      <path
                        id="add-3"
                        d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z"
                        transform="translate(324 333)"
                        fill="#8e8e8e"
                      />
                    </g>
                  </svg>
                </div>
                <div
                  className="icon-lang marg"
                  onClick={() => {
                    props.Update(props.data);
                  }}
                >
                  <div className="icon-lang-desc">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <defs>
                        <filter
                          id="Ellipse_319"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="3" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="3" result="blur" />
                          <feFlood floodOpacity="0.2" />
                          <feComposite operator="in" in2="blur" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g
                        transform="matrix(1, 0, 0, 1, 0, 0)"
                        filter="url(#Ellipse_319)"
                      >
                        <circle
                          id="Ellipse_319-2"
                          data-name="Ellipse 319"
                          cx="3"
                          cy="3"
                          r="3"
                          transform="translate(9 6)"
                          fill="#404040"
                        />
                      </g>
                    </svg>
                    {/* ATTENTION!! ADDING CONDITION NEXT LINE */}
                    <div
                      className="icon-lang-desc-bu"
                      style={{ whiteSpace: "nowrap", left: "-24px" }}
                    >
                      Edit
                    </div>
                  </div>
                  <svg
                    className="gray-color"
                    id="_15x15_photo_back"
                    data-name="15x15 photo back"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                  >
                    <g data-name="Mask Group 65">
                      <g
                        id="Group_4666"
                        data-name="Group 4666"
                        transform="translate(0.063 0)"
                      >
                        <g id="user" transform="translate(0.258)">
                          <path
                            id="Path_16021"
                            data-name="Path 16021"
                            d="M13.436,24.162l1.583-.366L13.8,22.579Z"
                            transform="translate(-6.428 -9.162)"
                            fill="#8e8e8e"
                          />
                          <path
                            id="Path_16022"
                            data-name="Path 16022"
                            d="M9.213,18.943l.262-1.136v-.047l.006-.014v-.009l.008-.014v-.008L9.5,17.7l.006-.008.011-.012h0l3.531-3.531a5.421,5.421,0,0,0-4.867-3.239A5.239,5.239,0,0,0,4.19,12.762a7.346,7.346,0,0,0-1.668,4.726A13.977,13.977,0,0,0,8.178,19a10.04,10.04,0,0,0,1.035-.055Z"
                            transform="translate(-2.521 -4.985)"
                            fill="#8e8e8e"
                          />
                          <path
                            id="Path_16023"
                            data-name="Path 16023"
                            d="M0,0H5.927V2.156H0Z"
                            transform="translate(7.595 12.882) rotate(-45)"
                            fill="#8e8e8e"
                          />
                          <circle
                            id="Ellipse_318"
                            data-name="Ellipse 318"
                            cx="2.694"
                            cy="2.694"
                            r="2.694"
                            transform="translate(2.963)"
                            fill="#8e8e8e"
                          />
                          <path
                            id="Path_16024"
                            data-name="Path 16024"
                            d="M23.32,15.209a1.078,1.078,0,0,0-1.84-.762L23,15.966A1.07,1.07,0,0,0,23.32,15.209Z"
                            transform="translate(-9.309 -6.137)"
                            fill="#8e8e8e"
                          />
                        </g>
                        <rect
                          id="Rectangle_4536"
                          data-name="Rectangle 4536"
                          width="14.875"
                          height="14.875"
                          transform="translate(0 0.125)"
                          fill="none"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div
                  className="close-button"
                  onClick={() => {
                    props.Close();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.9"
                    height="9.899"
                    viewBox="0 0 9.9 9.899"
                  >
                    <g
                      id="Group_4637"
                      data-name="Group 4637"
                      transform="translate(-224.153 -513.36) rotate(45)"
                    >
                      <line
                        id="Line_962"
                        data-name="Line 962"
                        x1="12"
                        transform="translate(522.5 204.5)"
                        fill="none"
                        stroke="#ff2e2e"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                      <line
                        id="Line_963"
                        data-name="Line 963"
                        x1="12"
                        transform="translate(528.5 198.5) rotate(90)"
                        fill="none"
                        stroke="#ff2e2e"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            )}
          </div>
          <div className="modal-body">
            {showIcons()}
            {showInputs()}
          </div>
        </>
      );
    } else if (
      (props.level === 2 && mode === "add") || (props.level === 3 && (mode === "update" || mode === "read"))
    ) {
      return (
        <>
          <div className="modal-top">
            <div className="modal-top-title">
              {mode === "add" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectangle_4527"
                        data-name="Rectangle 4527"
                        width="15"
                        height="15"
                        transform="translate(324 333)"
                        fill="none"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Mask_Group_16"
                    data-name="Mask Group 16"
                    transform="translate(-324 -333)"
                    clipPath="url(#clip-path)"
                  >
                    <path
                      id="add-3"
                      d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z"
                      transform="translate(324 333)"
                      fill="#404040"
                    />
                  </g>
                </svg>
              ) : (
                <>
                  {data.flat_photo_path ? (
                    <img alt=""
                      className="emp-dept no-fill"
                      src={FILE_SERVER + data.flat_photo_path}
                      width="23"
                      height="15"
                    />
                  ) : (
                    <svg
                      className="emp-dept"
                      data-src={Empty}
                      width="23"
                      height="15"
                    ></svg>
                  )}
                </>
              )}
              {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

              {mode === "add" ? (
                <div className="modal-title">{showModal()}</div>
              ) : (
                <div className="modal-title">
                  {mode === "update" ? trans("update") : ""}{" "}
                  {`${modalData.name}`} {trans("service")}
                </div>
              )}
            </div>
            {mode !== "read" ? (
              <div className="modal-top-buttons">
                <div
                  className={`add-button ${!ButtonValidateServ() && "disabled-button"
                    }`}
                  onClick={() => {
                    sendRequestServ();
                    if (ButtonValidateServ()) {
                      props.Close();
                    }
                  }}
                >
                  {mode === "add" ? trans("add") : trans("update")}
                </div>
                <div
                  className="close-button"
                  onClick={() => {
                    setOpen(false);
                    props.Close();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.9"
                    height="9.899"
                    viewBox="0 0 9.9 9.899"
                  >
                    <g
                      id="Group_4637"
                      data-name="Group 4637"
                      transform="translate(-224.153 -513.36) rotate(45)"
                    >
                      <line
                        id="Line_962"
                        data-name="Line 962"
                        x1="12"
                        transform="translate(522.5 204.5)"
                        fill="none"
                        stroke="#ff2e2e"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                      <line
                        id="Line_963"
                        data-name="Line 963"
                        x1="12"
                        transform="translate(528.5 198.5) rotate(90)"
                        fill="none"
                        stroke="#ff2e2e"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            ) : (
              <div className="modal-top-buttons">
                {props.level !== 1 && props.level !== 4 && (
                  <div
                    className="icon-lang marg"
                    onClick={() => {
                      props.Add(data);
                      props.ActiveAdd();
                      setExpand(true);
                    }}
                  >
                    <div className="icon-lang-desc">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <defs>
                          <filter
                            id="Ellipse_319"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="3" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood floodOpacity="0.2" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g
                          transform="matrix(1, 0, 0, 1, 0, 0)"
                          filter="url(#Ellipse_319)"
                        >
                          <circle
                            id="Ellipse_319-2"
                            data-name="Ellipse 319"
                            cx="3"
                            cy="3"
                            r="3"
                            transform="translate(9 6)"
                            fill="#404040"
                          />
                        </g>
                      </svg>
                      <div
                        className="icon-lang-desc-bu"
                        style={{
                          whiteSpace: "nowrap",
                          width: "auto",
                          padding: "7px",
                          left: "-27px",
                        }}
                      >
                        {showModal()}
                      </div>
                    </div>
                    <svg
                      className="gray-color"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                    >
                      <g
                        id="Mask_Group_16"
                        data-name="Mask Group 16"
                        transform="translate(-324 -333)"
                        clipPath="url(#clip-path)"
                      >
                        <path
                          id="add-3"
                          d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z"
                          transform="translate(324 333)"
                          fill="#8e8e8e"
                        />
                      </g>
                    </svg>
                  </div>
                )}
                <div
                  className="icon-lang marg"
                  onClick={() => {
                    props.Update(props.data);
                  }}
                >
                  <div className="icon-lang-desc">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <defs>
                        <filter
                          id="Ellipse_319"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="3" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="3" result="blur" />
                          <feFlood floodOpacity="0.2" />
                          <feComposite operator="in" in2="blur" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g
                        transform="matrix(1, 0, 0, 1, 0, 0)"
                        filter="url(#Ellipse_319)"
                      >
                        <circle
                          id="Ellipse_319-2"
                          data-name="Ellipse 319"
                          cx="3"
                          cy="3"
                          r="3"
                          transform="translate(9 6)"
                          fill="#404040"
                        />
                      </g>
                    </svg>
                    {/* ATTENTION!! ADDING CONDITION NEXT LINE */}
                    <div
                      className="icon-lang-desc-bu"
                      style={{ whiteSpace: "nowrap", left: "-24px" }}
                    >
                      {trans("edit")}
                    </div>
                  </div>
                  <svg
                    className="gray-color"
                    id="_15x15_photo_back"
                    data-name="15x15 photo back"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                  >
                    <g data-name="Mask Group 65">
                      <g
                        id="Group_4666"
                        data-name="Group 4666"
                        transform="translate(0.063 0)"
                      >
                        <g id="user" transform="translate(0.258)">
                          <path
                            id="Path_16021"
                            data-name="Path 16021"
                            d="M13.436,24.162l1.583-.366L13.8,22.579Z"
                            transform="translate(-6.428 -9.162)"
                            fill="#8e8e8e"
                          />
                          <path
                            id="Path_16022"
                            data-name="Path 16022"
                            d="M9.213,18.943l.262-1.136v-.047l.006-.014v-.009l.008-.014v-.008L9.5,17.7l.006-.008.011-.012h0l3.531-3.531a5.421,5.421,0,0,0-4.867-3.239A5.239,5.239,0,0,0,4.19,12.762a7.346,7.346,0,0,0-1.668,4.726A13.977,13.977,0,0,0,8.178,19a10.04,10.04,0,0,0,1.035-.055Z"
                            transform="translate(-2.521 -4.985)"
                            fill="#8e8e8e"
                          />
                          <path
                            id="Path_16023"
                            data-name="Path 16023"
                            d="M0,0H5.927V2.156H0Z"
                            transform="translate(7.595 12.882) rotate(-45)"
                            fill="#8e8e8e"
                          />
                          <circle
                            id="Ellipse_318"
                            data-name="Ellipse 318"
                            cx="2.694"
                            cy="2.694"
                            r="2.694"
                            transform="translate(2.963)"
                            fill="#8e8e8e"
                          />
                          <path
                            id="Path_16024"
                            data-name="Path 16024"
                            d="M23.32,15.209a1.078,1.078,0,0,0-1.84-.762L23,15.966A1.07,1.07,0,0,0,23.32,15.209Z"
                            transform="translate(-9.309 -6.137)"
                            fill="#8e8e8e"
                          />
                        </g>
                        <rect
                          id="Rectangle_4536"
                          data-name="Rectangle 4536"
                          width="14.875"
                          height="14.875"
                          transform="translate(0 0.125)"
                          fill="none"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div
                  className="close-button"
                  onClick={() => {
                    props.Close();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.9"
                    height="9.899"
                    viewBox="0 0 9.9 9.899"
                  >
                    <g
                      id="Group_4637"
                      data-name="Group 4637"
                      transform="translate(-224.153 -513.36) rotate(45)"
                    >
                      <line
                        id="Line_962"
                        data-name="Line 962"
                        x1="12"
                        transform="translate(522.5 204.5)"
                        fill="none"
                        stroke="#ff2e2e"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                      <line
                        id="Line_963"
                        data-name="Line 963"
                        x1="12"
                        transform="translate(528.5 198.5) rotate(90)"
                        fill="none"
                        stroke="#ff2e2e"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            )}
          </div>
          <div className="modal-body">
            <>
              <div className="modal-icon-section">
                <div className="modal-icon-name">{trans("serv_logo")}</div>
                <div className="modal-icons">
                  {!(
                    img_upload.status && img_upload.type === "flat_photo_path"
                  ) ? (
                    <div
                      className="modal-icon mar cityflag"
                      onDragLeave={(e) =>
                        (e.target.style.transform = "scale(1)")
                      }
                      onDragEnter={(e) =>
                        (e.target.style.transform = "scale(1.1)")
                      }
                      onDropCapture={(e) => { }}
                      dropzone
                      onDrop={(e) => {
                        onDropImage(e, "flat_photo_path");
                      }}
                      onDragOver={(e) => onDragOver(e)}
                      onClick={() => updatPhoto("flat_photo_path")}
                    >
                      {data.flat_photo_path && <span onClick={() => delPhoto("flat_photo_path")} className="abs-delt"><svg data-src={X}></svg></span>}
                      {data.flat_photo_path &&
                        data.flat_photo_path.length > 0 ? (
                        <img alt=""
                          src={FILE_SERVER + data.flat_photo_path}
                          width="70"
                          height="50"
                        />
                      ) : (
                        <img alt=""
                          style={{ width: "70px", height: "50px" }}
                          className="language-flat"
                          src={flagCity}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="modal-icon mar cityflag">
                      <LoadingImage />
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-input-section">
                {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

                <div className="modal-icon-name">{trans("service_name")}</div>
                <div className="input-section">
                  <div className="input-holder">
                    <div
                      className="short-input-element"
                      style={{ width: "37px" }}
                    >
                      <input
                        readOnly={mode === "read"}
                        onChange={(e) => {
                          if (e.target.value.length <= 3) {
                            dispatch({
                              type: "NEW-COM",
                              payload: { ...data, short_name: e.target.value },
                            });
                          }
                        }}
                        value={data.short_name}
                        type="text"
                      />
                      <img alt=""
                        onClick={() =>
                          dispatch({
                            type: "NEW-COM",
                            payload: { ...data, short_name: "" },
                          })
                        }
                       
                        src={X}
                      />
                    </div>
                    <div
                      className="full-input-element pa"
                      style={{ paddingLeft: "10px" }}
                    >
                      <InputText
                        disabled={mode === "read"}
                        code={
                          data.translations.filter(
                            (t) => t.language_code === "en"
                          )[0].language_code
                        }
                        pad={true}
                        clear={() => {
                          ChangeTrans(
                            data.translations.filter(
                              (t) => t.language_code === "en"
                            )[0].language_code,
                            ""
                          );
                        }}
                        lang={
                          data.translations.filter(
                            (t) => t.language_code === "en"
                          )[0].name
                        }
                        setLang={(e) => {
                          ChangeTrans(
                            data.translations.filter(
                              (t) => t.language_code === "en"
                            )[0].language_code,
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="trans-input-holder">
                    {data.translations.forEach((t, index) => {
                      return (
                        <>
                          {t.language_code !== "en" && (
                            <div
                              className="trans-input-element"
                              style={{ width: "109px" }}
                            >
                              <InputText
                                disabled={mode === "read"}
                                code={t.language_code}
                                pad={true}
                                clear={() => {
                                  ChangeTrans(t.language_code, "");
                                }}
                                lang={t.name}
                                setLang={(e) => {
                                  ChangeTrans(t.language_code, e.target.value);
                                }}
                              />
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          </div>
        </>
      );
    } else if ((props.level === 3 && mode === "add") || (props.level === 4 && (mode === "update" || mode === "read"))) {
      return (
        <>
          <div className="modal-top">
            <div className="modal-top-title">
              {mode === "add" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectangle_4527"
                        data-name="Rectangle 4527"
                        width="15"
                        height="15"
                        transform="translate(324 333)"
                        fill="none"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Mask_Group_16"
                    data-name="Mask Group 16"
                    transform="translate(-324 -333)"
                    clipPath="url(#clip-path)"
                  >
                    <path
                      id="add-3"
                      d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z"
                      transform="translate(324 333)"
                      fill="#404040"
                    />
                  </g>
                </svg>
              ) : (
                <>
                  {data.flat_photo_path ? (
                    <svg
                      className="emp-dept no-fill"
                      src={FILE_SERVER + data.flat_photo_path}
                      width="23"
                      height="15"
                    ></svg>
                  ) : (
                    <svg
                      className="emp-dept"
                      data-src={Empty}
                      width="23"
                      height="15"
                    ></svg>
                  )}
                </>
              )}
              {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

              {mode === "add" ? (
                <div className="modal-title">{showModal()}</div>
              ) : (
                <div className="modal-title">
                  {mode === "update" ? "Update" : ""}{" "}
                  {`${modalData.name} Subscription`}
                </div>
              )}
            </div>
            {mode !== "read" ? (
              <div className="modal-top-buttons">
                <div
                  className={`add-button ${!ButtonValidateSubs() && "disabled-button"
                    }`}
                  onClick={() => {
                    sendRequestSubs();
                    if (ButtonValidateSubs()) {
                      props.Close();
                    }
                  }}
                >
                  {mode === "add" ? trans("add") : trans("update")}
                </div>
                <div
                  className="close-button"
                  onClick={() => {
                    setOpen(false);
                    props.Close();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.9"
                    height="9.899"
                    viewBox="0 0 9.9 9.899"
                  >
                    <g
                      id="Group_4637"
                      data-name="Group 4637"
                      transform="translate(-224.153 -513.36) rotate(45)"
                    >
                      <line
                        id="Line_962"
                        data-name="Line 962"
                        x1="12"
                        transform="translate(522.5 204.5)"
                        fill="none"
                        stroke="#ff2e2e"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                      <line
                        id="Line_963"
                        data-name="Line 963"
                        x1="12"
                        transform="translate(528.5 198.5) rotate(90)"
                        fill="none"
                        stroke="#ff2e2e"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            ) : (
              <div className="modal-top-buttons">
                {/* {props.languageFollower.length<=4&&
           <div className='icon-lang marg' onClick={()=>{props.Add(data); props.ActiveAdd(); setExpand(true); }}>
           <div className='icon-lang-desc'>
           <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
           <defs>
             <filter id="Ellipse_319" x="0" y="0"  width="24" height="24" filterUnits="userSpaceOnUse">
               <feOffset dy="3" input="SourceAlpha"/>
               <feGaussianBlur stdDeviation="3" result="blur"/>
               <feFlood floodOpacity="0.2"/>
               <feComposite operator="in" in2="blur"/> 
               <feComposite in="SourceGraphic"/>
             </filter>
           </defs>
           <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
             <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040"/>
           </g>
         </svg>
       <div className='icon-lang-desc-bu' style={{whiteSpace:"nowrap",width:"auto",padding:"7px",left:"-27px"}}>{showModal()}</div>
           </div>
         <svg className='gray-color' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
         <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)" clipPath="url(#clip-path)">
           <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#8e8e8e"/>
         </g>
       </svg>
         </div>} */}
                <div
                  className="icon-lang marg"
                  onClick={() => {
                    props.Update(props.data);
                  }}
                >
                  <div className="icon-lang-desc">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <defs>
                        <filter
                          id="Ellipse_319"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="3" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="3" result="blur" />
                          <feFlood floodOpacity="0.2" />
                          <feComposite operator="in" in2="blur" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g
                        transform="matrix(1, 0, 0, 1, 0, 0)"
                        filter="url(#Ellipse_319)"
                      >
                        <circle
                          id="Ellipse_319-2"
                          data-name="Ellipse 319"
                          cx="3"
                          cy="3"
                          r="3"
                          transform="translate(9 6)"
                          fill="#404040"
                        />
                      </g>
                    </svg>
                    {/* ATTENTION!! ADDING CONDITION NEXT LINE */}
                    <div
                      className="icon-lang-desc-bu"
                      style={{ whiteSpace: "nowrap", left: "-24px" }}
                    >
                      {trans("edit")}
                    </div>
                  </div>
                  <svg
                    className="gray-color"
                    id="_15x15_photo_back"
                    data-name="15x15 photo back"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                  >
                    <g data-name="Mask Group 65">
                      <g
                        id="Group_4666"
                        data-name="Group 4666"
                        transform="translate(0.063 0)"
                      >
                        <g id="user" transform="translate(0.258)">
                          <path
                            id="Path_16021"
                            data-name="Path 16021"
                            d="M13.436,24.162l1.583-.366L13.8,22.579Z"
                            transform="translate(-6.428 -9.162)"
                            fill="#8e8e8e"
                          />
                          <path
                            id="Path_16022"
                            data-name="Path 16022"
                            d="M9.213,18.943l.262-1.136v-.047l.006-.014v-.009l.008-.014v-.008L9.5,17.7l.006-.008.011-.012h0l3.531-3.531a5.421,5.421,0,0,0-4.867-3.239A5.239,5.239,0,0,0,4.19,12.762a7.346,7.346,0,0,0-1.668,4.726A13.977,13.977,0,0,0,8.178,19a10.04,10.04,0,0,0,1.035-.055Z"
                            transform="translate(-2.521 -4.985)"
                            fill="#8e8e8e"
                          />
                          <path
                            id="Path_16023"
                            data-name="Path 16023"
                            d="M0,0H5.927V2.156H0Z"
                            transform="translate(7.595 12.882) rotate(-45)"
                            fill="#8e8e8e"
                          />
                          <circle
                            id="Ellipse_318"
                            data-name="Ellipse 318"
                            cx="2.694"
                            cy="2.694"
                            r="2.694"
                            transform="translate(2.963)"
                            fill="#8e8e8e"
                          />
                          <path
                            id="Path_16024"
                            data-name="Path 16024"
                            d="M23.32,15.209a1.078,1.078,0,0,0-1.84-.762L23,15.966A1.07,1.07,0,0,0,23.32,15.209Z"
                            transform="translate(-9.309 -6.137)"
                            fill="#8e8e8e"
                          />
                        </g>
                        <rect
                          id="Rectangle_4536"
                          data-name="Rectangle 4536"
                          width="14.875"
                          height="14.875"
                          transform="translate(0 0.125)"
                          fill="none"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div
                  className="close-button"
                  onClick={() => {
                    props.Close();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.9"
                    height="9.899"
                    viewBox="0 0 9.9 9.899"
                  >
                    <g
                      id="Group_4637"
                      data-name="Group 4637"
                      transform="translate(-224.153 -513.36) rotate(45)"
                    >
                      <line
                        id="Line_962"
                        data-name="Line 962"
                        x1="12"
                        transform="translate(522.5 204.5)"
                        fill="none"
                        stroke="#ff2e2e"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                      <line
                        id="Line_963"
                        data-name="Line 963"
                        x1="12"
                        transform="translate(528.5 198.5) rotate(90)"
                        fill="none"
                        stroke="#ff2e2e"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            )}
          </div>
          <div className="modal-body">
            <>
              <div className="modal-icon-section">
                <div className="modal-icon-name">{trans("sub_pdf")}</div>
                <div className="modal-icons">
                  {data.subscription_data.files.forEach((a, index) => (
                    <PDFElem clear={() => { let arr = []; data.subscription_data.files.forEach((item, ind) => { if (ind !== index) arr.push(item) }); dispatch({ type: "NEW-COM", payload: { ...data, subscription_data: { files: [...arr] } } }) }} path={FILE_SERVER + a} />
                  ))}
                  {img_upload.status ? (
                    <div className="pdf-loading">
                      <LoadingImage />
                    </div>
                  ) : (
                    <AddPdf
                      action={() => {
                        updatPhotoPDF(data.subscription_data.files.length);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="modal-input-section">
                {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

                <div className="modal-icon-name">{trans("sub_no")}</div>
                <div className="input-section">
                  <div className="input-holder">
                    <div
                      className="full-input-element pa"
                      style={{ width: "100%" }}
                    >
                      <InputText
                        noFloat
                        disabled={mode === "read"}
                        clear={() => {
                          dispatch({
                            type: "NEW-COM",
                            payload: { ...data, subscription_no: "" },
                          });
                        }}
                        lang={getPhone(data.subscription_no)}
                        setLang={(e) => {
                          dispatch({
                            type: "NEW-COM",
                            payload: {
                              ...data,
                              subscription_no: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-input-section">
                <div className="modal-icon-name">
                  {trans("sub_date_regas")}
                </div>
                <div className="input-section">
                  <div className="input-holder">
                    <div
                      className="full-input-element"
                      style={{ paddingLeft: "0px", width: "109px" }}
                    >
                      <input
                        style={{ width: "100%" }}
                        id="caland"
                        className="cal"
                        type={"date"}
                        onChange={(e) => {
                          dispatch({
                            type: "NEW-COM",
                            payload: {
                              ...data,
                              subscription_date: e.target.value,
                            },
                          });
                        }}
                      />
                      <InputText
                        noFloat
                        disabled={mode === "read"}
                        clear={() => {
                          dispatch({
                            type: "NEW-COM",
                            payload: { ...data, subscription_date: "" },
                          });
                        }}
                        lang={data.subscription_date}
                        setLang={(e) => { }}
                      />
                    </div>
                    <div
                      className="full-input-element pa"
                      style={{ paddingLeft: "0px", width: "268px" }}
                    >
                      <InputText
                        noFloat
                        disabled={mode === "read"}
                        clear={() => {
                          dispatch({
                            type: "NEW-COM",
                            payload: { ...data, registered_as: "" },
                          });
                        }}
                        lang={data.registered_as}
                        setLang={(e) => {
                          dispatch({
                            type: "NEW-COM",
                            payload: { ...data, registered_as: e.target.value },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </>
      );
    }
  };

  const showIcons = () => {
    return (
      <>
        <div className="modal-icon-section">
          <div className="modal-icon-name">{trans("com_logo")}</div>
          <div className="modal-icons">
            {!(img_upload.status && img_upload.type === "flat_photo_path") ? (
              <div
                className="modal-icon mar cityflag"
                onDragLeave={(e) => (e.target.style.transform = "scale(1)")}
                onDragEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                onDropCapture={(e) => { }}
                dropzone
                onDrop={(e) => {
                  onDropImage(e, "flat_photo_path");
                }}
                onDragOver={(e) => onDragOver(e)}
                onClick={() => updatPhoto("flat_photo_path")}
              >
                {data.flat_photo_path && <span onClick={() => delPhoto("flat_photo_path")} className="abs-delt"><svg data-src={X}></svg></span>}
                {data.flat_photo_path && data.flat_photo_path.length > 0 ? (
                  <img alt=""
                    src={FILE_SERVER + data.flat_photo_path}
                    width="70"
                    height="50"
                  />
                ) : (
                  <img alt=""
                    style={{ width: "70px", height: "50px" }}
                    className="language-flat"
                    src={flagCity}
                  />
                )}
              </div>
            ) : (
              <div className="modal-icon mar cityflag">
                <LoadingImage />
              </div>
            )}
            {!(img_upload.status && img_upload.type === "photo_path") ? (
              <div
                className="modal-icon mar photo"
                style={{ width: "135px" }}
                onDragLeave={(e) => (e.target.style.transform = "scale(1)")}
                onDragEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                onDropCapture={(e) => { }}
                dropzone
                onDrop={(e) => {
                  onDropImage(e, "photo_path");
                }}
                onDragOver={(e) => onDragOver(e)}
                onClick={() => updatPhoto("photo_path")}
              >
                {data.photo_path && <span onClick={() => delPhoto("photo_path")} className="abs-delt"><svg data-src={X}></svg></span>}
                {data.photo_path && data.photo_path.length > 0 ? (
                  <img alt=""
                    src={FILE_SERVER + data.photo_path}
                    width="135"
                    height="50"
                  />
                ) : (
                  <img alt=""
                    style={{ width: "135px", height: "50px" }}
                    className="language-flat"
                    src={photoCity}
                  />
                )}
              </div>
            ) : (
              <div className="modal-icon mar photo">
                <LoadingImage />
              </div>
            )}
          </div>
        </div>
      </>
    );
  };
  const showInputs = () => {
    return (
      <>
        <div className="modal-input-section">
          {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

          <div className="modal-icon-name">{trans("com_name")}</div>
          <div className="input-section">
            <div className="input-holder">
              <div className="short-input-element" style={{ width: "37px" }}>
                <input
                  readOnly={mode === "read"}
                  onChange={(e) => {
                    if (e.target.value.length <= 3) {
                      dispatch({
                        type: "NEW-COM",
                        payload: { ...data, short_name: e.target.value },
                      });
                    }
                  }}
                  value={data.short_name}
                  type="text"
                />
                <img alt=""
                  onClick={() =>
                    dispatch({
                      type: "NEW-COM",
                      payload: { ...data, short_name: "" },
                    })
                  }
                  
                  src={X}
                />
              </div>
              <div
                className="full-input-element pa"
                style={{ paddingLeft: "10px" }}
              >
                <InputText
                  disabled={mode === "read"}
                  code={
                    data.translations.filter((t) => t.language_code === "en")[0]
                      .language_code
                  }
                  pad={true}
                  clear={() => {
                    ChangeTrans(
                      data.translations.filter(
                        (t) => t.language_code === "en"
                      )[0].language_code,
                      ""
                    );
                  }}
                  lang={
                    data.translations.filter((t) => t.language_code === "en")[0]
                      .name
                  }
                  setLang={(e) => {
                    ChangeTrans(
                      data.translations.filter(
                        (t) => t.language_code === "en"
                      )[0].language_code,
                      e.target.value
                    );
                  }}
                />
              </div>
            </div>
            <div className="trans-input-holder">
              {data.translations.forEach((t, index) => {
                return (
                  <>
                    {t.language_code !== "en" && (
                      <div
                        className="trans-input-element"
                        style={{ width: "109px" }}
                      >
                        <InputText
                          disabled={mode === "read"}
                          code={t.language_code}
                          pad={true}
                          clear={() => {
                            ChangeTrans(t.language_code, "");
                          }}
                          lang={t.name}
                          setLang={(e) => {
                            ChangeTrans(t.language_code, e.target.value);
                          }}
                        />
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
        {view === "Communications Companies" ? (
          <div className="modal-input-section">
            <div className="modal-icon-name">{trans("comp_ph_cds")}</div>
            <div className="input-container">
              {mode === "read" && (
                <div className="options-code">
                  {data.company_phone_codes.split(",").forEach((s) => (
                    <dv className="option-code">{s}</dv>
                  ))}
                </div>
              )}
              {mode !== "read" && (
                <input
                  readOnly={mode === "read"}
                  pattern="[0-9,]"
                  className="num-icp"
                  placeholder="992,555,888"
                  type={"text"}
                  value={data.company_phone_codes}
                  onChange={(e) => {
                    dispatch({
                      type: "NEW-COM",
                      payload: { ...data, company_phone_codes: e.target.value },
                    });
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="modal-input-section">
            <div className="modal-icon-name">{view} Service Area</div>
            {data.company_regions && data.company_regions.length > 0 && (
              <div className="reg-comp">
                {data.company_regions.forEach((a, index) => (
                  <div
                    className="reg-item"
                    onClick={() => {
                      if (mode !== "read") {
                        dispatch({
                          type: "NEW-COM",
                          payload: {
                            ...data,
                            regions: data.regions.filter(
                              (d, ind) => ind !== index
                            ),
                            company_regions: data.company_regions.filter(
                              (d, ind) => ind !== index
                            ),
                          },
                        });
                      }
                    }}
                  >
                    {a}
                  </div>
                ))}
              </div>
            )}
            <div className="input-container">
              {mode !== "read" && (
                <input
                  onFocus={() => {
                    setFocuse(true);
                  }}
                  onBlur={() =>
                    setTimeout(() => {
                      setFocuse(false);
                    }, 400)
                  }
                  readOnly={mode === "read"}
                  className="num-icp"
                  type={"text"}
                  value={data.regs}
                  onChange={(e) => {
                    dispatch({
                      type: "NEW-COM",
                      payload: { ...data, regs: e.target.value },
                    });
                    dispatch({ type: "ASYNC_REG", payload: e.target.value });
                  }}
                />
              )}
              {reg_loading ? (
                <>
                  <div className="region-cont f-c">
                    <Spinner />
                  </div>
                </>
              ) : (
                focuses &&
                regs.length > 0 &&
                data.regs.length > 0 && (
                  <div className="region-cont">
                    {regs.forEach((a) => (
                      <div
                        onClick={() => {
                          dispatch({
                            type: "NEW-COM",
                            payload: {
                              ...data,
                              regs: "",
                              regions: [...data.regions, a.id],
                              company_regions: [
                                ...data.company_regions,
                                a.region_translations.filter(
                                  (a) => a.language_code === "en"
                                )[0].region_name,
                              ],
                            },
                          });
                        }}
                      >
                        {
                          a.region_translations.filter(
                            (a) => a.language_code === "en"
                          )[0].region_name
                        }
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </>
    );
  };
  const {
    open,
    setOpen,
    setExpand,
  } = props;
  const dispatch = useDispatch();
  const ChangeTrans = (lang_code, value) => {
    let translations = data.translations;
    let arr = [];
    translations.forEach((d) => {
      if (d.language_code === lang_code) arr.push({ ...d, name: value });
      else arr.push({ ...d });
    });
    dispatch({ type: "NEW-COM", payload: { ...data, translations: [...arr] } });
  };

  const ButtonValidate = () => {
    let bool,
      inp_bool = false;
    if (data.short_name && data.short_name.length > 0) bool = true;
    else bool = false;
    data.translations.forEach((s) => {
      if (s.name.length === 0) bool = false;
    });
    if (view === "Communications Companies") {
      if (data.company_phone_codes && data.company_phone_codes.length > 0)
        inp_bool = true;
      else inp_bool = false;
    } else {
      if (data.regions && data.regions.length > 0) inp_bool = true;
      else inp_bool = false;
    }
    return bool && inp_bool;
  };
  const updateImage = async (file, type) => {
    dispatch({ type: "IMG-UPLOAD-COM", payload: { status: true, type: type } });

    let pat = await upload(file);
    let tmp = { ...data };
    tmp[`${type}`] = pat;
    dispatch({ type: "NEW-COM", payload: { ...tmp } });
    dispatch({
      type: "IMG-UPLOAD-COM",
      payload: { status: false, type: null },
    });
    dispatch({ type: "LEVEL-DONE-COM" });
  };
  const delPhoto = (typ) => {
    let tmp = data
    tmp[`${typ}`] = null
    dispatch({ type: "NEW-COM", payload: { ...tmp } })
  }
  const updatPhoto = (name) => {
    if (mode !== "read") {
      let Image = document.createElement("input");
      Image.onchange = (e) => {
        updateImage(e.target.files[0], name);
      };
      Image.type = "file";
      Image.hidden = true;
      Image.accept = "image/*";
      Image.style = { position: "absolute", opacity: "0" };
      let i = document.body.appendChild(Image);
      i.click();
    }
  };
  const onDropImage = (file, type) => {
    if (mode !== "read") {
      file.preventDefault();
      if (
        file.dataTransfer.files.length === 1 &&
        file.dataTransfer.files[0].type === "image/svg+xml"
      ) {
        updateImage(file.dataTransfer.files[0], type);
      } else {
        toast.error(
          "Invalid File Type Svg Files are the Only Allowed File Type"
        );
      }
    }
  };
  const view = useSelector((state) => state.countries.view);
  const onDragOver = (e) => {
    if (mode !== "read") e.preventDefault();
  };
  const sendRequest = () => {
    if (ButtonValidate()) {
      if (mode === "add") {
        dispatch({ type: "ADD-COM", payload: data, view: view });
      } else {
        dispatch({ type: "UPD-COM", payload: data, view: view });
      }
    } else {
      document.querySelectorAll(".modal-input-section input").forEach((inp) => {
        if (inp.value.length < 2) {
          inp.parentElement.classList.add("shake-modal");
          inp.classList.add("red-bord");
          setTimeout(() => {
            inp.parentElement.classList.remove("shake-modal");
          }, 400);
          setTimeout(() => {
            inp.classList.remove("red-bord");
          }, 1000);
        }
      });
    }
  };
  const showModal = () => {
    switch (props.languageFollower.length) {
      case 2:
        return trans("add_new_com");
      case 3:
        return trans("add_new_serv");
      case 4:
        return trans("add_new_subscr");
      default:
        break;
    }
  };
  return (
    <>
      {mode !== "read" && (
        <div
          onClick={(e) => shake(e)}
          className={`lang-modalDisable ${open && "open"}`}
        ></div>
      )}
      <div
        style={{ width: "428px", height: "435px" }}
        className={`lang-modal ${open && "open"} ${props.fixModal}`}
      >
        {showContent()}
      </div>
    </>
  );
}

export default CompainesModal;
