import React from "react";
import X from "./xicon.svg";
import FloatingCharacter from "./floatingCharacter";
function InputText(props) {
  const { lang, setLang } = props;
  return (
    <>
      {props.code && (
        <svg
          className="lang-input-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="14"
          viewBox="0 0 13 14"
        >
          <text
            id="AR"
            transform="translate(0 11)"
            fill="#CECDCD"
            fontSize="10"
            fontFamily="SegoeUI, Segoe UI"
            letterSpacing="0.025em"
          >
            <tspan x="0" y="0">
              {props.code.toUpperCase()}
            </tspan>
          </text>
        </svg>
      )}
      <input
        className={`${props.pad && "pad20"}`}
        style={props.pad ? { paddingLeft: "20px" } : { paddingLeft: "0px" }}
        readOnly={props.disabled}
        onChange={(e) => {
          if (e.target.value.length <= 30) setLang(e);
        }}
        value={lang}
        defaultValue={props.type === "date" ? new Date() : ""}
        type={props.type === "date" ? "date" : "text"}
      />
      <img
        style={{ bottom: "9px", right: "2px" }}
        onClick={() => props.clear()}
        alt="close"
        src={X}
      />
      {props.noFloat === false && (
        <FloatingCharacter value={-(30 - lang.length)} />
      )}
    </>
  );
}

export default InputText;
