import axios from "axios";
import { BACKEND_FIles_URL } from "../constants/endpointConfig.js";
import { getLoggedInUser } from "./authUtils";
import { store } from "../redux/store";
const AxiosInstance = axios.create({
  baseURL:
    store?.getState().whatsapp.fetched_url === true
      ? store?.getState()?.whatsapp.base_url +
      "/wallet-full-backend/public"
      : process.env.REACT_APP_BASE_URL +
      "/wallet-full-backend/public",
  timeout: 0,
  headers: {
    Authorization:
      "Bearer " +
      (localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).access_token),
    current_role_id:
      localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).role_id
        ? localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).role_id
        : "-1",
    // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
    "Content-Type": "application/json",
  },
});
export const AxiosInstanceUser = axios.create({
  baseURL:
    store?.getState().whatsapp.fetched_url === true
      ? store?.getState()?.whatsapp.base_url + "/wallet-full-backend/public"
      : process.env.REACT_APP_BASE_URL + "/wallet-full-backend/public",
  timeout: 0,
  headers: {
    Authorization:
      "Bearer " +
      (localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).access_token),
    current_role_id:
      localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).role_id
        ? localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).role_id
        : "-1",
    // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
    "Content-Type": "application/json",
  },
});

AxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    return Promise.reject(error);
  }
);
export const AxiosFileInstance = axios.create({
  baseURL: BACKEND_FIles_URL,
  timeout: 0,
  headers: {
    Authorization:
      "Bearer " + (getLoggedInUser() && getLoggedInUser().access_token),
    "Content-Type": "application/json",
  },
});

export const AxiosChatInstance = axios.create({
  baseURL: BACKEND_FIles_URL,
  timeout: 0,
  headers: {
    Authorization:
      "Bearer " + (getLoggedInUser() && getLoggedInUser().access_token),
    "Content-Type": "application/json",
  },
});

AxiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer " +
    (localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).access_token);
  config.headers.current_role_id =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).role_id;
  return {
    ...config,
    headers: {
      ...config.headers,
      current_role_id:
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).role_id,
    },
  };
});

export default AxiosInstance;
