import React, { useEffect } from 'react'
import LinkedLanguages from "./LinkedLanguages/LinkedLanguages.js"
import HRM from './HRM';
import { useDispatch, useSelector } from 'react-redux';
import Currencies from './LinkedCurrencies/Currencies';
import Countries from './Countries';
import TranslateComponent from './Translate';
import Transfer from './Transfer/Transfer';
// import Monitoring from './Monitoring';

function Routes(props) {
  const dispatch = useDispatch()
  const roles = useSelector((state) => state.auth.roles)
  const languages = useSelector(state => state.langs.languages)
  // call important endpoints like languages
  useEffect(() => {
    if (languages.length === 0) {
      dispatch({ type: "GET-LANG" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (<>
    {/* <Languages active={props.option.routes==="Languages"} {...props}/> Languages Design */}

    {((localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).role_id === 1) || (roles.includes("ACCOUNT_MODULE") && !roles.includes("USER_EDIT_EMPLOYEE_SALARY"))) && <>
      <Transfer active={props.option.routes === "Transfer"} {...props} />
      {/* <Monitoring active={props.option.routes === "Monitoring"} {...props} /> */}
      {/* <Currencies active={props.option.routes==="Currencies"} {...props}/> */}
      <LinkedLanguages active={props.option.routes === "Languages"} {...props} />
      {/* <Currencies active={props.option.routes==="Currencies"} {...props}/> */}
      <Currencies active={props.option.routes === "Currencies"} {...props} />
      <Countries active={props.option.routes === "Countries"} {...props} />
      {props.option.routes === "Translate" && <TranslateComponent active={props.option.routes === "Translate"} {...props} />}
      <HRM forCustomer={(roles.includes("ACCOUNT_MODULE") && !roles.includes("USER_EDIT_EMPLOYEE_SALARY"))} active={props.option.routes === "HRM"} {...props} />
    </>
    }
  </>)
}

export default Routes