import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import AxiosInstance, {
} from "../../helpers/axiosInstance";
import { GET_REGIONS_URL } from "./constants";
import { toast } from "react-toastify";
import store from "../store";
function* getAllRegions() {
    try {
        let response = yield AxiosInstance.get(
            store?.getState().whatsapp.fetched_url === true
                ? store?.getState()?.whatsapp.base_url +
                "/wallet-full-backend/public" +
                GET_REGIONS_URL
                : process.env.REACT_APP_BASE_URL +
                "/wallet-full-backend/public" +
                GET_REGIONS_URL
        );
        yield put({ type: "GET_REGIONS_REDUCER", payload: response.data.data });
    } catch (e) {
        if (e.message) {
            toast.error(e.message);
        } else toast.error("failed! please check conection");
    }
}

function* watchGetAllRegions() {
    yield takeEvery("GET_REGIONS_SAGA", getAllRegions);
}

export function* RegionsSaga() {
    yield all([
        fork(watchGetAllRegions)
    ]);
}