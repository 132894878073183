import { all, fork, put, takeEvery } from 'redux-saga/effects';
import AxiosInstance from '../../helpers/axiosInstance';
function* getCategories(action){
    try{
        let response = yield AxiosInstance.get( "api/v1/categories/index")
        const payload = response.data.data.map(cat => {
                return {
                    id: cat.id,
                    icon: null,
                    routes: "category",
                    name: cat.category_translations[0].category_name,
                    childreen: cat?.sub_categories ? cat?.sub_categories.map(cat => {
                        return {
                            id: cat.id,
                            icon: null,
                            routes: "category",
                            name: cat.category_translations[0].category_name,
                            childreen: cat?.sub_categories ? cat?.sub_categories.map(cat => {
                                return {
                                    id: cat.id,
                                    icon: null,
                                    routes: "category",
                                    name: cat.category_translations[0].category_name,
                                    childreen: cat?.sub_categories ? cat?.sub_categories.map(cat => {
                                        return {
                                            id: cat.id,
                                            icon: null,
                                            routes: "category",
                                            name: cat.category_translations[0].category_name,
                                            childreen: cat?.sub_categories ? cat?.sub_categories.map(cat => {
                                                return {
                                                    id: cat.id,
                                                    icon: null,
                                                    routes: "category",
                                                    name: cat.category_translations[0].category_name,
                                                    childreen: cat?.sub_categories ? cat?.sub_categories.map(cat => {
                                                        return {
                                                            id: cat.id,
                                                            icon: null,
                                                            routes: "category",
                                                            name: cat.category_translations[0].category_name,
                                                            childreen: cat?.sub_categories ? cat?.sub_categories.map(cat => {
                                                                return {
                                                                    id: cat.id,
                                                                    icon: null,
                                                                    routes: "category",
                                                                    name: cat.category_translations[0].category_name,
                                                                    childreen: cat?.sub_categories ? cat?.sub_categories.map(cat => {
                                                                        return {
                                                                            id: cat.id,
                                                                            icon: null,
                                                                            routes: "category",
                                                                            name: cat.category_translations[0].category_name,
                                                                            childreen: []
                                                                        }
                                                                    }) : cat?.sub_categories ? cat?.sub_categories.map(cat => {
                                                                        return {
                                                                            id: cat.id,
                                                                            icon: null,
                                                                            routes: "category",
                                                                            name: cat.category_translations[0].category_name,
                                                                            childreen: cat?.sub_categories ? cat?.sub_categories.map(cat => {
                                                                                return {
                                                                                    id: cat.id,
                                                                                    icon: null,
                                                                                    routes: "category",
                                                                                    name: cat.category_translations[0].category_name,
                                                                                    childreen: []
                                                                                }
                                                                            }) : []
                                                                        }
                                                                    }) : []
                                                                }
                                                            }) : []
                                                        }
                                                    }) : []
                                                }
                                            }) : []
                                        }
                                    }) : []
                                }
                            }) : []
                        }
                    }) : []
                }
            })
        yield put({type:"GET_CATEGORIES_REDUCER",payload })
    }
    catch (e){
       
        yield put({type:"waiter"})
    }
}
function* watchGetCategories(){
    yield takeEvery("GET_CATEGORIES_SAGA",getCategories)
}
function* allCategoriesSaga() {
    yield all([
        fork(watchGetCategories)
    ]);
}

export default allCategoriesSaga;








       