import axios from "axios";
import {
  all,
  fork,
  put,
  takeEvery
} from "redux-saga/effects";
import {
  OFFLINE
} from 'redux-offline-status/lib/actions'
import store from "../store";




function* getGroupInfo(action) {
  try {
    const token = store.getState()?.auth.user?.access_token;
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    // yield put({ type: "WA_LOADING" });
    let res = yield AxiosInstace.get(
      "/api/v1/whatsapp_groups/show/" +
      action.id
    );

    yield put({
      type: "GI_RED",
      payload: { id: action.id, data: res.data.data.group_meta_data_json },
    });
    // yield put({ type: "WA_DONE" });
  } catch (e) {
    // yield put({ type: "WA_DONE" });
  }
}
function* watchGetGroupInfo() {
  yield takeEvery("GET_GI", getGroupInfo);
}
function* GetChat(action) {
  try {
    const token = store.getState()?.auth.user?.access_token;
    if (
      (store.getState().whatsapp.sending ||
        store.getState().whatsapp.wa_loading)

    ) {

    }
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });

    let res;
    if (!store.getState().whatsapp.wa_loading) {
      // yield put({ type: "WA_LOADING" });
      res = yield AxiosInstace.get(
        `/api/v1/whatsapp/get_chats_with_latest_messages?limit=20`,
      );
      // yield put({ type: "WA_DONE" });
      if (!store.getState().whatsapp.wa_loading)
        if (action.isFirst)
          yield put({ type: "GET_CHAT_WA_RED", payload: res.data.data });
        else {
          if (!store.getState().whatsapp.sending)
            yield put({ type: "UPD_CHAT_WA_RED", payload: res.data.data, scr: true, not: action.not });
        }
    }
  } catch (e) {
    store.dispatch({ type: OFFLINE });

  }
}
function* watchGetChat() {
  yield takeEvery("GET_WA_CHATS", GetChat);
}
function* sendText(action) {
  try {
    const token = store.getState()?.auth.user?.access_token;
    let res;
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let AxiosFileInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    });


    // yield put({ type: "WA_LOADING" });
    if (!action.isGroup) {
      res = yield AxiosInstace.post(
        "/api/v1/whatsapp/send_message_to_user",
        JSON.stringify(action.pay)
      );
      yield put({
        type: "UPLOAD_SUC",
        mid: action.mid,
        cid: action.cid,
        id: res.data.data.id,
      });
    }
    else {
      res = yield AxiosFileInstace.post(
        "/api/v1/whatsapp/send_message_to_group",
        action?.pay
      );
      yield put({
        type: "UPLOAD_SUC",
        mid: action.mid,
        cid: action.cid,
        id: res.data.data.id,
      });
    }
    yield put({ type: "SENDING_END" });
    // setTimeout(() => {
    //   put({ type: "WA_DONE" });
    //   put({ type: "SENDING_END" });
    // }, 3000);
  } catch (e) {
    console.error(e);
    put({ type: "SENDING_END" });
  }
}
function* watchSendText() {
  yield takeEvery("SEND_TEXT_SAGA", sendText);
}

function* getPage(action) {
  try {
    let id = action.id
    let page = action.page;
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    const token = store.getState()?.auth.user?.access_token;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    //EDIT
    let resp = yield AxiosInstace.get(
      `/api/v1/whatsapp/get_messages_of_chat?limit=500&last_whatsapp_chat_message_id=${page}&whatsapp_chat_id=${id}`
    );
    if (resp.data.data.length === 0) {
      yield put({ type: "CONV_REACHED_END", payload: [id] })
    } else {
      yield put({ type: "GET_PAGE", payload: resp.data.data });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: "GET_PAGE_CHAT_RED", payload: [] });
  }
}
function* watchGetPage() {
  yield takeEvery("GET_PAGE_CHAT", getPage);
}
// function* sendFile(action) {
//   try {
//     const token = store.getState()?.auth.user?.access_token;

//     const base_user =
//       store?.getState().whatsapp.fetched_url === true
//         ? store?.getState()?.whatsapp.base_user
//         : process.env.REACT_APP_USER_BASE_URL;
//     let AxiosInstace = axios.create({
//       baseURL: `${base_user}`,
//       headers: {
//         Authorization: "Bearer " + token,
//         "Content-Type": "application/json",
//       },
//     });
//     let resp = yield AxiosInstace.post(
//       "/api/v1/whatsapp/send_document_message",
//       JSON.stringify(action.pay)
//     );
//     yield put({
//       type: "UPLOAD_SUC",
//       mid: action.mid,
//       cid: action.cid,
//       id: resp.data.data.id,
//     });
//   } catch (e) {}
// }
// function* watchSendFile() {
//   yield takeEvery("SEND_FILE_SAGA", sendFile);
// }
function* SendForward(action) {
  try {
    const token = store.getState()?.auth.user?.access_token;
    let res;
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    res = yield AxiosInstace.post("/api/v1/whatsapp/forward_message", JSON.stringify({ whatsapp_chat_message_id: action.msId.toString(), whatsapp_chat_id: action.chatId, id: "4" }));
    yield put({
      type: "UPLOAD_SUC",
      mid: action.mid,
      cid: action.cid,
      id: res.data.data.id,
    });
  } catch (e) {

  }
}
function* watchForward() {
  yield takeEvery("SEND_TEXT_SAGA_FORWARD", SendForward)
}

function* changeName(action) {
  const token = store.getState()?.auth.user?.access_token;

  const base_user =
    store?.getState().whatsapp.fetched_url === true
      ? store?.getState()?.whatsapp.base_user
      : process.env.REACT_APP_USER_BASE_URL;
  let AxiosInstace = axios.create({
    baseURL: `${base_user}`,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  try {
    yield AxiosInstace.put("api/v1/whatsapp_contacts/" + action.id, JSON.stringify({ name: action.payload.name, whatsapp_contact_company_id: action.payload.cid, whatsapp_contact_role_id: action.payload.rid }))
  }
  catch (e) { }
}
function* watchChangeName() {
  yield takeEvery("CHANGE_NAME", changeName)
}
function* getMessages(action) {
  try {
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    const token = store.getState()?.auth.user?.access_token;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let res = yield AxiosInstace.post("api/v1/whatsapp/get_all_messages_between_two_messages", JSON.stringify({
      'first_message_id': action.sid?.toString(),
      'second_message_id': action.qid?.toString(),
      'whatsapp_chat_id': action.cid?.toString()
    }))
    yield put({ type: "GET_MES_RED", payload: res.data.data, cid: action.cid, id: action.sid })
  }
  catch (e) {

  }
}
function* WatchGetMessages() {
  yield takeEvery("GET_MES", getMessages)
}
function* getChatData(action) {
  try {
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    const token = store.getState()?.auth.user?.access_token;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let res = yield AxiosInstace.get("/api/v1/whatsapp_contact_roles")
    yield put({ type: "GET_ROLS", payload: res.data.data })
    let resd = yield AxiosInstace.get("/api/v1/whatsapp_contact_companies")
    yield put({ type: "GET_COMPA", payload: resd.data.data })
    let resp = yield AxiosInstace.get("api/v1/whatsapp_contacts")
    yield put({ type: "GET_CONTACT", payload: resp.data.data })
  }
  catch (e) { }
}
function* watchGetData() {
  yield takeEvery("GET_DATA_CHA", getChatData)
}
function* readChat(action) {
  try {
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    const token = store.getState()?.auth.user?.access_token;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    yield AxiosInstace.get("/api/v1/whatsapp/read_chat_messages?whatsapp_chat_id=" + action.id)

  }
  catch (e) { }
}
function* watchReadChat() {
  yield takeEvery("READ_CHAT", readChat)
}
function* getChat(action) {
  try {
    let id = action.id
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    const token = store.getState()?.auth.user?.access_token;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let res = yield AxiosInstace.get(`/api/v1/whatsapp/get_one_chat_with_latest_messages/${id}?limit=600`)
    yield put({ type: "GET_PAGE", payload: res.data.data.messages });
  }
  catch (e) { }
}
function* watchGetOneChat() {
  yield takeEvery("GET_WA_ONE_CHAT", getChat)
}
function* searchWord(action) {
  try {
    const token = store.getState()?.auth.user?.access_token;
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });

    let res;
    if (!store.getState().whatsapp.wa_loading) {
      res = yield AxiosInstace.get(
        `/api/v1/whatsapp/get_chats_with_latest_messages?search=${action.payload}`,
      );

      if (!store.getState().whatsapp.wa_loading)
        yield put({
          type: "SEARCH_WA", payload: {
            search: action.payload,
            data: res.data.data
          }
        });
    }
  } catch (e) {
    store.dispatch({ type: OFFLINE });

  }
}
function* watchSearchWord() {
  yield takeEvery("SEARCH_WA_SAGA", searchWord)
}
export default function* WhatsappSaga() {
  yield all([
    fork(watchGetChat),
    fork(watchSendText),
    fork(watchGetPage),
    fork(watchGetGroupInfo),
    // fork(watchSendFile),
    fork(watchForward),
    fork(watchChangeName),
    fork(WatchGetMessages),
    fork(watchGetData),
    fork(watchReadChat),
    fork(watchGetOneChat),
    fork(watchSearchWord)
  ]);
}
