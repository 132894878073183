import React from 'react'
import { useSelector } from 'react-redux'
import CountryItem from './CountryItem'

function CountriesChild(props) {
  const {lang_id, setLang_id} = props
  const data = useSelector((state)=>state.countries.newDepartment)
  const mode = useSelector((state)=>state.countries.mode)
  const requested_data = useSelector((state)=>state.countries.request_data)
  const Active_Ids = useSelector((state)=>state.countries.mode)

  return (<>
    <div className='lang-childreen department'>
      {props.child.sub_regions.map((child)=>(
          <CountryItem 
          lang={props.lang}
          languageFollower={props.languageFollower} 
          level={props.level}
          ActiveAdd={(e)=>props.ActiveAdd(e)}
          makeFollower={(e,w,id)=>props.makeFollower(e,w,id)} 
          accent={"accent"}
          lang_id={lang_id}
          Active_Ids={Active_Ids}
          setModalData={(e)=>props.setModalData(e)}
          setLang_id={(e)=>setLang_id(e)}
          child={child}
          open={props.open}
          Close={()=>props.Close()}
          Add={(e)=>props.Add(e)}
          Update={(e)=>props.Update(e)}
          Read={(e)=>props.Read(e)}
          setOpen={(e)=>props.setOpen(e)}/>
      ))} 
         {(((props.open)&&parseInt(props.lang_id)===parseInt(props.child.id)&&mode==="add")||((requested_data&&requested_data.parent_region_id===props.child.id)&&mode!=="update"&&mode!=="read"))&& <CountryItem     lang={props.lang} {...props}  languageFollower={props.languageFollower}  new={"new"}  level={props.level}  makeFollower={(e,w,id)=>props.makeFollower(e,w,id)} accent={"accent"} lang_id={lang_id} setLang_id={(e)=>setLang_id(e)} child={requested_data?requested_data: data}/>}
  </div>
    {/* ATTENTION!! ADDING PROPS TO THE NEXT LINE languageFollower */}
    
</>

)
}

export default CountriesChild