import React from 'react'
import calls from "../../../assets/svg/call.svg";
import vcall from "../../../assets/svg/vcall.svg";
import { FILE_SERVER } from '../../../constants';
import ProfileNo from "../WhatsApp/assets/images/profileNo.png";

function ChatHeader({ close, getNewMessage, user, typing, call, makeCall, activeChat }) {
  return (
    <header className="header chat__header cht-hed">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        className="back_icon"
        onClick={() => close()}
      >
        <path
          fill="#aeb4b7"
          d="M12 4l1.4 1.4L7.8 11H20v2H7.8l5.6 5.6L12 20l-8-8 8-8z"
        ></path>
      </svg>
      {getNewMessage() > 0 && <span className="newed">{getNewMessage()}</span>}
      <div className="chat__avatar-wrapper" >
        {activeChat?.channel_type.slug === "team" ?
          <img
            src={activeChat?.photo_path ? FILE_SERVER + user.photo_path : ProfileNo}
            alt={activeChat.channel_translations[0].name}
            className="avatar"
          /> :

          <img
            src={user?.avatar ? FILE_SERVER + user.avatar : ProfileNo}
            alt={user?.full_name ? user.full_name : user?.username}
            className="avatar"
          />}
      </div>

      <div className="chat__contact-wrapper" >
        <h2 className="chat__contact-name">
          {" "}
          {activeChat?.channel_type.slug === "team" ?
            activeChat.channel_translations[0].name
            : user?.full_name ? user.full_name : user?.username}
        </h2>
        <p className="chat__contact-desc">
          {typing && typing}
        </p>
      </div>
      <div className="chat__actions">
        <button
          className="chat__action"
          aria-label="Search"
          onClick={() => makeCall("video")}
        >
          <svg data-src={vcall}></svg>
        </button>
        <button
          className="chat__action"
          aria-label="Search"
          onClick={() => makeCall("audio")}
        >
          <svg className='call' data-src={calls}></svg>

        </button>
      </div>
    </header>
  )
}

export default ChatHeader