import React from "react";
import InputText from "./inputs/InputText";
import outline from "../../Languages/svgs/outline.svg";
import outlineCity from "./Icons/OutlineIcon.svg";
import flatCity from "./Icons/FlatIcon.svg";
import photoCity from "./Icons/PhotoIcon.svg";
import X from "../../Languages/svgs/xicon.svg";
import flag from "./Icons/FlagIcon.svg";
import flagCity from "./Icons/flagCity.svg";
import map from "./Icons/MapIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { FILE_SERVER } from "../../../../../constants";
import { upload } from "../../../../../uploadService";
import { toast } from "react-toastify";
import Empty from "../../../../../assets/svg/empty.svg";
import LoadingImage from "../../../LoadingImage";
import "../countries.css";
import SmallIcon from "../../Languages/svgs/SmallIcon";
import { trans } from '../../../../../Internationalization'
function CountriesModal(props) {
  const languages = useSelector((state) => state.langs.languages);
  const mode = useSelector((state) => state.countries.mode);
  const img_upload = useSelector((state) => state.countries.img_upload);
  const curr = useSelector((state) => state.countries.test);
  const modalData = useSelector((state) => state.countries.modalData);
  const data = props.data;
  const getPhone = (a) => {
    var regExp = /[a-zA-Z]/g;

    if (regExp.test(a)) {
      return "NAN"
    } else {
      if (parseInt(a) > 24)
        return "24"
      else return (a)
    }

  };
  const shake = (e) => {
    document.querySelector(".lang-modal.open").classList.add("shake-modal");
    setTimeout(() => {
      document
        .querySelector(".lang-modal.open")
        .classList.remove("shake-modal");
    }, 400);
  };
  const showIcons = () => {
    if (
      props.languageFollower.length === 1 ||
      (props.languageFollower.length === 2 && mode !== "add")
    )
      return (
        <>
          <div className="modal-icon-section">
            <div className="modal-icon-name">
              {showModal()}  {trans("logo_photo_map")}
            </div>
            <div className="modal-icons">
              {!(
                img_upload.status && img_upload.type === "outline_photo_path_1"
              ) ? (
                <div
                  className="modal-icon"
                  onDragLeave={(e) => (e.target.style.transform = "scale(1)")}
                  onDragEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                  onDropCapture={(e) => { }}
                  dropzone
                  onDrop={(e) => {
                    onDropImage(e, "outline_photo_path_1");
                  }}
                  onDragOver={(e) => onDragOver(e)}
                  onClick={() => updatPhoto("outline_photo_path_1")}
                >
                  {data.outline_photo_path_1 && <span onClick={() => delPhoto("outline_photo_path_1")} className="abs-delt"><svg data-src={X}></svg></span>}

                  {data.outline_photo_path_1 &&
                    data.outline_photo_path_1.length > 0 ? (
                    <svg
                      data-src={FILE_SERVER + data.outline_photo_path_1}
                      width="50"
                      height="50"
                    ></svg>
                  ) : (
                    <img alt=""
                      
                      style={{ marginLeft: "5px" }}
                      src={outline}
                    />
                  )}
                </div>
              ) : (
                <div className="modal-icon">
                  <LoadingImage />
                </div>
              )}
              {!(
                img_upload.status && img_upload.type === "outline_photo_path_2"
              ) ? (
                <div
                  className="modal-icon mar"
                  onDragLeave={(e) => (e.target.style.transform = "scale(1)")}
                  onDragEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                  onDropCapture={(e) => { }}
                  dropzone
                  onDrop={(e) => {
                    onDropImage(e, "outline_photo_path_2");
                  }}
                  onDragOver={(e) => onDragOver(e)}
                  onClick={() => updatPhoto("outline_photo_path_2")}
                >
                  {data.outline_photo_path_2 && <span onClick={() => delPhoto("outline_photo_path_2")} className="abs-delt"><svg data-src={X}></svg></span>}

                  {data.outline_photo_path_2 &&
                    data.outline_photo_path_2.length > 0 ? (
                    <svg
                      data-src={FILE_SERVER + data.outline_photo_path_2}
                      width="50"
                      height="50"
                    ></svg>
                  ) : (
                    <img alt=""
                      
                      style={{ marginLeft: "5px" }}
                      src={outline}
                    />
                  )}
                </div>
              ) : (
                <div className="modal-icon mar">
                  <LoadingImage />
                </div>
              )}
              {!(img_upload.status && img_upload.type === "flag_photo_path") ? (
                <div
                  className="modal-icon mar flag"
                  onDragLeave={(e) => (e.target.style.transform = "scale(1)")}
                  onDragEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                  onDropCapture={(e) => { }}
                  dropzone
                  onDrop={(e) => {
                    onDropImage(e, "flag_photo_path");
                  }}
                  onDragOver={(e) => onDragOver(e)}
                  onClick={() => updatPhoto("flag_photo_path")}
                >
                  {data.flag_photo_path && <span onClick={() => delPhoto("flag_photo_path")} className="abs-delt"><svg data-src={X}></svg></span>}

                  {data.flag_photo_path && data.flag_photo_path.length > 0 ? (
                    <svg
                      data-src={FILE_SERVER + data.flag_photo_path}
                      width="76"
                      height="50"
                    ></svg>
                  ) : (
                    <img alt="" className="language-flat country-flag" src={flag} />
                  )}
                </div>
              ) : (
                <div className="modal-icon mar flag">
                  <LoadingImage />
                </div>
              )}
              {!(img_upload.status && img_upload.type === "map_photo_path") ? (
                <div
                  className="modal-icon mar map"
                  onDragLeave={(e) => (e.target.style.transform = "scale(1)")}
                  onDragEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                  onDropCapture={(e) => { }}
                  dropzone
                  onDrop={(e) => {
                    onDropImage(e, "map_photo_path");
                  }}
                  onDragOver={(e) => onDragOver(e)}
                  onClick={() => updatPhoto("map_photo_path")}
                >
                  {data.map_photo_path && <span onClick={() => delPhoto("map_photo_path")} className="abs-delt"><svg data-src={X}></svg></span>}

                  {data.map_photo_path && data.map_photo_path.length > 0 ? (
                    <img alt=""
                      src={FILE_SERVER + data.map_photo_path}
                      width="120"
                      height="50"
                    />
                  ) : (
                    <img alt="" className="language-flat country-map" src={map} />
                  )}
                </div>
              ) : (
                <div className="modal-icon mar map">
                  <LoadingImage />
                </div>
              )}
            </div>
          </div>
        </>
      );
    else
      return (
        <>
          <div className="modal-icon-section">
            <div className="modal-icon-name">
              {showModal()} {trans("logo_photo_map")}
            </div>
            <div className="modal-icons">
              {!(
                img_upload.status && img_upload.type === "outline_photo_path_1"
              ) ? (
                <div
                  className="modal-icon cityflag"
                  onDragLeave={(e) => (e.target.style.transform = "scale(1)")}
                  onDragEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                  onDropCapture={(e) => { }}
                  dropzone
                  onDrop={(e) => {
                    onDropImage(e, "outline_photo_path_1");
                  }}
                  onDragOver={(e) => onDragOver(e)}
                  onClick={() => updatPhoto("outline_photo_path_1")}
                >
                  {data.outline_photo_path_1 && <span onClick={() => delPhoto("outline_photo_path_1")} className="abs-delt"><svg data-src={X}></svg></span>}

                  {data.outline_photo_path_1 &&
                    data.outline_photo_path_1.length > 0 ? (
                    <svg
                      data-src={FILE_SERVER + data.outline_photo_path_1}
                      width="70"
                      height="50"
                    ></svg>
                  ) : (
                    <img alt=""
                      
                      style={{
                        marginLeft: "5px",
                        width: "70px",
                        height: "50px",
                      }}
                      src={flatCity}
                    />
                  )}
                </div>
              ) : (
                <div className="modal-icon cityflag">
                  <LoadingImage />
                </div>
              )}
              {!(
                img_upload.status && img_upload.type === "outline_photo_path_2"
              ) ? (
                <div
                  className="modal-icon mar cityflag"
                  onDragLeave={(e) => (e.target.style.transform = "scale(1)")}
                  onDragEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                  onDropCapture={(e) => { }}
                  dropzone
                  onDrop={(e) => {
                    onDropImage(e, "outline_photo_path_2");
                  }}
                  onDragOver={(e) => onDragOver(e)}
                  onClick={() => updatPhoto("outline_photo_path_2")}
                >
                  {data.outline_photo_path_2 && <span onClick={() => delPhoto("outline_photo_path_2")} className="abs-delt"><svg data-src={X}></svg></span>}

                  {data.outline_photo_path_2 &&
                    data.outline_photo_path_2.length > 0 ? (
                    <svg
                      data-src={FILE_SERVER + data.outline_photo_path_2}
                      width="70"
                      height="50"
                    ></svg>
                  ) : (
                    <img alt=""
                      
                      style={{
                        marginLeft: "5px",
                        width: "70px",
                        height: "50px",
                      }}
                      src={outlineCity}
                    />
                  )}
                </div>
              ) : (
                <div className="modal-icon mar cityflag">
                  <LoadingImage />
                </div>
              )}
              {!(img_upload.status && img_upload.type === "flag_photo_path") ? (
                <div
                  className="modal-icon mar cityflag"
                  onDragLeave={(e) => (e.target.style.transform = "scale(1)")}
                  onDragEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                  onDropCapture={(e) => { }}
                  dropzone
                  onDrop={(e) => {
                    onDropImage(e, "flag_photo_path");
                  }}
                  onDragOver={(e) => onDragOver(e)}
                  onClick={() => updatPhoto("flag_photo_path")}
                >
                  {data.flag_photo_path && <span onClick={() => delPhoto("flag_photo_path")} className="abs-delt"><svg data-src={X}></svg></span>}

                  {data.flag_photo_path && data.flag_photo_path.length > 0 ? (
                    <svg
                      data-src={FILE_SERVER + data.flag_photo_path}
                      width="70"
                      height="50"
                    ></svg>
                  ) : (
                    <img alt=""
                      style={{ width: "70px", height: "50px" }}
                      className="language-flat"
                      src={flagCity}
                    />
                  )}
                </div>
              ) : (
                <div className="modal-icon mar cityflag">
                  <LoadingImage />
                </div>
              )}
              {!(img_upload.status && img_upload.type === "map_photo_path") ? (
                <div
                  className="modal-icon mar photo"
                  style={{ width: "135px" }}
                  onDragLeave={(e) => (e.target.style.transform = "scale(1)")}
                  onDragEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                  onDropCapture={(e) => { }}
                  dropzone
                  onDrop={(e) => {
                    onDropImage(e, "map_photo_path");
                  }}
                  onDragOver={(e) => onDragOver(e)}
                  onClick={() => updatPhoto("map_photo_path")}
                >
                  {data.map_photo_path && <span onClick={() => delPhoto("map_photo_path")} className="abs-delt"><svg data-src={X}></svg></span>}

                  {data.map_photo_path && data.map_photo_path.length > 0 ? (
                    <img alt=""
                      src={FILE_SERVER + data.map_photo_path}
                      width="135"
                      height="50"
                    />
                  ) : (
                    <img alt=""
                      style={{ width: "135px", height: "50px" }}
                      className="language-flat"
                      src={photoCity}
                    />
                  )}
                </div>
              ) : (
                <div className="modal-icon mar photo">
                  <LoadingImage />
                </div>
              )}
            </div>
          </div>
        </>
      );
  };
  const showInputs = () => {
    if (
      props.languageFollower.length === 1 ||
      (props.languageFollower.length === 2 && mode !== "add")
    ) {
      return (
        <>
          <div className="modal-input-section">
            {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

            <div className="modal-icon-name">{showModal()} {trans("cod_name")}</div>
            <div className="input-section">
              <div className="input-holder">
                <div className="short-input-element" style={{ width: "37px" }}>
                  <input
                    readOnly={mode === "read"}
                    onChange={(e) => {
                      if (e.target.value.length <= 3) {
                        dispatch({
                          type: "NEW-REG",
                          payload: {
                            ...data,
                            region_translations: [
                              ...data.region_translations.filter(
                                (w) => w.language_code !== "en"
                              ),
                              {
                                region_name: data.region_translations.filter(
                                  (ed) => ed.language_code === "en"
                                )[0].region_name,
                                region_description: e.target.value,
                                language_code: "en",
                              },
                            ],
                          },
                        });
                      }
                    }}
                    value={
                      data.region_translations.filter(
                        (sd) => sd.language_code === "en"
                      )[0].region_description
                    }
                    type="text"
                  />
                  <img alt=""
                    onClick={() =>
                      dispatch({
                        type: "NEW-REG",
                        payload: {
                          ...data,
                          region_translations: [
                            ...data.region_translations.filter(
                              (w) => w.language_code !== "en"
                            ),
                            {
                              region_name: data.region_translations.filter(
                                (ed) => ed.language_code === "en"
                              )[0].region_name,
                              region_description: "",
                              language_code: "en",
                            },
                          ],
                        },
                      })
                    }
                    
                    src={X}
                  />
                </div>
                <div
                  className="full-input-element pa"
                  style={{ paddingLeft: "10px" }}
                >
                  <InputText
                    disabled={mode === "read"}
                    code={
                      data.region_translations.filter(
                        (t) => t.language_code === "en"
                      )[0].language_code
                    }
                    pad={true}
                    clear={() => {
                      ChangeTrans(
                        data.region_translations.filter(
                          (t) => t.language_code === "en"
                        )[0].language_code,
                        ""
                      );
                    }}
                    lang={
                      data.region_translations.filter(
                        (t) => t.language_code === "en"
                      )[0].region_name
                    }
                    setLang={(e) => {
                      ChangeTrans(
                        data.region_translations.filter(
                          (t) => t.language_code === "en"
                        )[0].language_code,
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="trans-input-holder">
                {data.region_translations.forEach((t, index) => {
                  return (
                    <>
                      {t.language_code !== "en" && (
                        <div
                          className="trans-input-element"
                          style={{ width: "109px" }}
                        >
                          <InputText
                            disabled={mode === "read"}
                            code={t.language_code}
                            pad={true}
                            clear={() => {
                              ChangeTrans(t.language_code, "");
                            }}
                            lang={t.region_name}
                            setLang={(e) => {
                              ChangeTrans(t.language_code, e.target.value);
                            }}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="modal-input-section">
            <div className="modal-icon-name sec-languages">{trans("languages")}</div>
            <div className="input-section row-lay">
              {languages.forEach((a) => (
                <div
                  onClick={() => changeLangs(a)}
                  className={
                    "lang-icon-country " +
                    `${data.languages.filter(
                      (ad) => ad.language_code === a.language_code
                    ).length > 0 && "active"
                    } ${data.languages.filter((ad) => ad.is_default === 1)
                      .length > 0 &&
                    data.languages.filter((ad) => ad.is_default === 1)[0]
                      .language_code === a.language_code &&
                    " Active-langs"
                    }`
                  }
                >
                  {data.languages.filter(
                    (ad) => ad.language_code === a.language_code
                  ).length > 0 &&
                    data.languages.filter(
                      (ad) => ad.language_code === a.language_code
                    )[0].is_default === 1 && (
                      <svg
                        className="point"
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="5"
                        viewBox="0 0 5 5"
                      >
                        <circle
                          id="Ellipse_325"
                          data-name="Ellipse 325"
                          cx="2.5"
                          cy="2.5"
                          r="2.5"
                          fill="#5d5d5d"
                        />
                      </svg>
                    )}
                  <SmallIcon icon={a.language_code.toUpperCase()} />
                </div>
              ))}
              {data.languages.filter((as) => as.is_default === 1).length >
                0 && (
                  <div className="official-det">
                    {
                      languages
                        .filter(
                          (la) =>
                            la.language_code ===
                            data.languages.filter((as) => as.is_default === 1)[0]
                              .language_code
                        )[0]
                        .translations.filter((a) => a.language_code === "en")[0]
                        .name
                    }{" "}
                    Official
                  </div>
                )}
            </div>
          </div>
          <div className="modal-input-section">
            <div className="modal-icon-name sec-languages">{trans("currency")}</div>
            <div className="input-section row-lay">
              {curr.forEach((a) => (
                <div
                  onClick={() => changeCurrency(a)}
                  className={
                    "lang-icon-country currency-sq " +
                    `${data.currencies.filter((ad) => ad.currency_id === a.id)
                      .length > 0 && "active"
                    } ${data.currencies.filter((ad) => ad.is_default === 1)
                      .length > 0 &&
                    data.currencies.filter((ad) => ad.is_default === 1)[0]
                      .currency_id === a.id &&
                    " Active-langs"
                    }`
                  }
                >
                  {data.currencies.filter((ad) => ad.currency_id === a.id)
                    .length > 0 &&
                    data.currencies.filter((ad) => ad.currency_id === a.id)[0]
                      .is_default === 1 && (
                      <svg
                        className="point"
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="5"
                        viewBox="0 0 5 5"
                      >
                        <circle
                          id="Ellipse_325"
                          data-name="Ellipse 325"
                          cx="2.5"
                          cy="2.5"
                          r="2.5"
                          fill="#5d5d5d"
                        />
                      </svg>
                    )}
                  <svg
                    data-src={FILE_SERVER + a.code}
                    width="11"
                    height={"11"}
                  ></svg>
                </div>
              ))}
              {data.currencies.filter((as) => as.is_default === 1).length >
                0 && (
                  <div className="official-det">
                    {
                      curr
                        .filter(
                          (sd) =>
                            sd.id ===
                            data.currencies.filter((c) => c.is_default === 1)[0]
                              .currency_id
                        )[0]
                        .translations.filter((a) => a.language_code === "en")[0]
                        .name
                    }{" "}
                    Official
                  </div>
                )}
            </div>
          </div>
          {/* <div className='modal-input-section'>
        <div className='modal-icon-name sec-curr'>Currency</div>
        <div className='input-section row-lay'>
          {curr.forEach((a)=>(
            <div onClick={()=>changeCurrency(a)} className={'lang-icon-country currency-sq '+`${data.currencies.filter((ad)=>ad.id===a.id).length>0&&"active"}`}>
            
            </div>
          ))}
        
          </div>
  </div> */}
          <div className="modal-input-section input-section num-section">
            <div className="modal-input-head ">
              <div className="modal-icon-name"> {trans("ph_cod")}</div>
              <div className="trans-input-holder">
                <div className="trans-input-element" style={{ width: "71px" }}>
                  <InputText
                    noFloat={"true"}

                    disabled={mode === "read"}
                    code={false}
                    pad={false}
                    clear={() => {
                      dispatch({
                        type: "NEW-REG",
                        payload: { ...data, phone_code: "" },
                      });
                    }}
                    lang={data.phone_code}
                    setLang={(e) => {
                      dispatch({
                        type: "NEW-REG",
                        payload: { ...data, phone_code: e.target.value },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-input-head">
              <div className="modal-icon-name">{trans("post_cod")}</div>
              <div className="trans-input-holder">
                <div className="trans-input-element" style={{ width: "71px" }}>
                  <InputText
                    noFloat={"true"}
                    disabled={mode === "read"}
                    code={false}
                    pad={false}
                    clear={() => {
                      dispatch({
                        type: "NEW-REG",
                        payload: { ...data, post_code: "" },
                      });
                    }}
                    lang={data.post_code}
                    setLang={(e) => {
                      dispatch({
                        type: "NEW-REG",
                        payload: { ...data, post_code: e.target.value },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-input-head">
              <div className="modal-icon-name">{trans("gmt")}</div>
              <div className="trans-input-holder">
                <div className="trans-input-element" style={{ width: "71px" }}>
                  <InputText
                    gmt={true}
                    noFloat={"true"}
                    disabled={mode === "read"}
                    code={false}
                    pad={false}
                    clear={() => {
                      dispatch({
                        type: "NEW-REG",
                        payload: { ...data, gmt_difference: "" },
                      });
                    }}
                    lang={getPhone(data.gmt_difference)}
                    setLang={(e) => {
                      dispatch({
                        type: "NEW-REG",
                        payload: { ...data, gmt_difference: e.target.value },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="modal-input-section">
            {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

            <div className="modal-icon-name">
              {showModal()} {trans("sh_nam_nam")}
            </div>
            <div className="input-section">
              <div className="input-holder">
                <div className="short-input-element" style={{ width: "37px" }}>
                  <input
                    readOnly={mode === "read"}
                    onChange={(e) => {
                      if (e.target.value.length <= 3) {
                        dispatch({
                          type: "NEW-REG",
                          payload: {
                            ...data,
                            region_translations: [
                              ...data.region_translations.filter(
                                (w) => w.language_code !== "en"
                              ),
                              {
                                region_name: data.region_translations.filter(
                                  (ed) => ed.language_code === "en"
                                )[0].region_name,
                                region_description: e.target.value,
                                language_code: "en",
                              },
                            ],
                          },
                        });
                      }
                    }}
                    value={
                      data.region_translations.filter(
                        (sd) => sd.language_code === "en"
                      )[0].region_description
                    }
                    type="text"
                  />
                  <img alt=""
                    onClick={() =>
                      dispatch({
                        type: "NEW-REG",
                        payload: {
                          ...data,
                          region_translations: [
                            ...data.region_translations.filter(
                              (w) => w.language_code !== "en"
                            ),
                            {
                              region_name: data.region_translations.filter(
                                (ed) => ed.language_code === "en"
                              )[0].region_name,
                              region_description: "",
                              language_code: "en",
                            },
                          ],
                        },
                      })
                    }
                    
                    src={X}
                  />
                </div>
                <div
                  className="full-input-element pa"
                  style={{ paddingLeft: "10px" }}
                >
                  <InputText
                    disabled={mode === "read"}
                    code={
                      data.region_translations.filter(
                        (t) => t.language_code === "en"
                      )[0].language_code
                    }
                    pad={true}
                    clear={() => {
                      ChangeTrans(
                        data.region_translations.filter(
                          (t) => t.language_code === "en"
                        )[0].language_code,
                        ""
                      );
                    }}
                    lang={
                      data.region_translations.filter(
                        (t) => t.language_code === "en"
                      )[0].region_name
                    }
                    setLang={(e) => {
                      ChangeTrans(
                        data.region_translations.filter(
                          (t) => t.language_code === "en"
                        )[0].language_code,
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="trans-input-holder">
                {data.region_translations.forEach((t, index) => {
                  return (
                    <>
                      {t.language_code !== "en" && (
                        <div
                          className="trans-input-element"
                          style={{ width: "109px" }}
                        >
                          <InputText
                            disabled={mode === "read"}
                            code={t.language_code}
                            pad={true}
                            clear={() => {
                              ChangeTrans(t.language_code, "");
                            }}
                            lang={t.region_name}
                            setLang={(e) => {
                              ChangeTrans(t.language_code, e.target.value);
                            }}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="modal-input-section input-section num-section">
            <div className="modal-input-head ">
              <div className="modal-icon-name">{showModal()} {trans("ph_cod")}</div>
              <div className="trans-input-holder">
                <div className="trans-input-element" style={{ width: "71px" }}>
                  <InputText
                    noFloat={"true"}

                    disabled={mode === "read"}
                    code={false}
                    pad={false}
                    clear={() => {
                      dispatch({
                        type: "NEW-REG",
                        payload: { ...data, phone_code: "" },
                      });
                    }}
                    lang={data.phone_code}
                    setLang={(e) => {
                      dispatch({
                        type: "NEW-REG",
                        payload: { ...data, phone_code: e.target.value },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-input-head">
              <div className="modal-icon-name">{showModal()} {trans("post_cod")}</div>
              <div className="trans-input-holder">
                <div className="trans-input-element" style={{ width: "71px" }}>
                  <InputText
                    noFloat={"true"}

                    disabled={mode === "read"}
                    code={false}
                    pad={false}
                    clear={() => {
                      dispatch({
                        type: "NEW-REG",
                        payload: { ...data, post_code: "" },
                      });
                    }}
                    lang={data.post_code}
                    setLang={(e) => {
                      dispatch({
                        type: "NEW-REG",
                        payload: { ...data, post_code: e.target.value },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-input-head">
              <div className="modal-icon-name">{showModal()} {trans("code")}</div>
              <div className="trans-input-holder">
                <div className="trans-input-element" style={{ width: "71px" }}>
                  <InputText
                    noFloat={"true"}

                    disabled={mode === "read"}
                    code={false}
                    pad={false}
                    clear={() => {
                      dispatch({
                        type: "NEW-REG",
                        payload: { ...data, office_post_code: "" },
                      });
                    }}
                    lang={data.office_post_code}
                    setLang={(e) => {
                      dispatch({
                        type: "NEW-REG",
                        payload: { ...data, office_post_code: e.target.value },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  const {
    open,
    setOpen,
    setExpand,
  } = props;
  const dispatch = useDispatch();
  const ChangeTrans = (lang_code, value) => {
    let region_translations = data.region_translations;
    let arr = [];
    region_translations.forEach((d) => {
      if (d.language_code === lang_code) arr.push({ ...d, region_name: value });
      else arr.push({ ...d });
    });
    dispatch({
      type: "NEW-REG",
      payload: { ...data, region_translations: [...arr] },
    });
  };

  const ButtonValidate = () => {
    let bool,
      inp_bool = false;

    if (
      (props.languageFollower.length === 2 && mode !== "add") ||
      props.languageFollower.length === 1
    ) {
      if (
        data?.languages?.length > 0 &&
        data?.currencies?.length > 0 &&
        data?.gmt_difference.length > 0
      ) {
        inp_bool = true;
      } else {
        inp_bool = false;
      }
    } else {
      if (
        data.office_post_code &&
        data.office_post_code.length > 0 &&
        data.phone_code.length > 0 &&
        data.post_code.length > 0
      ) {
        inp_bool = true;
      } else {
        inp_bool = false;
      }
    }
    if (
      data.region_translations.filter((e) => e.language_code === "en")[0]
        .region_description &&
      data.region_translations.filter((e) => e.language_code === "en")[0]
        .region_description.length > 0
    )
      bool = true;
    else bool = false;
    data.region_translations.forEach((s) => {
      if (s.region_name.length === 0) bool = false;
    });
    return bool && inp_bool;
  };
  const showModalLevel = () => {
    switch (props.level) {
      case 1: {
        return trans("city");
      }
      case 2: {
        return trans("region");
      }
      case 3: {
        return trans("district");
      }
      case 4: {
        return trans("street");
      }
      default:
        return trans("street");
    }
  };
  const updateImage = async (file, type) => {
    dispatch({ type: "IMG-UPLOAD-REG", payload: { status: true, type: type } });

    let pat = await upload(file);
    let tmp = { ...data };
    tmp[`${type}`] = pat;
    dispatch({ type: "NEW-REG", payload: { ...tmp } });
    dispatch({
      type: "IMG-UPLOAD-REG",
      payload: { status: false, type: null },
    });
    dispatch({ type: "LEVEL-DONE-REG" });
  };
  const delPhoto = (typ) => {
    let tmp = data
    tmp[`${typ}`] = null
    dispatch({ type: "NEW-REG", payload: { ...tmp } })
  }
  const updatPhoto = (name) => {
    if (mode !== "read") {
      let Image = document.createElement("input");
      Image.onchange = (e) => {
        updateImage(e.target.files[0], name);
      };
      Image.type = "file";
      Image.hidden = true;
      Image.accept = "image/svg+xml";
      Image.style = { position: "absolute", opacity: "0" };
      let i = document.body.appendChild(Image);
      i.click();
    }
  };
  const onDropImage = (file, type) => {
    if (mode !== "read") {
      file.preventDefault();
      if (
        file.dataTransfer.files.length === 1 &&
        file.dataTransfer.files[0].type === "image/svg+xml"
      ) {
        updateImage(file.dataTransfer.files[0], type);
      } else {
        toast.error(trans("invalid_svg"));
      }
    }
  };
  const onDragOver = (e) => {
    if (mode !== "read") e.preventDefault();
  };
  const sendRequest = () => {
    if (ButtonValidate()) {
      if (mode === "add") {
        dispatch({ type: "ADD-REG", payload: data });
      } else {
        dispatch({ type: "UPD-REG", payload: data });
      }
    } else {
      document.querySelectorAll(".modal-input-section input").forEach((inp) => {
        if (inp.value.length < 2) {
          inp.parentElement.classList.add("shake-modal");
          inp.classList.add("red-bord");
          setTimeout(() => {
            inp.parentElement.classList.remove("shake-modal");
          }, 400);
          setTimeout(() => {
            inp.classList.remove("red-bord");
          }, 1000);
        }
      });
      if (data.languages && data.languages.length === 0) {
        document.querySelectorAll(".sec-languages").forEach((inp) => {
          inp.parentElement.classList.add("shake-modal");
          inp.classList.add("red-cont");
          setTimeout(() => {
            inp.parentElement.classList.remove("shake-modal");
          }, 400);
          setTimeout(() => {
            inp.classList.remove("red-cont");
          }, 1000);
        });
      }
      if (data.currencies && data.currencies.length === 0) {
        document.querySelectorAll(".sec-curr").forEach((inp) => {
          inp.parentElement.classList.add("shake-modal");
          inp.classList.add("red-cont");
          setTimeout(() => {
            inp.parentElement.classList.remove("shake-modal");
          }, 400);
          setTimeout(() => {
            inp.classList.remove("red-cont");
          }, 1000);
        });
      }
    }
  };
  const changeLangs = (lang) => {
    if (mode !== "read") {
      if (
        data.languages.filter((a) => a.language_code === lang.language_code)
          .length > 0
      ) {
        if (
          data.languages.filter(
            (a) => a.language_code === lang.language_code
          )[0].is_default === 1
        ) {
          let arr = [];
          data.languages.forEach((s) => {
            if (s.language_code === lang.language_code);
            else arr.push(s);
          });
          dispatch({
            type: "NEW-REG",
            payload: { ...data, languages: [...arr] },
          });
        } else {
          let arr = [];
          data.languages.forEach((s) => {
            if (s.language_code === lang.language_code)
              arr.push({ ...s, is_default: 1 });
            else arr.push({ ...s, is_default: 0 });
          });
          dispatch({
            type: "NEW-REG",
            payload: { ...data, languages: [...arr] },
          });
        }
      } else {
        dispatch({
          type: "NEW-REG",
          payload: {
            ...data,
            languages: [
              ...data.languages,
              { language_code: lang.language_code, is_default: 0 },
            ],
          },
        });
      }
    }
  };
  const changeCurrency = (cur) => {
    if (mode !== "read") {
      if (data.currencies.filter((a) => a.currency_id === cur.id).length > 0) {
        if (
          data.currencies.filter((a) => a.currency_id === cur.id)[0]
            .is_default === 1
        ) {
          let arr = [];
          data.currencies.forEach((s) => {
            if (s.currency_id === cur.id);
            else arr.push(s);
          });
          dispatch({
            type: "NEW-REG",
            payload: { ...data, currencies: [...arr] },
          });
        } else {
          let arr = [];
          data.currencies.forEach((s) => {
            if (s.currency_id === cur.id) arr.push({ ...s, is_default: 1 });
            else arr.push({ ...s, is_default: 0 });
          });
          dispatch({
            type: "NEW-REG",
            payload: { ...data, currencies: [...arr] },
          });
        }
      } else {
        dispatch({
          type: "NEW-REG",
          payload: {
            ...data,
            currencies: [
              ...data.currencies,
              { currency_id: cur.id, is_default: 0 },
            ],
          },
        });
      }
    }
  };
  const showModal = () => {
    switch (props.languageFollower.length) {
      case 1: {
        return trans("country");
      }
      case 2: {
        if (mode === "add") return trans("city");
        else return trans("country");
      }
      case 3: {
        if (mode === "add") return trans("region");
        else return trans("city");
      }
      case 4: {
        if (mode === "add") return trans("district");
        else return trans("region");
      }
      case 5: {
        if (mode === "add") return trans("street");
        else return trans("district");
      }
      case 6: {
        return trans("street");
      }
      default:
        return trans("street");
    }
  };

  return (
    <>
      {mode !== "read" && (
        <div
          onClick={(e) => shake(e)}
          className={`lang-modalDisable ${open && "open"}`}
        ></div>
      )}
      <div
        style={{ width: "428px", height: "auto", minHeight: "435px", minWidth: "428px" }}
        className={`lang-modal ${open && "open"} ${props.fixModal}`}
      >
        <div className="modal-top">
          <div className="modal-top-title">
            {mode === "add" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <defs>
                  <clipPath id="clip-path">
                    <rect
                      id="Rectangle_4527"
                      data-name="Rectangle 4527"
                      width="15"
                      height="15"
                      transform="translate(324 333)"
                      fill="none"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Mask_Group_16"
                  data-name="Mask Group 16"
                  transform="translate(-324 -333)"
                  clipPath="url(#clip-path)"
                >
                  <path
                    id="add-3"
                    d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z"
                    transform="translate(324 333)"
                    fill="#404040"
                  />
                </g>
              </svg>
            ) : props.languageFollower.length === 2 ? (
              <>
                {data.flag_photo_path ? (
                  <svg
                    className="no-fill"
                    style={{ fill: "none" }}
                    data-src={data.flag_photo_path}
                    width="23"
                    height="15"
                  ></svg>
                ) : (
                  <svg
                    className="emp-dept"
                    data-src={Empty}
                    width="15"
                    height="15"
                  ></svg>
                )}
              </>
            ) : (
              <>
                {data.flat_photo_path ? (
                  <svg
                    className="emp-dept"
                    data-src={data.flat_photo_path}
                    width="15"
                    height="15"
                  ></svg>
                ) : (
                  <svg
                    className="emp-dept"
                    data-src={Empty}
                    width="23"
                    height="15"
                  ></svg>
                )}
              </>
            )}
            {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

            {mode === "add" ? (
              <div className="modal-title">{trans("add_new")} {showModal()}</div>
            ) : (
              <div className="modal-title">
                {mode === "update" ? trans("update") : ""}{" "}
                {`${modalData.name}`} {showModal()}
              </div>
            )}
          </div>
          {mode !== "read" ? (
            <div className="modal-top-buttons">
              <div
                className={`add-button ${!ButtonValidate() && "disabled-button"
                  }`}
                onClick={() => {
                  sendRequest();
                  if (ButtonValidate()) {
                    props.Close();
                  }
                }}
              >
                {mode === "add" ? trans("add") : trans("update")}
              </div>
              <div
                className="close-button"
                onClick={() => {
                  setOpen(false);
                  props.Close();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.9"
                  height="9.899"
                  viewBox="0 0 9.9 9.899"
                >
                  <g
                    id="Group_4637"
                    data-name="Group 4637"
                    transform="translate(-224.153 -513.36) rotate(45)"
                  >
                    <line
                      id="Line_962"
                      data-name="Line 962"
                      x1="12"
                      transform="translate(522.5 204.5)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                    <line
                      id="Line_963"
                      data-name="Line 963"
                      x1="12"
                      transform="translate(528.5 198.5) rotate(90)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </div>
            </div>
          ) : (
            <div className="modal-top-buttons">
              <div
                className="icon-lang marg"
                onClick={() => {
                  props.Add(data);
                  props.ActiveAdd();
                  setExpand(true);
                }}
              >
                <div className="icon-lang-desc">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <filter
                        id="Ellipse_319"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.2" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#Ellipse_319)"
                    >
                      <circle
                        id="Ellipse_319-2"
                        data-name="Ellipse 319"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(9 6)"
                        fill="#404040"
                      />
                    </g>
                  </svg>
                  <div
                    className="icon-lang-desc-bu"
                    style={{
                      whiteSpace: "nowrap",
                      width: "auto",
                      padding: "7px",
                      left: "-27px",
                    }}
                  >
                    {trans("add_new")} {showModalLevel()}
                  </div>
                </div>
                <svg
                  className="gray-color"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                >
                  <g
                    id="Mask_Group_16"
                    data-name="Mask Group 16"
                    transform="translate(-324 -333)"
                    clipPath="url(#clip-path)"
                  >
                    <path
                      id="add-3"
                      d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z"
                      transform="translate(324 333)"
                      fill="#8e8e8e"
                    />
                  </g>
                </svg>
              </div>
              <div
                className="icon-lang marg"
                onClick={() => {
                  props.Update(props.data);
                }}
              >
                <div className="icon-lang-desc">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <filter
                        id="Ellipse_319"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.2" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#Ellipse_319)"
                    >
                      <circle
                        id="Ellipse_319-2"
                        data-name="Ellipse 319"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(9 6)"
                        fill="#404040"
                      />
                    </g>
                  </svg>
                  {/* ATTENTION!! ADDING CONDITION NEXT LINE */}
                  <div
                    className="icon-lang-desc-bu"
                    style={{ whiteSpace: "nowrap", left: "-24px" }}
                  >
                    {trans("edit")}
                  </div>
                </div>
                <svg
                  className="gray-color"
                  id="_15x15_photo_back"
                  data-name="15x15 photo back"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                >
                  <g data-name="Mask Group 65">
                    <g
                      id="Group_4666"
                      data-name="Group 4666"
                      transform="translate(0.063 0)"
                    >
                      <g id="user" transform="translate(0.258)">
                        <path
                          id="Path_16021"
                          data-name="Path 16021"
                          d="M13.436,24.162l1.583-.366L13.8,22.579Z"
                          transform="translate(-6.428 -9.162)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16022"
                          data-name="Path 16022"
                          d="M9.213,18.943l.262-1.136v-.047l.006-.014v-.009l.008-.014v-.008L9.5,17.7l.006-.008.011-.012h0l3.531-3.531a5.421,5.421,0,0,0-4.867-3.239A5.239,5.239,0,0,0,4.19,12.762a7.346,7.346,0,0,0-1.668,4.726A13.977,13.977,0,0,0,8.178,19a10.04,10.04,0,0,0,1.035-.055Z"
                          transform="translate(-2.521 -4.985)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16023"
                          data-name="Path 16023"
                          d="M0,0H5.927V2.156H0Z"
                          transform="translate(7.595 12.882) rotate(-45)"
                          fill="#8e8e8e"
                        />
                        <circle
                          id="Ellipse_318"
                          data-name="Ellipse 318"
                          cx="2.694"
                          cy="2.694"
                          r="2.694"
                          transform="translate(2.963)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16024"
                          data-name="Path 16024"
                          d="M23.32,15.209a1.078,1.078,0,0,0-1.84-.762L23,15.966A1.07,1.07,0,0,0,23.32,15.209Z"
                          transform="translate(-9.309 -6.137)"
                          fill="#8e8e8e"
                        />
                      </g>
                      <rect
                        id="Rectangle_4536"
                        data-name="Rectangle 4536"
                        width="14.875"
                        height="14.875"
                        transform="translate(0 0.125)"
                        fill="none"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div
                className="close-button"
                onClick={() => {
                  props.Close();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.9"
                  height="9.899"
                  viewBox="0 0 9.9 9.899"
                >
                  <g
                    id="Group_4637"
                    data-name="Group 4637"
                    transform="translate(-224.153 -513.36) rotate(45)"
                  >
                    <line
                      id="Line_962"
                      data-name="Line 962"
                      x1="12"
                      transform="translate(522.5 204.5)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                    <line
                      id="Line_963"
                      data-name="Line 963"
                      x1="12"
                      transform="translate(528.5 198.5) rotate(90)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </div>
            </div>
          )}
        </div>
        <div className="modal-body">
          {showIcons()}
          {showInputs()}
        </div>
      </div>
    </>
  );
}

export default CountriesModal;
