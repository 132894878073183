import category from "./components/Dashboard/Routes/Languages/svgs/category.svg"
import mens from "./components/Dashboard/Routes/Languages/svgs/mens.svg"
import women from "./components/Dashboard/Routes/Languages/svgs/women.svg"
import kids from "./components/Dashboard/Routes/Languages/svgs/kids.svg"
import home from "./components/Dashboard/Routes/Languages/svgs/home.svg"
import elec from "./components/Dashboard/Routes/Languages/svgs/electronics.svg"
import ArOut from "./components/Dashboard/Routes/Languages/svgs/ArOut.svg"
import EnOut from "./components/Dashboard/Routes/Languages/svgs/EnOut.svg"
import TrOut from "./components/Dashboard/Routes/Languages/svgs/TrOut.svg"
import Langs from "./components/Dashboard/Routes/Languages/svgs/Langs.svg"
import Countries from "./components/Dashboard/Routes/Languages/svgs/Countries.svg"
import HRM from './components/Dashboard/Routes/Languages/svgs/HRM.svg'
import date from "date-and-time";
import ava from './assets/svg/ava.jpg'
import store from "./redux/store"
export const dashboardOptions = [
  {
    id: 1,
    name: "Setting",
    icon: null,
    add: false,
    childreen: [
      {
        id: 2,
        name: "Categories",
        add: true,
        icon: category,
        routes: "category",
        childreen: [
          { id: 3, name: "Men", routes: "category", icon: mens, childreen: [{ id: 8, name: "Dress", icon: mens }, { id: 9, name: "T-Shert", icon: mens }] },
          { id: 4, name: "Women", routes: "category", icon: women, childreen: [{ id: 8, name: "Dress", icon: women }, { id: 9, name: "T-Shert", icon: women }] },
          { id: 5, name: "Children", routes: "category", icon: kids, childreen: [{ id: 8, name: "Dress", icon: kids }, { id: 9, name: "T-Shert", icon: kids }] },
          { id: 6, name: "Home", routes: "category", icon: home, childreen: [{ id: 8, name: "Dress", icon: home }, { id: 9, name: "T-Shert", icon: home }] },
          { id: 7, name: "Electronic", routes: "category", icon: elec, childreen: [{ id: 8, name: "Dress", icon: elec }, { id: 9, name: "T-Shert", icon: elec }] }
        ]
      }
    ]
  },
  {
    id: 2,
    name: "System",
    icon: null,
    add: null,
    childreen: [{
      id: 1, name: "Languages", routes: "languages", add: true, icon: Langs, childreen: [
        { id: 3, icon: ArOut, name: "Arabic" },
        { id: 3, icon: EnOut, name: "English" },
        { id: 3, icon: TrOut, name: "Turkish" },
      ]
    },
    { id: 2, name: "Countries", routes: "full-content", add: true, icon: Countries, childreen: [] }
    ]
  },
  {
    id: 3,
    name: "Management",
    icon: null,
    add: null,
    childreen: [{ id: 1, name: "HRM", routes: "HRM", add: true, icon: HRM, childreen: [] },

    ]
  }
]
export const langsdata = [
  { id: 1, name: "Arabic", icon: "AR", childs: [{ id: 11, name: "Syria", icon: "SY", childs: [{ id: 111, name: "Damascus", icon: "DA", childs: [] }, { id: 112, name: "Aleppo", icon: "AL", childs: [] }, { id: 113, name: "Latakia", icon: "LA", childs: [] }] }, { id: 12, name: "Iraq", icon: "IQ", childs: [] }, { id: 12, name: "Lebanon", icon: "LB", childs: [] }] },
  { id: 2, name: "Turkish", icon: "TR", childs: [] },
  { id: 3, name: "English", icon: "EN", childs: [] }
]
export const GetTransValidate = (arr) => {
  let bool = true
  arr.forEach((name) => {
    if (name.name.length === 0)
      bool = false
  })
  return bool
}
export const FILE_SERVER = process.env.REACT_APP_BASE_FILE_URL
export const showTrans = (data, lang) => {

  if (data && data.filter((a) => a.language_code === lang).length > 0) {
    return data.filter((a) => a.language_code === lang)[0].name
  }
  else {
    return "Not Entered"
  }
}
export const showTransRegions = (data, lang) => {
  if (data.filter((a) => a.language_code === lang).length > 0) {
    return data.filter((a) => a.language_code === lang)[0].region_name
  }
  else {
    return "Not Entered"
  }
}
export const getReg = (count, pa) => {

  return count.filter((a) => a.translations.filter((ad) => ad.name?.toLowerCase().includes(pa?.toLowerCase())).length > 0).slice(0, 3)
}
export const getUser = (clients, pa) => {
  return users.filter((a) => a.name?.toLowerCase().includes(pa?.toLowerCase())).slice(0, 3)
}

export const getDept = (depts, pa) => {
  return depts.filter((a) => a?.name?.toLowerCase().includes(pa?.toLowerCase())).slice(0, 3)
}
const users = [{ name: "Mohamad Katmawi", photo: ava, id: "VDC  1102222 1" }, { name: "Mohamad Ali", photo: ava, id: "VDC  11332323 3" }, { name: "Ali Mohamad", photo: ava, id: "VDC  2302222 1" }, { name: "Alaa Asaad", photo: ava, id: "VDC  11022322 1" }]
export const isNew = (ch) => {
  let a = ch?.filter((mes) => mes.sender_user_id !== JSON.parse(localStorage.getItem("user"))?.id && mes.message_status.filter((st) => st.user_id === JSON.parse(localStorage.getItem("user"))?.id)[0]?.is_watched === false).length
  return (a)
}
export const isElementXPercentInViewport = function (el, percentVisible) {
  let
    rect = el.getBoundingClientRect(),
    windowHeight = (window.innerHeight || document.documentElement.clientHeight);

  return !(
    Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
    Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
  )
};

export const forwardMessage = (m, activeChat) => {

  if ((m.body || m.media) && (!m?.message_type)) {
    if (m.type === "chat") {
      let dd = new Date();
      let dds = dd.getTime() - (3 * 60 * 60 * 1000)
      dds = new Date(dds);
      let mid = Math.random() * 1000;
      let cid = activeChat?.id;
      let objs = {
        receiver_mobile_phone: `${!activeChat?.id_from_whatsapp?.split("@")[0].includes("+") ? "+" : ""
          }${activeChat?.id_from_whatsapp?.split("@")[0]}`,
        message: m.body,
        caption: null,

        type: "chat",
        updated_at: dd.toISOString(),
        msg_id_from_whatsapp: null,
      };
      let obj = {
        body: m.body,
        ack: "server",
        fromMe: 1,
        id: "true_" + mid,
        isForwarded: 1,
        sent_at: date.format(dds, "YYYY-MM-DD HH:mm:ss"),
        timestamp: Math.floor(Date.now() / 1000),
        type: "chat",
        cid: activeChat?.id,
        mid: mid,
        chat_id: activeChat?.id,
        sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
        quotedMsg: null,
      };
      let groubObj = {
        group_contact_id: `${activeChat?.id_from_whatsapp?.split("+")[0]}`,
        message: m.body,
        caption: null,

        type: "chat",
        updated_at: dd.toISOString(),
        msg_id_from_whatsapp: null,
      };
      store.dispatch({
        type: "SEND_TEXT",
        payload: obj,
        isGroup: activeChat.isGroup === 1,
        pay: activeChat.isGroup === 1 ? groubObj : objs,
        mid: mid,
        cid: cid,
      });
      store.dispatch({
        type: "SEND_TEXT_SAGA_FORWARD",
        msId: m.id,
        chatId: activeChat.isGroup === 1 ? activeChat?.id_from_whatsapp?.split("+")[0] : activeChat?.id_from_whatsapp?.split("+")[0],
        mid: mid,
        cid: cid,
      });
      store.dispatch({ type: "FORWARD-MESSAGE", payload: null })
    }
    if (m.type === "vcard") {
      let dd = new Date();
      let dds = dd.getTime() - (3 * 60 * 60 * 1000)
      dds = new Date(dds);
      let mid = Math.random() * 1000;
      let cid = activeChat?.id;
      let objs = {
        receiver_mobile_phone: `${!activeChat?.id_from_whatsapp?.split("@")[0].includes("+") ? "+" : ""
          }${activeChat?.id_from_whatsapp?.split("@")[0]}`,
        message: m.body,
        caption: null,

        type: "vcard",
        updated_at: dd.toISOString(),
        msg_id_from_whatsapp: null,
      };
      let obj = {
        body: m.body,
        ack: "server",
        fromMe: 1,
        id: "true_" + mid,
        isForwarded: 1,
        sent_at: date.format(dds, "YYYY-MM-DD HH:mm:ss"),
        timestamp: Math.floor(Date.now() / 1000),
        type: "vcard",
        cid: activeChat?.id,
        mid: mid,
        chat_id: activeChat?.id,
        sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
        quotedMsg: null,
      };
      let groubObj = {
        group_contact_id: `${activeChat?.id_from_whatsapp?.split("+")[0]}`,
        message: m.body,
        caption: null,

        type: "vcard",
        updated_at: dd.toISOString(),
        msg_id_from_whatsapp: null,
      };
      store.dispatch({
        type: "SEND_TEXT",
        payload: obj,
        isGroup: activeChat.isGroup === 1,
        pay: activeChat.isGroup === 1 ? groubObj : objs,
        mid: mid,
        cid: cid,
      });
      store.dispatch({
        type: "SEND_TEXT_SAGA_FORWARD",
        msId: m.id,
        chatId: activeChat.isGroup === 1 ? activeChat?.id_from_whatsapp?.split("+")[0] : activeChat?.id_from_whatsapp?.split("+")[0],
        mid: mid,
        cid: cid,
      });
      store.dispatch({ type: "FORWARD-MESSAGE", payload: null })
    }

    else if (m.type === "image" || m.type === "sticker") {
      let dd = new Date();
      let dds = dd.getTime() - (3 * 60 * 60 * 1000)
      dds = new Date(dds);
      let mid = Math.random() * 1000;
      let cid = activeChat?.id;
      store.dispatch({
        type: "SEND_TEXT",
        cid: activeChat?.id,
        mid: mid,
        payload: {
          caption: m.body,
          media: m.media,
          sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
          isForwarded: 1,
          fromMe: 1,
          ack: "server",
          loading: true,
          id: "true_" + mid,
          sent_at: date.format(dds, "YYYY-MM-DD HH:mm:ss"),
          timestamp: Math.floor(Date.now() / 1000),
          type: "image",
          chat_id: activeChat?.id,
          cid: activeChat?.id,
          mid: mid,
          quotedMsg: null,
        },
      });
      if (activeChat.isGroup === 0) {
        store.dispatch({
          type: "SEND_TEXT_SAGA_FORWARD",
          msId: m.id,
          chatId: activeChat.isGroup === 1 ? activeChat?.id_from_whatsapp?.split("+")[0] : activeChat?.id_from_whatsapp,
          mid: mid,
          cid: cid,
        });
      } else {
        store.dispatch({
          type: "SEND_TEXT_SAGA_FORWARD",
          msId: m.id,
          chatId: activeChat.isGroup === 1 ? activeChat?.id_from_whatsapp?.split("+")[0] : activeChat?.id_from_whatsapp,
          mid: mid,
          cid: cid,
        });
      }
      store.dispatch({ type: "FORWARD-MESSAGE", payload: null })
    }
    else if (m.type === "video") {
      let dd = new Date();
      let dds = dd.getTime() - (3 * 60 * 60 * 1000)
      dds = new Date(dds);
      let mid = Math.random() * 1000;
      let cid = activeChat?.id;
      store.dispatch({
        type: "SEND_TEXT",
        cid: activeChat?.id,
        mid: mid,
        payload: {
          caption: m.body,
          media: m.media,
          isForwarded: 1,
          sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
          ack: "server",
          loading: true,
          fromMe: 1,
          id: "true_" + mid,
          sent_at: date.format(dds, "YYYY-MM-DD HH:mm:ss"),
          timestamp: Math.floor(Date.now() / 1000),
          type: "video",
          chat_id: activeChat?.id,
          cid: activeChat?.id,
          mid: mid,
          quotedMsg: null,
        },
      });
      if (activeChat.isGroup === 0) {
        store.dispatch({
          type: "SEND_TEXT_SAGA_FORWARD",
          msId: m.id,
          chatId: activeChat.isGroup === 1 ? activeChat?.id_from_whatsapp?.split("+")[0] : activeChat?.id_from_whatsapp,
          mid: mid,
          cid: cid,
        });
      } else {
        store.dispatch({
          type: "SEND_TEXT_SAGA_FORWARD",
          msId: m.id,
          chatId: activeChat.isGroup === 1 ? activeChat?.id_from_whatsapp?.split("+")[0] : activeChat?.id_from_whatsapp,
          mid: mid,
          cid: cid,
        });
      }
      store.dispatch({ type: "FORWARD-MESSAGE", payload: null })
    }
    else if (m.type === "ptt" || m.type === "audio") {
      let dd = new Date();
      let dds = dd.getTime() - (3 * 60 * 60 * 1000)
      dds = new Date(dds);
      let mid = Math.random() * 1000;
      let cid = activeChat?.id;
      store.dispatch({
        type: "SEND_TEXT_SAGA_FORWARD",
        msId: m.id,
        chatId: activeChat.isGroup === 1 ? activeChat?.id_from_whatsapp?.split("+")[0] : activeChat?.id_from_whatsapp,
        mid: mid,
        cid: cid,
      });
      store.dispatch({
        type: "SEND_TEXT",
        payload: {
          caption: null,
          media: m.media,
          sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
          ack: "server",
          fromMe: 1,
          mid: mid,
          loading: false,
          isForwarded: 1,
          id: "true_" + mid,
          sent_at: date.format(dds, "YYYY-MM-DD HH:mm:ss"),
          timestamp: Math.floor(Date.now() / 1000),
          type: "audio",
          chat_id: activeChat?.id,
          quotedMsg:
            null
        },
      });
      store.dispatch({ type: "FORWARD-MESSAGE", payload: null })
    }
    else if (m.type === "document") {
      let dd = new Date();
      let dds = dd.getTime() - (3 * 60 * 60 * 1000)
      dds = new Date(dds);
      let mid = Math.random() * 1000;
      let cid = activeChat?.id;
      store.dispatch({
        type: "SEND_TEXT_SAGA_FORWARD",
        msId: m.id,
        chatId: activeChat.isGroup === 1 ? activeChat?.id_from_whatsapp?.split("+")[0] : activeChat?.id_from_whatsapp,
        mid: mid,
        cid: cid,
      });
      store.dispatch({
        type: "SEND_TEXT",
        payload: {
          caption: null,
          sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
          media: m.media,
          ack: "server",
          fromMe: 1,
          mid: mid,
          loading: false,
          isForwarded: 1,
          id: "true_" + mid,
          sent_at: date.format(dds, "YYYY-MM-DD HH:mm:ss"),
          timestamp: Math.floor(Date.now() / 1000),
          type: "document",
          chat_id: activeChat?.id,
          quotedMsg:
            null
        },
      });
      store.dispatch({ type: "FORWARD-MESSAGE", payload: null })
    }

  }
  else {
    let i = Math.random();
    // console.log(m,activeChat)
    if (m.message_type.name === "TextMessage" || m.message_type === "TextMessage") {

      store.dispatch({
        type: "SEND_MES",
        payload: {
          receiver_user_id: activeChat.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].user_id,
          receiver_role_id: activeChat.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].role_id,
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          content: m.message_content.content,
          parent_message_id: null,
          is_forward: 1,
          message_type: "TextMessage",
          mid: i,
          cid: activeChat?.id_from_whatsapp,
        },
      });
      store.dispatch({
        type: "SEND-MESSAGE",
        payload: {
          act: activeChat,
          message: {
            receiver_user_id: activeChat.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id,
            receiver_role_id: activeChat.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].role_id,
            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            sender_user_id: JSON.parse(localStorage.getItem("user")).id,
            message_type: { name: "TextMessage" },
            message_content: { content: m.message_content.content },
            created_at: new Date(),
            mid: i,
            message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
              , {
              is_received: 0, is_watched: false, user_id: activeChat.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].user_id
            }],
            type: "pending",
            is_forward: 1,
            cid: activeChat?.id_from_whatsapp,
          },
        },
      });
      store.dispatch({ type: "FORWARD-MESSAGE", payload: null })
    }
    if (m.message_type.name === "ImageMessage" || m.message_type === "ImageMessage") {
      store.dispatch({
        type: "SEND_MES",
        payload: {
          mid: i,
          cid: activeChat?.id,
          is_forward: 1,
          receiver_user_id: activeChat.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].user_id,
          receiver_role_id: activeChat.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].role_id,
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          content: [{ file_path: m.message_content[0].file_path, caption: "" }],
          parent_message_id: null,
          message_type: "ImageMessage",
        },
      });
      store.dispatch({
        type: "SEND-MESSAGE",
        payload: {
          act: activeChat,
          message: {
            mid: i,
            is_forward: 1,
            receiver_user_id: activeChat.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id,
            receiver_role_id: activeChat.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].role_id,
            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            sender_user_id: JSON.parse(localStorage.getItem("user")).id,
            message_type: { name: "ImageMessage" },
            type: "pending",
            created_at: new Date(),
            message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
              , {
              is_received: 0, is_watched: false, user_id: activeChat.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].user_id
            }],
            message_content: [{ file_path: m.message_content[0].file_path, caption: "" }],
            cid: activeChat?.id_from_whatsapp,
          },
        },
      });
      store.dispatch({ type: "FORWARD-MESSAGE", payload: null })
    }
    if (m.message_type.name === "VoiceMessage" || m.message_type === "VoiceMessage") {
      store.dispatch({
        type: "SEND_MES",
        payload: {
          mid: i,
          cid: activeChat?.id,
          is_forward: 1,
          receiver_user_id: activeChat.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].user_id,
          receiver_role_id: activeChat.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].role_id,
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          content: [{ file_path: m.message_content[0].file_path, caption: "" }],
          parent_message_id: null,
          message_type: "VoiceMessage",
        },
      });
      store.dispatch({
        type: "SEND-MESSAGE",
        payload: {
          act: activeChat,
          message: {
            mid: i,
            receiver_user_id: activeChat.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id,
            receiver_role_id: activeChat.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].role_id,
            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            sender_user_id: JSON.parse(localStorage.getItem("user")).id,
            message_type: { name: "VoiceMessage" },
            type: "pending",
            is_forward: 1,
            created_at: new Date(),
            message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
              , {
              is_received: 0, is_watched: false, user_id: activeChat.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].user_id
            }],
            message_content: [{ file_path: m.message_content[0].file_path, caption: "" }],
            cid: activeChat?.id_from_whatsapp,
          },
        },
      });
      store.dispatch({ type: "FORWARD-MESSAGE", payload: null })
    }
    if (m.message_type.name === "VideoMessage" || m.message_type === "VideoMessage") {
      store.dispatch({
        type: "SEND_MES",
        payload: {
          mid: i,
          cid: activeChat?.id,
          is_forward: 1,
          receiver_user_id: activeChat.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].user_id,
          receiver_role_id: activeChat.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].role_id,
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          content: [{ file_path: m.message_content[0].file_path, caption: "" }],
          parent_message_id: null,
          message_type: "VideoMessage",
        },
      });
      store.dispatch({
        type: "SEND-MESSAGE",
        payload: {
          act: activeChat,
          message: {
            is_forward: 1,
            mid: i,
            receiver_user_id: activeChat.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id,
            receiver_role_id: activeChat.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].role_id,
            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            sender_user_id: JSON.parse(localStorage.getItem("user")).id,
            message_type: { name: "VoiceMessage" },
            type: "pending",
            created_at: new Date(),
            message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
              , {
              is_received: 0, is_watched: false, user_id: activeChat.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].user_id
            }],
            message_content: [{ file_path: m.message_content[0].file_path, caption: "" }],
            cid: activeChat?.id_from_whatsapp,
          },
        },
      });
      store.dispatch({ type: "FORWARD-MESSAGE", payload: null })
    }
    if (m.message_type.name === "FileMessage" || m.message_type === "FileMessage") {
      store.dispatch({
        type: "SEND_MES",
        payload: {
          mid: i,
          cid: activeChat?.id,
          is_forward: 1,
          receiver_user_id: activeChat.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].user_id,
          receiver_role_id: activeChat.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].role_id,
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          content: [{ file_path: m.message_content[0].file_path, caption: "" }],
          parent_message_id: null,
          message_type: "FileMessage",
        },
      });
      store.dispatch({
        type: "SEND-MESSAGE",
        payload: {
          act: activeChat,
          message: {
            mid: i,
            receiver_user_id: activeChat.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id,
            receiver_role_id: activeChat.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].role_id,
            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            sender_user_id: JSON.parse(localStorage.getItem("user")).id,
            message_type: { name: "FileMessage" },
            type: "pending",
            is_forward: 1,
            created_at: new Date(),
            message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
              , {
              is_received: 0, is_watched: false, user_id: activeChat.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].user_id
            }],
            message_content: [{ file_path: m.message_content[0].file_path, caption: "" }],
            cid: activeChat?.id_from_whatsapp,
          },
        },
      });
      store.dispatch({ type: "FORWARD-MESSAGE", payload: null })
    }
  }
}
export const forwardToChat = (m, activeChat) => {

  let i = Math.random();
  if (m.type === "chat") {
    store.dispatch({ type: "REFS" })
    store.dispatch({
      type: "SEND_MES",
      payload: {
        receiver_user_id: activeChat?.channel_members.filter(
          (a) =>
            parseInt(a.user_id) !==
            parseInt(JSON.parse(localStorage.getItem("user")).id)
        )[0].user_id,
        receiver_role_id: activeChat?.channel_members.filter(
          (a) =>
            parseInt(a.user_id) !==
            parseInt(JSON.parse(localStorage.getItem("user")).id)
        )[0].role_id,
        sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
        content: m.body,
        parent_message_id: null,
        message_type: "TextMessage",
        mid: i,
        cid: activeChat?.id,
      },
    });
    store.dispatch({
      type: "SEND-MESSAGE",
      payload: {
        act: activeChat,
        message: {
          parent_message: null,
          parent_message_id: null,
          receiver_user_id: activeChat?.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].user_id,
          receiver_role_id: activeChat?.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].role_id,
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          sender_user_id: JSON.parse(localStorage.getItem("user")).id,
          message_type: { name: "TextMessage" },
          message_content: { content: m.body },
          created_at: new Date(),
          mid: i,
          message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
            , {
            is_received: 0, is_watched: false, user_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id
          }],
          type: "pending",
          cid: activeChat?.id,
        },
      },
    });
  }
  if (m.type === "image" || m.type === "sticker") {
    store.dispatch({
      type: "SEND_MES",
      payload: {
        receiver_user_id: activeChat?.channel_members.filter(
          (a) =>
            parseInt(a.user_id) !==
            parseInt(JSON.parse(localStorage.getItem("user")).id)
        )[0].user_id,
        receiver_role_id: activeChat?.channel_members.filter(
          (a) =>
            parseInt(a.user_id) !==
            parseInt(JSON.parse(localStorage.getItem("user")).id)
        )[0].role_id,
        mid: i,
        sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
        content: [{ file_path: m.media, caption: "" }],
        parent_message_id: null,
        message_type: "ImageMessage",
        cid: activeChat?.id,
      },
    });
    store.dispatch({
      type: "SEND-MESSAGE",
      payload: {
        act: activeChat,
        message: {
          parent_message: null,
          parent_message_id: null,
          receiver_user_id: activeChat?.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].user_id,
          receiver_role_id: activeChat?.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].role_id,
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          sender_user_id: JSON.parse(localStorage.getItem("user")).id,
          message_type: { name: "ImageMessage" },
          type: "pending",
          mid: i,
          created_at: new Date(),
          message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
            , {
            is_received: 0, is_watched: false, user_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id
          }],
          message_content: [{ file_path: m.media, caption: "" }],
          cid: activeChat?.id,
        },
      },
    });
  }
  if (m.type === "ptt" || m.type === "audio") {
    store.dispatch({
      type: "SEND_MES",
      payload: {
        mid: i,
        receiver_user_id: activeChat?.channel_members.filter(
          (a) =>
            parseInt(a.user_id) !==
            parseInt(JSON.parse(localStorage.getItem("user")).id)
        )[0].user_id,
        receiver_role_id: activeChat?.channel_members.filter(
          (a) =>
            parseInt(a.user_id) !==
            parseInt(JSON.parse(localStorage.getItem("user")).id)
        )[0].role_id,
        sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
        content: [{ file_path: m.media, caption: "" }],
        parent_message_id: null,
        message_type: "VoiceMessage",
        cid: activeChat?.id,
      },
    });
    store.dispatch({
      type: "SEND-MESSAGE",
      payload: {
        act: activeChat,
        message: {
          mid: i,
          parent_message: null,
          parent_message_id: null,
          receiver_user_id: activeChat?.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].user_id,
          receiver_role_id: activeChat?.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].role_id,
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          sender_user_id: JSON.parse(localStorage.getItem("user")).id,

          message_type: { name: "VoiceMessage" },
          type: "pending",
          created_at: new Date(),
          message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
            , {
            is_received: 0, is_watched: false, user_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id
          }],
          message_content: [{ file_path: m.media, caption: "" }],
          cid: activeChat?.id,
        },
      },
    });
  }
  if (m.type === "video") {
    store.dispatch({
      type: "SEND_MES",
      payload: {
        receiver_user_id: activeChat?.channel_members.filter(
          (a) =>
            parseInt(a.user_id) !==
            parseInt(JSON.parse(localStorage.getItem("user")).id)
        )[0].user_id,
        receiver_role_id: activeChat?.channel_members.filter(
          (a) =>
            parseInt(a.user_id) !==
            parseInt(JSON.parse(localStorage.getItem("user")).id)
        )[0].role_id,
        mid: i,
        sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
        content: [{ file_path: m.media, caption: "" }],
        parent_message_id: null,
        message_type: "VideoMessage",
        cid: activeChat?.id,
      },
    });
    store.dispatch({
      type: "SEND-MESSAGE",
      payload: {
        act: activeChat,
        message: {
          parent_message: null,
          parent_message_id: null,
          receiver_user_id: activeChat?.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].user_id,
          receiver_role_id: activeChat?.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].role_id,
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          sender_user_id: JSON.parse(localStorage.getItem("user")).id,

          message_type: { name: "VideoMessage" },
          type: "pending",
          mid: i,
          created_at: new Date(),
          message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
            , {
            is_received: 0, is_watched: false, user_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id
          }],
          message_content: [{ file_path: m.media, caption: "" }],
          cid: activeChat?.id,
        },
      },
    });
  }
  store.dispatch({ type: "OPEN_CHAT", payload: activeChat })
  store.dispatch({ type: "FORWARD-MESSAGE", payload: null })
}
// export const SendContact=(m,activeChat)=>{
//   if (m.type === "vcard") {
//     let dd = new Date();
//     let dds = dd.getTime() - (3 * 60 * 60 * 1000)
//     dds = new Date(dds);
//     let mid = Math.random() * 1000;
//     let cid = activeChat?.id;
//     let objs = {
//       receiver_mobile_phone: `${!activeChat?.id_from_whatsapp?.split("@")[0].includes("+") ? "+" : ""
//         }${activeChat?.id_from_whatsapp?.split("@")[0]}`,
//       message: m.body,
//       caption: null,

//       type: "vcard",
//       updated_at: dd.toISOString(),
//       msg_id_from_whatsapp: null,
//     };
//     let obj = {
//       body: m.body,
//       ack: "server",
//       fromMe: 1,
//       id: "true_" + mid,
//       isForwarded: 1,
//       sent_at: date.format(dds, "YYYY-MM-DD HH:mm:ss"),
//       timestamp: Math.floor(Date.now() / 1000),
//       type: "vcard",
//       cid: activeChat?.id,
//       mid: mid,
//       chat_id: activeChat?.id,
//       sender_employee_user_id:JSON.parse(localStorage.getItem("user")).id,
//       quotedMsg: null,
//     };
//     let groubObj = {
//       group_contact_id: `${activeChat?.id_from_whatsapp?.split("+")[0]}`,
//       message: m.body,
//       caption: null,

//       type: "vcard",
//       updated_at: dd.toISOString(),
//       msg_id_from_whatsapp: null,
//     };
//     store.dispatch({
//       type: "SEND_TEXT",
//       payload: obj,
//       isGroup: activeChat.isGroup === 1,
//       pay: activeChat.isGroup === 1 ? groubObj : objs,
//       mid: mid,
//       cid: cid,
//     });
//     store.dispatch({
//       type: "SEND_TEXT_SAGA_FORWARD",
//       msId: m.id,
//       chatId: activeChat.isGroup === 1 ? activeChat?.id_from_whatsapp?.split("+")[0] : activeChat?.id_from_whatsapp?.split("+")[0],
//       mid: mid,
//       cid: cid,
//     });
//     store.dispatch({ type: "FORWARD-MESSAGE", payload: null })
//   }
// }
