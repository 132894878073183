import React from 'react'
import { useSelector } from 'react-redux'
import { forwardToChat } from '../../../../../constants'
import ChatUser from './ChatUser'

function ChatUsers(props) {
const chatsArray=useSelector((state)=>state.chat.data)?.filter((s)=>s?.channel_type.slug!=="team")

  const getChats=()=>{
    return(chatsArray.filter((s)=>s.channel_type.slug!=="team"))
  }
  return (
    <div className="chat-users-container">
      {getChats().map((em,index)=>(
        <ChatUser forwardChat={()=>{
          if(props.forwarderMessage){
            forwardToChat(props.forwarderMessage,em)
          }
        }} key={index} chat={em} photo={em}/>
      ))}
    </div>
  )
}

export default ChatUsers