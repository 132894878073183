import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CountriesModal from './CountriesModal'
import "../countries.css"
import CountryItem from './CountryItem'
function OfficesList(props) {
  const dispatch = useDispatch()
  const ActiveAdd = (e) => {
    dispatch({ type: "Active-add", payload: e })
  }
  const { lang_id, setLang_id } = props
  useEffect(() => {


    if (lang_id === 0)
      Add()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang_id])
  const countries = useSelector(state => state.offices.offices)
  const languages = useSelector(state => state.langs.languages)
  const { languageFollower, makeFollower } = props
  const data = useSelector(state => state.offices.newDepartment)
  const view = useSelector(state => state.countries.view)
  const Active_Ids = useSelector(state => state.offices.Active_Ids)
  const open = useSelector(state => state.offices.open)
  const setOpen = (e) => {
    dispatch({ type: "OPEN-OFF", payload: e })
  }
  useEffect(() => {
    dispatch({ type: "GET-OFF" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {


  }, [view, languageFollower])
  const setMode = (e) => {
    dispatch({ type: "MODE-OFF", payload: e })
  }
  const setModalData = (e) => {
    dispatch({ type: "MOD-DATA-OFF", payload: { name: e.name, icon: e.icon } })
  }
  const setData = (e) => {
    dispatch({ type: "NEW-OFF", payload: { ...e } })
  }
  const Update = async (e, post) => {
    dispatch({ type: "request-running-OFF-red", paylod: null })
    setMode("update");
    let obj = { region_translations: [], map_location: "", country_id: "", post_code: "", lat: "", lng: "", location_description: "", tower: "", blok: "", floor: "", No: "", phone_number_1: "", phone_number_2: "", gsm_1: "", gsm_2: "", office_website: "", office_mail: "" }
    setData(obj);
    if (e.region_translations.length !== languages.length) {
      obj.icon = e.icon

      languages.forEach((d) => {
        if (e.region_translations.filter((asd) => asd.language_code.toLowerCase() === d.language_code.toLowerCase()).length > 0)
          obj.region_translations = [...obj.region_translations, e.region_translations.filter((asd) => asd.language_code.toLowerCase() === d.language_code.toLowerCase())[0]]
        else
          obj.region_translations = [...obj.region_translations, { region_name: "", language_code: d.language_code }]
      })
      setData({ ...e, region_translations: [...obj.region_translations], parent_post_code: post, phone_code: countries.filter((a) => parseInt(a.id) === (e.country_id ? parseInt(e.country_id) : e.id))[0].phone_code, });
      obj = { ...obj, region_translations: [...obj.region_translations], parent_post_code: post, }
    }
    else {
      setData({ ...e, phone_code: countries.filter((a) => parseInt(a.id) === (e.country_id ? parseInt(e.country_id) : e.id))[0].phone_code, parent_post_code: post });
      obj = { ...obj, parent_post_code: post }

    }

    setModalData({ name: e.region_translations.filter((a) => a.language_code === "en")[0].region_name + " ", icon: null, code: "Country" });
    setMode("update");
    setOpen(true);
  }
  const Read = async (e, post) => {
    let obj = { region_translations: [], map_location: "", country_id: "", post_code: "", lat: "", lng: "", location_description: "", tower: "", blok: "", floor: "", No: "", phone_number_1: "", phone_number_2: "", gsm_1: "", gsm_2: "", office_website: "", office_mail: "" }
    setData(obj);
    if (e.region_translations.length !== languages.length) {
      languages.forEach((d) => {
        if (e.region_translations.filter((asd) => asd.language_code.toLowerCase() === d.language_code.toLowerCase()).length > 0)
          obj.region_translations = [...obj.region_translations, e.region_translations.filter((asd) => asd.language_code.toLowerCase() === d.language_code.toLowerCase())[0]]
        else
          obj.region_translations = [...obj.region_translations, { region_name: "", language_code: d.language_code }]
      })
      setData({ ...e, region_translations: [...obj.region_translations], phone_code: countries.filter((a) => parseInt(a.id) === (e.country_id ? parseInt(e.country_id) : e.id))[0].phone_code, parent_post_code: post });
      obj = { ...obj, region_translations: [...obj.region_translations], parent_post_code: post }
    }
    else {
      setData({ ...e, parent_post_code: post, phone_code: countries.filter((a) => parseInt(a.id) === (e.country_id ? parseInt(e.country_id) : e.id))[0].phone_code, });
      obj = { ...obj, phone_code: countries.filter((a) => parseInt(a.id) === (e.country_id ? parseInt(e.country_id) : e.id))[0].phone_code, parent_post_code: post }
    }

    setModalData({ name: e.region_translations.filter((a) => a.language_code === "en")[0].region_name, icon: e.icon, code: e.region_translations.filter((a) => a.language_code === "en")[0].region_name })
    setMode("read");
    setOpen(true);
  }
  const Close = () => {
    setLang_id(null)
    dispatch({ type: "OFF-POST-REDUCER", payload: {} })
    let obj = { region_translations: [], map_location: "", country_id: "", post_code: "", lat: "", lng: "", location_description: "", tower: "", blok: "", floor: "", No: "", phone_number_1: "", phone_number_2: "", gsm_1: "", gsm_2: "", office_website: "", office_mail: "" }
    languages.forEach((a) => {
      obj = { ...obj, region_translations: [...obj.region_translations, { region_name: "", language_code: a.language_code }] }
    })
    setOpen(false);
    setData(obj);
    setModalData({ name: "", icon: null, code: "" })
  }
  const Add = async (e) => {
    setOpen(false);
    dispatch({ type: "request-running-OFF-red", paylod: null })

    let obj = { region_translations: [], region_latlongs: [{ region_lat: 39.89709437, region_long: 32.82989502 }], map_location: "", country_id: "", post_code: "", lat: "", lng: "", location_description: "", tower: "", blok: "", floor: "", No: "", phone_number_1: "", phone_number_2: "", gsm_1: "", gsm_2: "", office_website: "", office_mail: "" }
    setData(obj);

    languages.forEach((a) => {
      obj = { ...obj, region_translations: [...obj.region_translations, { region_name: "", language_code: a.language_code }] }
    })
    if (e)
      obj = { ...obj, country_id: e.country_id ? e.country_id : e.id, parent_region_id: e.id, is_office: 1, parent_post_code: e.post_code, office_post_code: "", phone_code: countries.filter((a) => parseInt(a.id) === (e.country_id ? parseInt(e.country_id) : e.id))[0].phone_code }
    else
      obj = { ...obj, is_office: 1, office_post_code: "", }
    setData(obj)
    setMode("add")
    if (e) {


      setData({ ...obj, phone_code: countries.filter((a) => parseInt(a.id) === (e.country_id ? parseInt(e.country_id) : e.id))[0].phone_code, region_latlongs: [{ region_lat: "34.333", region_long: "34.344" }] })



    }

    setTimeout(() => {
      setOpen(true);
    }, 100);
  }

  return (
    <>

      {(lang_id === 0 || lang_id === null) && open && <CountriesModal setOpen={(a) => setOpen(a)} open={open} Add={(e) => Add(e)} Close={() => Close()} Active_Ids={Active_Ids} ActiveAdd={() => ActiveAdd(data.id)} languageFollower={props.languageFollower} setExpand={(e) => { }} data={data} {...props} />}
      <div className='lang-body countries'>
        {
          countries.forEach((dept) => (
            <CountryItem
              lang={props.lang}
              ActiveAdd={(e) => ActiveAdd(e)}
              open={open}
              Close={() => Close()}
              Add={(e) => Add(e)}
              Update={(e, p) => Update(e, p)}
              Read={(e, p) => Read(e, p)}
              setModalData={(e) => setModalData(e)}
              setOpen={(e) => setOpen(e)}
              child={dept}
              Active_Ids={Active_Ids}
              languageFollower={props.languageFollower}
              level={1}
              makeFollower={(level, value, id) => makeFollower(level, value, id)}
              lang_id={lang_id}
              setLang_id={(e) => setLang_id(e)}
            />
          ))
        }

      </div>
    </>
  )
}

export default OfficesList