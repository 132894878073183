import React from 'react'
import X from "./xicon.svg"
import FloatingCharacter from './floatingCharacter'
function InputText(props) {
  const { lang, setLang } = props
  return (
    <>
      {props.code &&
        <svg className='lang-input-icon' xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14">
          <text id="AR" transform="translate(0 11)" fill="#CECDCD" fontSize="10" fontFamily="SegoeUI, Segoe UI" letterSpacing="0.025em"><tspan x="0" y="0">{props.code.toUpperCase()}</tspan></text>
        </svg>}
      <input onClick={(e) => { props.onFocus ? props.onFocus(e) : console.log() }} onFocus={(e) => { props.onFocus ? props.onFocus(e) : console.log(); }} id={props.id} placeholder={props.lang && props.lang.length === 0 ? props.placeholder : ""} className={`${props.pad && "pad20"}`} style={props.pad ? { paddingLeft: "20px" } : { paddingLeft: "0px" }} readOnly={props.disabled} onChange={(e) => { setLang(e) }} value={lang} inputMode={props.numeric ? props.numeric[0] : ""} type={props.numeric ? props.numeric[0] : "text"} /><img onClick={() => props.clear()} alt="close" src={X} />
      {!props.noFloat && <FloatingCharacter value={-(30 - props.lang && lang.length)} />}
    </>
  )
}

export default InputText