// ** React Imports
/* ************************************************ */
/* ************************************************ */
import store from './redux/store'
/* ************************************************ */

export const trans = (id, values = {}) => {
  return ((store.getState().translationsReducer[_getAppLang()] && store.getState().translationsReducer[_getAppLang()][id]) ?? id)
}

//************************************//
export const _changeAppLang = (lang) => {
  localStorage.setItem('APP_LANG', lang)
  // window.location.reload()
}

// ** Create Context
// const Context = createContext()
//************************************//
export const _getAppLang = () => {
  return localStorage.getItem('APP_LANG') ?? 'en'
}
// const IntlProviderWrapper = ({ children, additionalLangs }) => {
  
//   // ** States
//   const localStorageLang = localStorage.getItem('APP_LANG')
//   const lang = localStorageLang ? localStorageLang : 'en'
//   const [locale, setLocale] = useState(lang)
//   const [messages, setMessages] = useState(flatten(additionalLangs[lang]))

//   let localStorageMessages = localStorage.getItem('translations')
//   useEffect(() => {
//     setMessages(localStorageMessages)
//   }, [localStorageMessages])

//   // ** Switches Language
//   const switchLanguage = lang => {
//     _changeAppLang(lang)
//     setLocale(lang)
//   }

//   // ** Switches Language
//   const plainText = key => {
//     return _.get(additionalLangs, `${locale}.${key}`) ?? key
//   }
//   // 
//   // 
//   // 
//   return (
//     <Context.Provider value={{ locale, switchLanguage, plainText }}>
//       <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='en'>
//         {children}
//       </IntlProvider>
//     </Context.Provider>
//   )
// }

// export { IntlProviderWrapper, Context as IntlContext }
