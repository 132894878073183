import React, { useEffect, useState } from 'react'
import Curre from "../Languages/svgs/Langs.svg"
import { useSelector, useDispatch } from "react-redux"
import FollowerIcon from '../Languages/svgs/FollowerIcon'
import LangBody from './LangBody'
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.min.css"
import Search from '../../../../assets/svg/search'
import Translated from '../../../../assets/svg/Translated'
import Sort from '../../../../assets/svg/Sort'
import Filter from '../../../../assets/svg/Filter'
import { trans } from '../../../../Internationalization'
import Application from '../../../../assets/svg/Application'

function LinkedLanguages(props) {
  const [languageFollower, setLanguageFollower] = useState([])
  const [lang_id, setLang_id] = useState(null)
  const dispatch = useDispatch()
  const [lang, setLang] = useState(localStorage.getItem("APP_LANG"))
  const translated_lang = useSelector((state) => state.auth.lang)
  const getLang = () => {
    if (lang) {
      return (lang)
    }
    else {
      return translated_lang
    }
  }
  useEffect(() => {

  }, [languageFollower])
  const makeFollower = (level, value, id) => {

    if (id === 0) {

      setLang_id(null)

    }
    else setLang_id(id)
    if (languageFollower.length >= level) {
      let arr = languageFollower
      arr[level] = { name: value, id: id }
      arr = arr.filter((a, index) => index <= level)
      setLanguageFollower([...arr])

    } else {
      setLanguageFollower([...languageFollower, { name: value, id: id }])
    }
  }

  const languages = useSelector(state => state.langs.languages)
  const open = useSelector(state => state.langs.open)
  const setOpen = (e) => {
    dispatch({ type: "OPEN-LANG", payload: e })
  }
  const setMode = (e) => {
    dispatch({ type: "MODE-LANG", payload: e })
  }
  const setModalData = (e) => {
    dispatch({ type: "MOD-DATA-LANG", payload: { name: e.name, icon: e.icon } })
  }
  const setData = (e) => {
    dispatch({ type: "NEW-LANG", payload: { ...e } })

  }
  const Update = (e) => {
    dispatch({ type: "request-running-red", paylod: null })
    setMode("update");
    let obj = { translations: [], icon: null, language_code: "" }
    if (e.translations.length !== languages.length) {

      obj.icon = e.icon
      obj.language_code = e.language_code
      languages.forEach((d) => {
        if (e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
          obj.translations = [...obj.translations, e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
        else
          obj.translations = [...obj.translations, { name: "", language_code: d.language_code }]
      })
    }
    else {
      obj.translations = [...e.translations]
    }
    setData({ ...e, translations: [...obj.translations] });
    setModalData({ name: e.language_code + " ", icon: null, code: "Department" });
    setMode("update");
    setOpen(true);
  }
  const Read = (e) => {

    let obj = { translations: [], icon: null, language_code: "" }

    if (e.translations.length !== languages.length) {
      obj.icon = e.icon
      obj.language_code = e.language_code
      languages.forEach((d) => {
        if (e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
          obj.translations = [...obj.translations, e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
        else
          obj.translations = [...obj.translations, { name: "", language_code: d.language_code }]
      })
      setData({ ...e, translations: [...obj.translations] });
    }
    else {
      setData({ ...e });
    }
    setModalData({ name: e.translations.filter(t => t.language_code === "en").name, icon: e.icon, code: e.language_code })
    setMode("read");
    setOpen(true);
  }
  const Close = () => {
    let obj = { translations: [], icon: null, language_code: "", flat_photo_path: "", outline_photo_path: "", fill_photo_path: "", name_in_native_language: "" }
    languages.forEach((a) => {
      obj = { ...obj, translations: [...obj.translations, { name: "", language_code: a.language_code }] }
    })
    setOpen(false);
    setData(obj);
    setModalData({ name: "", icon: null, code: "" })

  }
  const Add = (e) => {
    setOpen(false);
    dispatch({ type: "request-running-red", paylod: null })
    let obj = { translations: [], icon: null, language_code: "", flat_photo_path: "", outline_photo_path: "", fill_photo_path: "", name_in_native_language: "", parent_language_code: null }
    languages.forEach((a) => {
      obj = { ...obj, translations: [...obj.translations, { name: "", language_code: a.language_code }] }
    })
    if (e) {
      obj.parent_language_code = e.language_code
      setModalData({ name: e.language_code + " Language", icon: null, language_code: "Language" });
    }
    else {
      if (languageFollower.length === 0) {
        obj.parent_language_code = null
      }
      else obj.parent_language_code = languageFollower[languageFollower.length - 1].id?.toLowerCase()
      setModalData({ name: "Language", icon: null, language_code: "Language" })
    }
    setData(obj);

    setMode("add");
    setTimeout(() => {
      setOpen(true);
    }, 100);

  }
  return (
    <>
      <ToastContainer />
      <div className={`languages-page ${props.active && "Active-page"}`}>

        <div className='lang-top'>
          <div className='lang-info'>
            <div className='lang-icon header-icon'>
              <img alt="Languages" src={Curre} />
            </div>
            <div className='lang-name' onClick={() => { setLang_id(0); setLanguageFollower([]) }}>{trans("languages")}</div>
            <div className='follower-list'>
              {languageFollower.forEach((fo, index) => (
                <div className='follower' onClick={() => makeFollower(index, fo.name, fo.id)}>
                  <span><FollowerIcon expand={false} /></span>
                  <span>{fo.name}</span>
                </div>

              ))}
            </div>
          </div>
          <div className='top-options'>
            <div className='top-option'>
              <span><Search /></span>
            </div>
            <div className='top-option langs'>
              <span><Translated /></span>
              <div className='lang-items'>
                {languages.forEach(langs => <div onClick={() => dispatch({ type: "TRANSLATE", payload: langs.language_code })} onMouseLeave={() => setLang(null)} onMouseEnter={() => setLang(langs.language_code)} className={`lang-item  ${translated_lang?.toLowerCase() === langs.language_code?.toLowerCase() && "selected-lang"}`}>{langs.language_code.toUpperCase()}</div>)}
              </div>
            </div>
            <div className='top-option'>
              <span><Sort /></span>
            </div>
            <div className='top-option'>
              <span><Filter /></span>
            </div>
            <div className='top-option'>
              <span><Application /></span>
            </div>
            <svg className={'lines'} xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5">
              <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.5" />
            </svg>
          </div>
          <div className={`lang-add currency-add`}>
            <svg className={(!languages.length > 0) ? 'disab' : undefined} onClick={() => { if (languages.length > 0 && languageFollower.length === 0) Add(); if (languageFollower.length > 0 && languageFollower[languageFollower.length - 1].id) setLang_id(languageFollower[languageFollower.length - 1].id) }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_4527" data-name="Rectangle 4527" width="15" height="15" transform="translate(324 333)" fill="none" />
                </clipPath>
              </defs>
              <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)" clipPath="url(#clip-path)">
                <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#404040" />
              </g>
            </svg>

          </div>
        </div>
        <LangBody lang={getLang()} setModalData={(e) => setModalData(e)} setLang_id={(e) => setLang_id(e)} lang_id={lang_id} open={open} setOpen={(e) => setOpen(e)} Add={(e) => Add(e)} Close={(e) => Close(e)} Update={(e) => Update(e)} Read={(e) => Read(e)} makeFollower={(e, n, id) => makeFollower(e, n, id)} setLanguageFollower={(level, val) => setLanguageFollower(level, val)} languageFollower={languageFollower} />
      </div>
    </>
  )
}

export default LinkedLanguages