import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NumItem from './NumItem'
import NumModal from './NumModal'

function NumCats(props) {
  
  const ActiveAdd =(e)=>{
    dispatch({type:"Active-add",payload:e})
  }
  const dispatch = useDispatch()
  const requested_data = useSelector((state)=>state.numcats.request_data)
  const {lang_id, setLang_id}= props
  const {makeFollower} = props
  const numcats = useSelector(state => state.numcats.numcats)
  const data = useSelector(state => state.numcats.data)
  const mode = useSelector(state => state.numcats.mode)
  const Active_Ids = useSelector(state => state.numcats.Active_Ids)
  useEffect(()=>{
    if(numcats.length===0&&props.view==="Number Categories"){

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
   },[props.view])
  return (
    <>
    { (lang_id===0 ||lang_id===null) && props.open && <NumModal Active_Ids={Active_Ids} ActiveAdd={()=>props.ActiveAdd(data.id)} languageFollower={props.languageFollower} setExpand={(e)=>{}} data={data} {...props}/>}
        <div className='lang-body'>
          {
            numcats.map((dept)=>(
              <NumItem
              ActiveAdd={(e)=>ActiveAdd(e)}
              lang={props.lang}
              open={props.open}
              Close={()=>props.Close()}
              Add={(e)=>props.Add(e)}
              Update={(e)=>props.Update(e)}
              Read={(e)=>props.Read(e)}
              setModalData={(e)=>props.setModalData(e)}
              setOpen={(e)=>props.setOpen(e)}
               child={dept}
               
               languageFollower={props.languageFollower}
               level={1}
               makeFollower={(level,value,id)=>makeFollower(level,value,id)}
               lang_id={lang_id}
               setLang_id={(e)=>setLang_id(e)}
               />
            ))
          }
          {((props.open&&mode==="add")||(requested_data&&mode==="add"))&& <NumItem
              open={props.open}
              Close={()=>props.Close()}
              lang={props.lang}
              Add={(e)=>props.Add(e)}
              Update={(e)=>props.Update(e)}
              new={"new"}
              Read={(e)=>props.Read(e)}
              setOpen={(e)=>props.setOpen(e)}
               child={requested_data?requested_data:data}
               languageFollower={props.languageFollower}
               level={1}
               makeFollower={(level,value,id)=>makeFollower(level,value,id)}
               lang_id={lang_id}
               setLang_id={(e)=>setLang_id(e)}
               />}
        </div>
        </>
  )
}

export default NumCats