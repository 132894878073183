const initialState = {
  numcats:[],
    data:{translations:[{name:"",language_code:"en",code:""},{name:"",language_code:"ar",code:""},{name:"",language_code:"tr",code:""}],icon:null},
    mode:"read",
    open:false,
    modalData:{name:"",icon:null,code:""},
    loading:false,
    Active_Ids:[],
    request_running:false,
    request_data:null,
    message:"",
    error:false,
  img_upload:{type:"",status:false,lang:null}

}

export const NumReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "EDIT-NUM-ADD":{
      return({
        ...state,
      numcats:[...state.numcats,payload]
      })
    }
    case "EDIT-NUM-UPD":{
      const data=state.numcats
      let arr=[]
      data.forEach((a)=>{
        if(a.id===payload.id){
          arr.push(payload)
        }
        else{
          arr.push(a)
        }
      })
      return({
        ...state,
        numcats:[...arr]
      })
    }
    case "IMG-UPLOAD-NUM":{
      return({
        ...state,
        img_upload:{...payload}
      })
    }
    case "request-running-num":{
      return({
        ...state,
        request_running:true,
        request_data:payload
      })
    }
    case "GENERAL-ERR-num":{
      return({
        ...state,
        request_running:false,
        request_data:payload.data,
        error:true,
        message:payload.message
      })
    }
    case "request-running-num-red":{
      
      return({
        ...state,
        request_running:false,
        request_data:payload,
        error:false,
        message:""
      })
    }

    case "GENERAL-LOADING-NUM":{
      return({
        ...state,
        loading:true
      })
    }
    case "VIEW-NUM":{
      return({
        ...state,
        view_language:payload
      })
    }
    case "GENERAL-DONE-NUM":{
      return({
        ...state,
        loading:false,
        open:false,
    

      })
    }
    case "LEVEL-DONE-NUM":{
      return({
        ...state,
        loading:false
      })
    }
    case "GET-NUM-REDUCER":{
      return({...state,numcats:[...payload],request_data:null,request_running:false,error:false,message:""})
    }
    case "Active-add":{
        return({
          ...state,
          Active_Ids:payload
        })
      }
      case "NEW-NUM":{
        return{
          ...state,
          data:{...payload}
        }
      }
      case "OPEN-NUM":{
        return({
          ...state,
          open:payload
        })
      }
      case "MODE-NUM":{
        return({
          ...state,
          mode:payload
        })
      } 
      case "MOD-DATA-NUM":{
        
        return({
          ...state,
          modalData:{...payload}
        })
      }
  default:
    return state
  }
}
