import React, { useState, useEffect } from 'react'
import Curre from "../Languages/svgs/Currencies.svg"
import { useSelector, useDispatch } from "react-redux"
import FollowerIcon from "../Languages/svgs/FollowerIcon"
import NumCats from './NumCats/NumCats'
import CurrenciesList from './Currencies/CurrenciesList'
import NumCatsICon from './NumCats/NumCatsICon'
import CurrenciesIcon from './Currencies/CurrenciesIcon'
import Search from '../../../../assets/svg/search'
import Translated from '../../../../assets/svg/Translated'
import Sort from '../../../../assets/svg/Sort'
import Filter from '../../../../assets/svg/Filter'
import Application from '../../../../assets/svg/Application'
import { trans } from '../../../../Internationalization'
function Currencies(props) {
  const [lang_id, setLang_id] = useState(null)
  const dispatch = useDispatch()
  const [lang, setLang] = useState(null)
  const translated_lang = useSelector((state) => state.auth.lang)
  const getLang = () => {
    if (lang) {
      return (lang)
    }
    else {
      return translated_lang
    }
  }
  const makeFollower = (level, value, id) => {
    if (id === 0) {
      setLang_id(null)

    }
    else setLang_id(id)
    if (languageFollower.length >= level) {
      let arr = languageFollower
      arr[level] = { name: value, id: id }
      arr = arr.filter((a, index) => index <= level)
      setLanguageFollower([...arr])
    } else {
      setLanguageFollower([...languageFollower, { name: value, id: id }])

    }
  }
  const view = useSelector(state => state.currency.view)
  const languages = useSelector(state => state.langs.languages)
  const [languageFollower, setLanguageFollower] = useState([{ name: view, id: 0 }])
  //currencies section
  const open = useSelector(state => state.currency.open)
  const setOpen = (e) => {
    dispatch({ type: "OPEN-CUR", payload: e })
  }
  useEffect(() => {
    setOpenJob(false)
    setOpen(false)
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])
  const setMode = (e) => {
    dispatch({ type: "MODE-CUR", payload: e })
  }
  const setModalData = (e) => {
    dispatch({ type: "MOD-DATA-CUR", payload: { name: e.name, icon: e.icon } })
  }
  const setData = (e) => {
    dispatch({ type: "NEW-CUR", payload: { ...e } })
  }
  const Update = (e) => {
    dispatch({ type: "request-running-curr-red", paylod: null })
    setMode("update");
    let obj = { translations: [], icon: null, language_code: "" }

    if (e.translations.length !== languages.length) {
      obj.icon = e.icon
      obj.language_code = e.language_code
      languages.forEach((d) => {
        if (e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
          obj.translations = [...obj.translations, e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
        else
          obj.translations = [...obj.translations, { name: "", language_code: d.language_code, code: "", one_percent_piece_code: "", one_percent_piece_name: "" }]
      })
      setData({ ...e, translations: [...obj.translations] });
    }
    else {
      setData({ ...e });
    }
    setModalData({ name: e.translations.filter((a) => a.language_code === "en")[0].name + " ", icon: null, code: "Currency" });
    setMode("update");
    setOpen(true);
  }
  const Read = (e) => {
    let obj = { translations: [], icon: null, language_code: "" }

    if (e.translations.length !== languages.length) {
      obj.icon = e.icon
      obj.language_code = e.language_code
      languages.forEach((d) => {
        if (e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
          obj.translations = [...obj.translations, e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
        else
          obj.translations = [...obj.translations, { name: "", language_code: d.language_code, code: "", one_percent_piece_code: "", one_percent_piece_name: "" }]
      })
      setData({ ...e, translations: [...obj.translations] });
    }
    else {
      setData({ ...e });
    }

    setModalData({ name: e.translations.filter((a) => a.language_code === "en")[0].name, icon: e.icon, code: e.translations.filter((a) => a.language_code === "en")[0].name })
    setMode("read");
    setOpen(true);
  }
  const Close = () => {
    let obj = { translations: [], icon: null, code: "", fill_photo_path: null, flat_photo_path: null, outline_photo_path: null, png_photo_path: null }
    languages.forEach((a) => {
      obj = { ...obj, translations: [...obj.translations, { name: "", language_code: a.language_code }] }
    })
    setOpen(false);
    setData(obj);
    setModalData({ name: "", icon: null, code: "" })
  }
  const Add = (e) => {
    setLanguageFollower([{ name: trans("currencies"), id: 0 }]);
    setOpen(false);
    dispatch({ type: "request-running-curr-red", paylod: null })

    let obj = { translations: [], is_main: 1, fill_photo_path: null, flat_photo_path: null, outline_photo_path: null, png_photo_path: null, code: "" }
    languages.forEach((a) => {
      obj = { ...obj, translations: [...obj.translations, { name: "", code: "", language_code: a.language_code, one_percent_piece_name: "", one_percent_piece_code: "" }] }
    })
    if (e) {
      obj = { ...obj }
      setModalData({ name: e.translations.filter((a) => a.language_code === "en")[0].name + " Currency", icon: null, code: "Currency" });
    }
    else {
      obj = { ...obj }
      setModalData({ name: "Currency", icon: null, code: "Currency" })
    }
    setData(obj);
    setMode("add");
    setTimeout(() => {
      setOpen(true);
    }, 100);

  }
  //currencis section end
  //numcats section start
  const openJob = useSelector(state => state.numcats.open)

  const setOpenJob = (e) => {
    dispatch({ type: "OPEN-NUM", payload: e })
  }

  const setModeJob = (e) => {
    dispatch({ type: "MODE-NUM", payload: e })
  }
  const setModalDataJob = (e) => {
    dispatch({ type: "MOD-DATA-NUM", payload: { name: e.name, icon: e.icon, code: e.code } })
  }
  const setDataJob = (e) => {
    dispatch({ type: "NEW-NUM", payload: { ...e } })
  }
  const UpdateJob = (e) => {
    dispatch({ type: "request-running-num-red", paylod: null })
    setMode("update");
    let obj = { translations: [], icon: null }

    if (e.translations.length !== languages.length) {
      obj.icon = e.icon

      languages.forEach((d) => {
        if (e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
          obj.translations = [...obj.translations, e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
        else
          obj.translations = [...obj.translations, { name: "", language_code: d.language_code, code: "" }]
      })
      setDataJob({ ...e, translations: [...obj.translations] });
    }
    else {
      setDataJob({ ...e });
    }

    setModalDataJob({ name: e.translations.filter((a) => a.language_code === "en")[0].name + " ", icon: null, code: e.translations.filter((e) => e.language_code === "en")[0].code });
    setModeJob("update");
    setOpenJob(true);
  }
  const ReadJob = (e) => {
    let obj = { translations: [], icon: null }

    if (e.translations.length !== languages.length) {
      obj.icon = e.icon

      languages.forEach((d) => {
        if (e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
          obj.translations = [...obj.translations, e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
        else
          obj.translations = [...obj.translations, { name: "", language_code: d.language_code }]
      })
      setDataJob({ ...e, translations: [...obj.translations] });
    }
    else {
      setDataJob({ ...e });
    }

    setModalDataJob({ name: e.translations.filter((e) => e.language_code === "en")[0].name, icon: e.translations.filter((e) => e.language_code === "en")[0].name, code: e.translations.filter((e) => e.language_code === "en")[0].code })
    setModeJob("read");
    setOpenJob(true);
  }
  const CloseJob = () => {
    let obj = { translations: [], icon: null, fill_photo_path: null, flat_photo_path: null, outline_photo_path: null, png_photo_path: null }
    languages.forEach((a) => {
      obj = { ...obj, translations: [...obj.translations, { name: "", language_code: a.language_code, code: "" }] }
    })
    setOpenJob(false);
    setDataJob(obj);
    setModalDataJob({ name: "", icon: null, code: "" })

  }
  const AddJob = (e) => {
    setLanguageFollower([{ name: trans("num_cat"), id: 0 }]);
    dispatch({ type: "request-running-num-red", paylod: null })

    setOpenJob(false);
    let obj = { translations: [], icon: null, fill_photo_path: null, flat_photo_path: null, outline_photo_path: null, png_photo_path: null }
    languages.forEach((a) => {
      obj = { ...obj, translations: [...obj.translations, { name: "", language_code: a.language_code, code: "" }] }
    })
    if (e)
      setModalDataJob({ name: e.translations.filter((a) => a.language_code === "en")[0].name + trans("num_cat"), icon: null, code: "Job Title" });
    else
      setModalDataJob({ name: trans("num_cat"), icon: null, code: trans("num_cat") })
    setDataJob(obj);

    setModeJob("add");
    setTimeout(() => {
      setOpenJob(true);
    }, 100);
  }
  //numcats section end
  const addItem = (e) => {
    switch (view) {
      case trans("currencies"):
        Add(e);
        break;
      case trans("num_cat"): 
        if (view === trans("num_cat"))
          AddJob(e);
        break;

      default:
        break;
    }
  }
  const showView = () => {

    switch (view) {
      case trans("currencies"):
        return (<CurrenciesList lang={getLang()} active={props.active} view={view} setModalData={(e) => setModalData(e)} setLang_id={(e) => setLang_id(e)} lang_id={lang_id} open={open} setOpen={(e) => setOpen(e)} Add={(e) => Add(e)} Close={(e) => Close(e)} Update={(e) => Update(e)} Read={(e) => Read(e)} makeFollower={(e, n, id) => makeFollower(e, n, id)} setLanguageFollower={(level, val) => setLanguageFollower(level, val)} languageFollower={languageFollower} />)
      case trans("num_cat"):
        return (<NumCats lang={getLang()} active={props.active} view={view} setModalData={(e) => setModalDataJob(e)} setLang_id={(e) => setLang_id(e)} lang_id={lang_id} open={openJob} setOpen={(e) => setOpenJob(e)} Add={(e) => AddJob(e)} Close={(e) => CloseJob(e)} Update={(e) => UpdateJob(e)} Read={(e) => ReadJob(e)} makeFollower={(e, n, id) => makeFollower(e, n, id)} setLanguageFollower={(level, val) => setLanguageFollower(level, val)} languageFollower={languageFollower} />)
      default:
        break;

    }
  }
  useEffect(() => {
    if (props.active) {
      if (view === trans("currencies")) {
        dispatch({ type: "GET-CUR" })
      } else if (view === trans("num_cat")) {
        dispatch({ type: "GET-NUM" })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])
  return (
    <div className={`languages-page ${props.active && "Active-page"}`}>

      <div className='lang-top'>
        <div className='lang-info'>
          <div className='lang-icon header-icon'>
            <img alt="Currencies" src={Curre} />
          </div>
          <div className='lang-name' onClick={() => { }}>{trans("currencies")}</div>
          <div className='follower-list'>
            {languageFollower.forEach((fo, index) => (
              <div className='follower' onClick={() => makeFollower(index, fo.name, fo.id)}>
                <span><FollowerIcon expand={false} /></span>
                <span>{fo.name}</span>
              </div>

            ))}
          </div>
        </div>
        <div className='top-options'>
          <div className='top-option'>
            <span><Search /></span>
          </div>
          <div className='top-option langs'>
            <span><Translated /></span>
            <div className='lang-items'>
              {languages.forEach(langs => <div onClick={() => dispatch({ type: "TRANSLATE", payload: langs.language_code })} onMouseLeave={() => setLang(null)} onMouseEnter={() => setLang(langs.language_code)} className={`lang-item  ${translated_lang?.toLowerCase() === langs.language_code?.toLowerCase() && "selected-lang"}`}>{langs.language_code.toUpperCase()}</div>)}
            </div>
          </div>
          <div className='top-option'>
            <span><Sort /></span>
          </div>
          <div className='top-option'>
            <span><Filter /></span>
          </div>
          <div className='top-option'>
            <span><Application /></span>
          </div>
          <svg className={'lines'} xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5">
            <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.5" />
          </svg>
        </div>
        <div className={`lang-add currency-add`}>
          <NumCatsICon active={view === trans("num_cat")} change={() => { dispatch({ type: "CUR-VIEW", payload: trans("num_cat") }); setLanguageFollower([{ name: trans("num_cat"), id: 0 }]); setLang_id(null) }} />
          <CurrenciesIcon active={view === trans("currencies")} change={() => { dispatch({ type: "CUR-VIEW", payload: trans("currencies") }); setLanguageFollower([{ name: trans("currencies"), id: 0 }]); setLang_id(null) }} />
          <svg style={{ marginRight: "20px", width: "1px" }} xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5">
            <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.5" />
          </svg>

          <svg className={!languages.length > 0 && 'disab'} onClick={() => { if (languages.length > 0) addItem(); if (languageFollower[languageFollower.length - 1].id) setLang_id(languageFollower[languageFollower.length - 1].id) }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_4527" data-name="Rectangle 4527" width="15" height="15" transform="translate(324 333)" fill="none" />
              </clipPath>
            </defs>
            <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)" clipPath="url(#clip-path)">
              <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#404040" />
            </g>
          </svg>

        </div>
      </div>
      {showView()}
    </div>
  )
}

export default Currencies