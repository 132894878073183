import React from 'react'
import rep from "./svgs/rep.svg"
import copy from "./svgs/copy.svg"
import categ from "./svgs/categ.svg"
import delt from "./svgs/delt.svg"
import edit from "./svgs/edit.svg"
import forward from "./svgs/forward.svg"
import remind from "./svgs/remind.svg"
function OptionsMenu(props) {
  return (
    <div className="abs-menu">
        <div className='reply-but' onClick={()=>props.click()}>
            <svg data-src={rep}></svg>
            <div className='rep-descs' style={{    bottom: "-34px"}}>Reply</div>
        </div>
        <div className='message-ops'>
            <div className='message-opt' onClick={()=>props.forward()}>
                <svg data-src={forward}></svg>
            <div className='rep-descs'>Forward</div>
            </div>
            <div className='message-opt' onClick={()=>props.copy()}>
                <svg data-src={copy}></svg>
            <div className='rep-descs' >Copy</div>
            </div>
            <div className='message-opt'>
                <svg data-src={categ}></svg>
            <div className='rep-descs' >Category</div>
            </div>
            <div className='message-opt'>
                <svg data-src={delt}></svg>
            <div className='rep-descs' >Delete</div>
            </div>
            <div className='message-opt'>
                <svg data-src={edit}></svg>
            <div className='rep-descs' >Edit</div>
            </div>
            <div className='message-opt'>
                <svg data-src={remind}></svg>
            <div className='rep-descs' >Re-Remind</div>
            </div>
        </div>
    </div>
  )
}

export default OptionsMenu