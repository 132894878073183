const initialState = {

}
export const RegionsReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case "GET_REGIONS_REDUCER": {
            return ({
                ...state,
                regions: payload
            })
        }
        default:
            return state
    }
}
