import React from "react";
import Spinner from "../../../../Spinner";
import date from "date-and-time";
import ProfileNo from "../../../assets/images/profileNo.png";
import { memo } from "react";
import parse from 'html-react-parser';
import ply from "../../../assets/images/ply.svg";
import paus from "../../../assets/images/paus.svg";
import cam from "../../../assets/images/cam.svg";
import spinn from "../../../assets/images/spinner.svg";
import down from "../../../assets/images/down.svg";
import fil from "../../../assets/images/output.png";
import wai from "../../../assets/images/wai.svg";
import contactIcon from "../../../assets/images/cons.svg";
import rep from "../../../components/svgs/rep.svg";
import voc from "../../../assets/images/voc.svg";
import vide from "../../../assets/images/vid.svg";
import { useState } from "react";
import { useRef } from "react";
import Icon from "../../../components/Icon";
import OptionsMenu from "../../../components/OptionsMenu";
import { useDispatch, useSelector } from "react-redux";
import { FILE_SERVER } from "../../../../../../constants";
var vCard = require('vcard-parser');
function Message({
  a,
  active,
  i,
  copy,
  activeChat,
  img,
  setImg,
  vid,
  setVid,
  setReplyMessage,
  GetMessage,
  getAudioMessages
}) {
  function handleSetUser(contact, id) {
    dispatch({ type: "CHAT_ID", payload: contact })
  }
  var numb = a?.id
  let ids = numb
  const dispatch = useDispatch()
  const [opens, setOpen] = React.useState(false);
  const getDir = (s) => {
    if (s?.length > 0) {
      let charStr = s[0]
      var langdic = {
        "arabic": /[\u0600-\u06FF]/,
        "english": /^[a-zA-Z]+$/
        //add other languages her
      }
      let lang = ''
      Object.entries(langdic).forEach(([key, value]) => {  // loop to read all the dictionary items if not true
        if (value.test(charStr) === true) {   //Check Unicode to see which one is true
          lang = key //print language name if unicode true   
        }
      });
      if (lang === "arabic") {
        return "rtl"
      }
      else {
        return "ltr"
      }
    }
    else {
      return "ltr"
    }
  }
  const getText = (sds) => {
    let str = sds;
    if (str?.includes("@")) {
      const regex = /@\d+/g;
      let ser = str.match(regex)


      // console.log(ser)
      if (ser && ser.length > 0) {

        ser.forEach((s) => {
          str = str.replace(s, `<span class='blue_tag'> @${(contacts.filter((con) => ("@" + con.number) === s)[0]?.name || contacts.filter((con) => ("@" + con.number) === s)[0]?.pushname || sub_contacts.filter((con) => ("@" + con.number) === s)[0]?.name || sub_contacts.filter((con) => ("@" + con.number) === s)[0]?.pushname) || s}</span>`)
        })

      }
    }

    if (str !== null) {
      return parse(str?.toString())

    }
    else {
      return sds
    }

  }
  const handlePlay = () => {
    let played = a?.id
    let auds = getAudioMessages()
    auds?.forEach((aud) => {
      if (aud?.getAttribute("aria-details") !== played)
        aud.currentTime = 0
      aud?.pause()
    })
  }
  const getVcardName = (card) => {
    var myVCard = vCard?.parse(card);
    return (myVCard?.fn[0]?.value)
  }
  const getVcardWaId = (card) => {
    var myVCard = vCard?.parse(card);
    if (myVCard?.tel[0]?.meta?.waid[0]) {
      return (myVCard?.tel[0]?.meta?.waid[0])
    }
    else {
      return null
    }
  }

  const getVcardTel = (card) => {
    var myVCard = vCard?.parse(card);
    return (myVCard?.tel[0]?.value)
  }

  const handleEnd = () => {
    let played = a?.id
    let targenext = 0
    getAudioMessages().forEach((aud, ind) => {
      if (aud?.getAttribute("aria-details") === played && ind !== (getAudioMessages().length - 1)) {
        targenext = ind + 1
      }
    })
    if (targenext !== 0) {
      getAudioMessages().forEach((aud, ind) => {
        if (ind === targenext) {
          aud.play()
        }
      })
    }
  }
  const emps = useSelector((state) => state.employee.employee)
  const contacts = useSelector((state) => state.whatsapp.contacts)
  const sub_contacts = useSelector((state) => state.whatsapp.activeChat?.whatsapp_contact?.sub_contacts) || []
  const audioRef = useRef();
  const showTime = (m) => {
    let minutes = parseInt(m / 60);
    let seconds = Math.abs(parseInt(m - minutes * 60));
    if (m)
      return `${minutes > 9 ? minutes : "0" + minutes}:${seconds > 9 ? seconds : "0" + seconds
        }`;
    else return "";
  };
  const [play, setPlay] = useState(false);
  const getMessageTime = () => {
    let no = new Date();
    let d = a.sent_at ? new Date(a?.sent_at) : new Date(a.created_at);
    if (no.getHours() === d.getHours()) {
      return date.format(new Date(d), "HH:mm");
    }
    d = d.getTime()
    return date.format(new Date(d), "HH:mm");
  };
  const showMessage = () => {
    if (a?.type === "document") {
      return (
        <>
          <div
            onMouseLeave={() => setOpen(false)}
            className={`message-hold ${opens && "ac"}  message-hold--${a?.fromMe === 0 ? "rxd" : "sent"
              }`}
          >

            <div
              id={"s" + ids}
              onClick={() => setOpen(true)}

              className={`${a?.isForwarded === 1 && "padTop"}
              ${(((a?.fromMe === 0 || !a?.fromMe)) || (a?.sender_employee_user_id)) &&
                "group_chat_msg"
                } chat__file  chat__msg chat__msg--${a?.fromMe === 0 ? "rxd" : "sent"
                }`}
            >
              {a?.isForwarded === 1 &&
                <div className="forwarded-icon">
                  <svg data-src={rep} ></svg>
                  <span>Forwarded Message</span>
                </div>}
              {(a?.fromMe === 0 || !a?.fromMe) && (
                <>
                  {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                    {contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                      ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                      : contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                  </div>}
                  <img alt="" src={contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
                </>
              )}
              {!a.received_from_contact_id && a?.sender_employee_user_id &&
                <>
                  {activeChat?.isGroup === 1 && <div className="group_name">
                    {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                  </div>}
                  <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
                </>
              }
              {a?.quotedMsg?.id && (
                <div
                  className="replay-msg"
                  onClick={() => GetMessage(a?.id, a?.quotedMsg?.id)}
                >
                  <div className="bar-c"></div>
                  <div className="reply-cont">
                    <div className="reply-name group_name">
                      {a?.quotedMsg?.sender_name ? a?.quotedMsg?.sender_name : (a?.quotedMsg?.fromMe === 1
                        ? ("You")
                        : activeChat?.isGroup === 1 ? (
                          (activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.pushname)
                            ? (activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.pushname)
                            : "+" + activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.number
                        )
                          :
                          ((activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                            ? (activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                            : "+" + activeChat?.whatsapp_contact?.number))}
                    </div>
                    <span className="reply-desc">
                      {(a?.quotedMsg?.type === "image" || a?.quotedMsg?.type === "sticker") && <><svg data-src={cam}></svg> Image</>}
                      {a?.quotedMsg?.type === "video" && <> <svg data-src={vide}></svg> Video</>}
                      {(a?.quotedMsg?.type === "ptt" || a?.quotedMsg?.type === "audio") && <><svg data-src={voc}></svg> Audio</>}
                      {a?.quotedMsg?.type === "document" && <><img alt="" width="12" height="15" src={fil} /> File</>}
                      {a?.quotedMsg?.type === "vcard" && <>  <svg data-src={contactIcon}></svg> Contact</>}
                      {" "}
                      {a?.quotedMsg?.body && a.quotedMsg.type !== "vcard" && ((a?.quotedMsg?.type !== "video") || (a.quotedMsg.type !== "vcard")) && getText(a?.quotedMsg?.body)}
                    </span>
                  </div>
                  {a?.quotedMsg && a?.quotedMsg?.media && (
                    <img alt=""
                      className="quotedImg"
                      src={a?.quotedMsg?.media}
                    />
                  )}
                </div>
              )}
              {a?.media && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={a?.media}
                  download
                  className="replay-msg file-msg"
                >
                  <img alt="" src={fil} style={{ width: "26px" }} />
                  <div className="file-desc">
                    <div className="file-name">
                      {a?.body}
                    </div>
                    <div className="file-type">
                      {a?.file_ext && a?.file_ext.length < 4
                        ? a?.file_ext
                        : a?.body && a?.body?.split(".")[1]}
                    </div>
                  </div>
                  {a?.mid ? (
                    <svg data-src={spinn}></svg>
                  ) : (
                    <svg data-src={down}></svg>
                  )}
                </a>
              )}

              <span className="chat__msg-filler"> </span>

              <span className="chat__msg-footer">
                {getMessageTime()}
                {((a?.fromMe === 1 || a?.fromMe === true) || a?.id_from_whatsapp?.substring(0, 1) === "t") && (
                  <>
                    {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read") ? <Icon
                      id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : ""))}
                      aria-label={a?.ack}
                      className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                        }`}
                    /> :
                      <svg className="chat__msg-status-icon" data-src={wai}></svg>}
                  </>
                )}
              </span>
            </div>
            <OptionsMenu copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
          </div>
        </>
      );
    } else if (a?.quotedMsg && a?.quotedMsg?.id) {
      return (
        <div
          onMouseLeave={() => setOpen(false)}
          className={`message-hold ${opens && "ac"}  message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
            }`}
        >
          <div
            id={"s" + ids}
            onClick={() => setOpen(true)}


            className={`${a?.isForwarded === 1 && "padTop"} ${((a?.fromMe === 0 || !a?.fromMe) || (a?.sender_employee_user_id)) &&
              "group_chat_msg"

              } ${(a?.type === "ptt" || a.type === "audio") && "aud_msg au-he"} chat__msg chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >
            {a?.isForwarded === 1 &&
              <div className="forwarded-icon">
                <svg data-src={rep} ></svg>
                <span>Forwarded Message</span>
              </div>}
            {(a?.fromMe === 0 || !a?.fromMe) && (
              <>
                {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                  {contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    : contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                </div>}
                <img alt="" src={contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
              </>
            )}
            {!a.received_from_contact_id && a?.sender_employee_user_id &&
              <>
                {activeChat?.isGroup === 1 && <div className="group_name">
                  {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                </div>}
                <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
              </>
            }
            <div
              className="replay-msg"
              onClick={() => GetMessage(a?.id, a?.quotedMsg?.id)}
            >
              <div className="bar-c"></div>
              <div className="reply-cont">
                <div className="reply-name group_name">
                  {a?.quotedMsg?.sender_name ? a?.quotedMsg?.sender_name : (a?.quotedMsg?.fromMe === 1
                    ? "You"
                    : activeChat?.isGroup === 1 ? (
                      (activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.pushname)
                        ? (activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.pushname)
                        : "+" + activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.number
                    )
                      :
                      ((activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                        ? (activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                        : "+" + activeChat?.whatsapp_contact?.number))}
                </div>
                <span className="reply-desc">
                  {(a?.quotedMsg?.type === "image" || a?.quotedMsg?.type === "sticker") && <><svg data-src={cam}></svg> Image</>}
                  {a?.quotedMsg?.type === "video" && <> <svg data-src={vide}></svg> Video</>}
                  {(a?.quotedMsg?.type === "ptt" || a?.quotedMsg?.type === "audio") && <><svg data-src={voc}></svg> Audio</>}
                  {a?.quotedMsg?.type === "document" && <><img alt="" width="12" height="15" src={fil} /> File</>}
                  {a?.quotedMsg?.type === "vcard" && <>  <svg data-src={contactIcon}></svg> Contact</>}
                  {" "}
                  {a?.quotedMsg?.body && a.quotedMsg.type !== "vcard" && ((a?.quotedMsg?.type !== "video") || (a.quotedMsg.type !== "vcard")) && getText(a?.quotedMsg?.body)}

                </span>
              </div>
              {a?.quotedMsg && a?.quotedMsg?.media && a?.quotedMsg?.type === "image" && (
                <img alt=""
                  className="quotedImg"
                  src={a?.quotedMsg?.media}
                />
              )}
            </div>
            {a?.media && (a?.type === "image" || a?.type === "sticker") && (
              <img alt=""
                onClick={() => setImg(a?.media)}
                className="img-mes"
                src={a?.media}
              />
            )}
            {(a?.type === "ptt" || a.type === "audio") && <div className="row-aud">
              {((a?.fromMe === 1 || a?.fromMe === true) || a?.id_from_whatsapp?.substring(0, 1) === "t") && (
                <div className="aud_ava_cont ml-0">
                  <img alt=""
                    src={
                      active?.profile_photo ? active?.profile_photo : ProfileNo
                    }
                  />
                  <svg
                    viewBox="0 0 19 26"
                    height="26"
                    width="19"
                    preserveAspectRatio="xMidYMid meet"
                    className=""
                    version="1.1"
                    x="0px"
                    y="0px"
                    enableBackground="new 0 0 19 26"
                    xmlSpace="preserve"
                  >
                    <path
                      fill="#FFFFFF"
                      className="ptt-status-background"
                      d="M9.217,24.401c-1.158,0-2.1-0.941-2.1-2.1v-2.366c-2.646-0.848-4.652-3.146-5.061-5.958L2.004,13.62 l-0.003-0.081c-0.021-0.559,0.182-1.088,0.571-1.492c0.39-0.404,0.939-0.637,1.507-0.637h0.3c0.254,0,0.498,0.044,0.724,0.125v-6.27 C5.103,2.913,7.016,1,9.367,1c2.352,0,4.265,1.913,4.265,4.265v6.271c0.226-0.081,0.469-0.125,0.723-0.125h0.3 c0.564,0,1.112,0.233,1.501,0.64s0.597,0.963,0.571,1.526c0,0.005,0.001,0.124-0.08,0.6c-0.47,2.703-2.459,4.917-5.029,5.748v2.378 c0,1.158-0.942,2.1-2.1,2.1H9.217V24.401z"
                    ></path>
                    <path
                      fill="#0DA9E5"
                      className="ptt-status-icon"
                      d="M9.367,15.668c1.527,0,2.765-1.238,2.765-2.765V5.265c0-1.527-1.238-2.765-2.765-2.765 S6.603,3.738,6.603,5.265v7.638C6.603,14.43,7.84,15.668,9.367,15.668z M14.655,12.91h-0.3c-0.33,0-0.614,0.269-0.631,0.598 c0,0,0,0-0.059,0.285c-0.41,1.997-2.182,3.505-4.298,3.505c-2.126,0-3.904-1.521-4.304-3.531C5.008,13.49,5.008,13.49,5.008,13.49 c-0.016-0.319-0.299-0.579-0.629-0.579h-0.3c-0.33,0-0.591,0.258-0.579,0.573c0,0,0,0,0.04,0.278 c0.378,2.599,2.464,4.643,5.076,4.978v3.562c0,0.33,0.27,0.6,0.6,0.6h0.3c0.33,0,0.6-0.27,0.6-0.6V18.73 c2.557-0.33,4.613-2.286,5.051-4.809c0.057-0.328,0.061-0.411,0.061-0.411C15.243,13.18,14.985,12.91,14.655,12.91z"
                    ></path>
                  </svg>
                </div>
              )}
              <div
                className={`aud-cont ${((a?.fromMe === 1 || a?.fromMe === true) || a?.id_from_whatsapp?.substring(0, 1) === "t") && "ml-0"}`}
              >
                {(play || (!audioRef.current?.paused)) ? (
                  <svg
                    onClick={() => {
                      audioRef?.current?.pause();
                      setPlay(false);
                    }}
                    data-src={paus}
                  ></svg>
                ) : (
                  <svg
                    onClick={() => {
                      audioRef?.current?.play();
                      setPlay(true);
                      handlePlay()
                    }}
                    data-src={ply}
                  ></svg>
                )}
              </div>
              <div className="aud">
                <audio
                  aria-details={a?.id}
                  onEnded={() => {
                    handleEnd()
                    setPlay(false);
                    audioRef.current.currentTime = 0;
                  }}
                  src={a?.media}
                  ref={audioRef}
                >
                  <source src={a?.media} />
                </audio>
                <div className="tra"></div>
                <div
                  className="sv"
                  style={{
                    marginLeft: `${audioRef?.current &&
                      ((parseFloat(audioRef.current.currentTime) * 100) /
                        parseFloat(audioRef.current.duration)) *
                      1.8
                      }px`,
                  }}
                ></div>
              </div>
              {(a?.fromMe === 0 || !a?.fromMe) && (
                <div className="aud_ava_cont">
                  <img alt=""
                    src={
                      active?.profile_photo ? active?.profile_photo : ProfileNo
                    }
                  />
                  <svg
                    viewBox="0 0 19 26"
                    height="26"
                    width="19"
                    preserveAspectRatio="xMidYMid meet"
                    className=""
                    version="1.1"
                    x="0px"
                    y="0px"
                    enableBackground="new 0 0 19 26"
                    xmlSpace="preserve"
                  >
                    <path
                      fill="#FFFFFF"
                      className="ptt-status-background"
                      d="M9.217,24.401c-1.158,0-2.1-0.941-2.1-2.1v-2.366c-2.646-0.848-4.652-3.146-5.061-5.958L2.004,13.62 l-0.003-0.081c-0.021-0.559,0.182-1.088,0.571-1.492c0.39-0.404,0.939-0.637,1.507-0.637h0.3c0.254,0,0.498,0.044,0.724,0.125v-6.27 C5.103,2.913,7.016,1,9.367,1c2.352,0,4.265,1.913,4.265,4.265v6.271c0.226-0.081,0.469-0.125,0.723-0.125h0.3 c0.564,0,1.112,0.233,1.501,0.64s0.597,0.963,0.571,1.526c0,0.005,0.001,0.124-0.08,0.6c-0.47,2.703-2.459,4.917-5.029,5.748v2.378 c0,1.158-0.942,2.1-2.1,2.1H9.217V24.401z"
                    ></path>
                    <path
                      fill="#0DA9E5"
                      className="ptt-status-icon"
                      d="M9.367,15.668c1.527,0,2.765-1.238,2.765-2.765V5.265c0-1.527-1.238-2.765-2.765-2.765 S6.603,3.738,6.603,5.265v7.638C6.603,14.43,7.84,15.668,9.367,15.668z M14.655,12.91h-0.3c-0.33,0-0.614,0.269-0.631,0.598 c0,0,0,0-0.059,0.285c-0.41,1.997-2.182,3.505-4.298,3.505c-2.126,0-3.904-1.521-4.304-3.531C5.008,13.49,5.008,13.49,5.008,13.49 c-0.016-0.319-0.299-0.579-0.629-0.579h-0.3c-0.33,0-0.591,0.258-0.579,0.573c0,0,0,0,0.04,0.278 c0.378,2.599,2.464,4.643,5.076,4.978v3.562c0,0.33,0.27,0.6,0.6,0.6h0.3c0.33,0,0.6-0.27,0.6-0.6V18.73 c2.557-0.33,4.613-2.286,5.051-4.809c0.057-0.328,0.061-0.411,0.061-0.411C15.243,13.18,14.985,12.91,14.655,12.91z"
                    ></path>
                  </svg>
                </div>
              )}
            </div>}
            {(getText(a?.body)) && (
              <span style={{ marginTop: "10px", width: "100%", direction: getDir(getText(a?.body)) }}>

                {" "}
                {getText(a?.body)}
              </span>
            )}
            <span className="chat__msg-filler"> </span>

            <span className="chat__msg-footer">
              {getMessageTime()}
              {((a?.fromMe === 1 || a?.fromMe === true) || a?.id_from_whatsapp?.substring(0, 1) === "t") && (
                <>
                  {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read") ? <Icon
                    id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : ""))}
                    aria-label={a?.ack}
                    className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                      }`}
                  /> :
                    <svg className="chat__msg-status-icon" data-src={wai}></svg>}
                </>
              )}
            </span>
          </div>
          <OptionsMenu copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
        </div>
      );
    }
    if (a?.type === "chat") {
      return (
        <div
          onMouseLeave={() => setOpen(false)}
          className={`message-hold ${opens && "ac"} message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
            }`}
        >
          <div
            id={"s" + ids}
            onClick={() => setOpen(true)}


            className={`${a?.isForwarded === 1 && "padTop"} ${((a?.fromMe === 0 || !a?.fromMe) || (a?.sender_employee_user_id)) &&
              "group_chat_msg"
              } chat__msg chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >
            {a?.isForwarded === 1 &&
              <div className="forwarded-icon">
                <svg data-src={rep} ></svg>
                <span>Forwarded Message</span>
              </div>}

            {(a?.fromMe === 0 || !a?.fromMe) && (
              <>
                {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                  {contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    : contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                </div>}
                <img alt="" src={contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
              </>
            )}
            {!a.received_from_contact_id && a?.sender_employee_user_id &&
              <>
                {activeChat?.isGroup === 1 && <div className="group_name">
                  {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                </div>}
                <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
              </>
            }
            <span style={{ direction: getDir(getText(a?.body)) }}>


              {getText(a?.body)}</span>
            <span className="chat__msg-filler"> </span>

            <span className="chat__msg-footer">
              {getMessageTime()}
              {((a?.fromMe === 1 || a?.fromMe === true) || a?.id_from_whatsapp?.substring(0, 1) === "t") && (
                <>
                  {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read") ? <Icon
                    id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : ""))}
                    aria-label={a?.ack}
                    className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                      }`}
                  /> :
                    <svg className="chat__msg-status-icon" data-src={wai}></svg>}
                </>
              )}
            </span>
          </div>
          <OptionsMenu copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
        </div>
      );
    } else if (a?.type === "image" || a?.type === "sticker") {
      return (
        <div
          onMouseLeave={() => setOpen(false)}
          className={`message-hold ${opens && "ac"}  message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
            }`}
        >

          <div
            id={"s" + ids}
            onClick={() => setOpen(true)}


            style={{
              padding: "3px",
              flexWrap: "wrap",
              paddingBottom: a?.body ? "16px" : "4px",
            }}
            className={`${a?.isForwarded === 1 && "padTop"}
            ${((a?.fromMe === 0 || !a?.fromMe) || (a?.sender_employee_user_id)) &&
              "group_chat_msg"} med-msg
            ${a?.type === "sticker" && "sticker_msg"}
             chat_img chat__msg chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >
            {a?.sender_employee_user_id && <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />}

            {a?.isForwarded === 1 &&
              <div className="forwarded-icon">
                <svg data-src={rep} ></svg>
                <span>Forwarded Message</span>
              </div>}
            {(a?.fromMe === 0 || !a?.fromMe) && (
              <>
                {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                  {contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    : contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                </div>}
                <img alt="" src={contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
              </>
            )}
            {!a.received_from_contact_id && a?.sender_employee_user_id &&
              <>
                {activeChat?.isGroup === 1 && <div className="group_name">
                  {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                </div>}
                <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
              </>
            }
            <img alt=""
              onClick={() => setImg(a?.media)}
              className="img-mes"
              src={a?.media}
            />
            {a?.mid && (
              <div className="sppin">
                <Spinner no={true} />
              </div>
            )}
            <span className="chat__msg-footer">
              {getMessageTime()}
              {((a?.fromMe === 1 || a?.fromMe === true) || a?.id_from_whatsapp?.substring(0, 1) === "t") && !a?.mid && (
                <>
                  {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read") ? <Icon
                    id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : ""))}
                    aria-label={a?.ack}
                    className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                      }`}
                  /> :
                    <svg className="chat__msg-status-icon" data-src={wai}></svg>}
                </>
              )}
            </span>
            {(a?.body || a?.body) && (
              <span style={{ marginTop: "10px", width: "100%", direction: getDir(getText(a?.body)) }}>


                {a?.body || getText(a?.body)}
              </span>
            )}
          </div>
          <OptionsMenu copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus() }} />
        </div>
      );
    } else if (a?.type === "ptt" || a?.type === "audio") {
      return (
        <div
          onMouseLeave={() => setOpen(false)}
          className={`message-hold ${opens && "ac"} message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
            }`}
        >

          <div
            id={"s" + ids}
            onClick={() => setOpen(true)}
            style={{ flexWrap: "nowrap" }}


            className={`${a?.isForwarded === 1 && "padTop"} ml-0 chat__msg aud_msg chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >
            {a?.isForwarded === 1 &&
              <div className="forwarded-icon">
                <svg data-src={rep} ></svg>
                <span>Forwarded Message</span>
              </div>}
            {(a?.fromMe === 0 || !a?.fromMe) && (
              <>
                {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                  {contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    : contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                </div>}
                <img alt="" src={contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
              </>
            )}
            {!a.received_from_contact_id && a?.sender_employee_user_id &&
              <>
                {activeChat?.isGroup === 1 && <div className="group_name">
                  {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                </div>}
                <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
              </>
            }
            {((a?.fromMe === 1 || a?.fromMe === true) || a?.id_from_whatsapp?.substring(0, 1) === "t") && (
              <div className="aud_ava_cont ml-0">
                <img alt=""
                  src={
                    active?.profile_photo ? active?.profile_photo : ProfileNo
                  }
                />
                <svg
                  viewBox="0 0 19 26"
                  height="26"
                  width="19"
                  preserveAspectRatio="xMidYMid meet"
                  className=""
                  version="1.1"
                  x="0px"
                  y="0px"
                  enableBackground="new 0 0 19 26"
                  xmlSpace="preserve"
                >
                  <path
                    fill="#FFFFFF"
                    className="ptt-status-background"
                    d="M9.217,24.401c-1.158,0-2.1-0.941-2.1-2.1v-2.366c-2.646-0.848-4.652-3.146-5.061-5.958L2.004,13.62 l-0.003-0.081c-0.021-0.559,0.182-1.088,0.571-1.492c0.39-0.404,0.939-0.637,1.507-0.637h0.3c0.254,0,0.498,0.044,0.724,0.125v-6.27 C5.103,2.913,7.016,1,9.367,1c2.352,0,4.265,1.913,4.265,4.265v6.271c0.226-0.081,0.469-0.125,0.723-0.125h0.3 c0.564,0,1.112,0.233,1.501,0.64s0.597,0.963,0.571,1.526c0,0.005,0.001,0.124-0.08,0.6c-0.47,2.703-2.459,4.917-5.029,5.748v2.378 c0,1.158-0.942,2.1-2.1,2.1H9.217V24.401z"
                  ></path>
                  <path
                    fill="#0DA9E5"
                    className="ptt-status-icon"
                    d="M9.367,15.668c1.527,0,2.765-1.238,2.765-2.765V5.265c0-1.527-1.238-2.765-2.765-2.765 S6.603,3.738,6.603,5.265v7.638C6.603,14.43,7.84,15.668,9.367,15.668z M14.655,12.91h-0.3c-0.33,0-0.614,0.269-0.631,0.598 c0,0,0,0-0.059,0.285c-0.41,1.997-2.182,3.505-4.298,3.505c-2.126,0-3.904-1.521-4.304-3.531C5.008,13.49,5.008,13.49,5.008,13.49 c-0.016-0.319-0.299-0.579-0.629-0.579h-0.3c-0.33,0-0.591,0.258-0.579,0.573c0,0,0,0,0.04,0.278 c0.378,2.599,2.464,4.643,5.076,4.978v3.562c0,0.33,0.27,0.6,0.6,0.6h0.3c0.33,0,0.6-0.27,0.6-0.6V18.73 c2.557-0.33,4.613-2.286,5.051-4.809c0.057-0.328,0.061-0.411,0.061-0.411C15.243,13.18,14.985,12.91,14.655,12.91z"
                  ></path>
                </svg>
              </div>
            )}
            <div
              className={`aud-cont ${((a?.fromMe === 1 || a?.fromMe === true) || a?.id_from_whatsapp?.substring(0, 1) === "t") && "ml-0"}`}
            >
              {(play || (!audioRef.current?.paused)) ? (
                <svg
                  onClick={() => {
                    audioRef?.current?.pause();
                    setPlay(false);
                  }}
                  data-src={paus}
                ></svg>
              ) : (
                <svg
                  onClick={() => {
                    audioRef?.current?.play();
                    setPlay(true);
                    handlePlay()
                  }}
                  data-src={ply}
                ></svg>
              )}
            </div>
            <div className="aud">
              <audio
                aria-details={a?.id}
                onEnded={() => {
                  handleEnd()
                  setPlay(false);
                  audioRef.current.currentTime = 0;
                }}
                src={a?.media}
                ref={audioRef}
              >
                <source src={a?.media} />
              </audio>
              <div className="tra"></div>
              <div
                className="sv"
                style={{
                  marginLeft: `${audioRef?.current &&
                    ((parseFloat(audioRef.current.currentTime) * 100) /
                      parseFloat(audioRef.current.duration)) *
                    1.8
                    }px`,
                }}
              ></div>
            </div>
            {(a?.fromMe === 0 || !a?.fromMe) && (
              <div className="aud_ava_cont">
                <img alt=""
                  src={
                    active?.profile_photo ? active?.profile_photo : ProfileNo
                  }
                />
                <svg
                  viewBox="0 0 19 26"
                  height="26"
                  width="19"
                  preserveAspectRatio="xMidYMid meet"
                  className=""
                  version="1.1"
                  x="0px"
                  y="0px"
                  enableBackground="new 0 0 19 26"
                  xmlSpace="preserve"
                >
                  <path
                    fill="#FFFFFF"
                    className="ptt-status-background"
                    d="M9.217,24.401c-1.158,0-2.1-0.941-2.1-2.1v-2.366c-2.646-0.848-4.652-3.146-5.061-5.958L2.004,13.62 l-0.003-0.081c-0.021-0.559,0.182-1.088,0.571-1.492c0.39-0.404,0.939-0.637,1.507-0.637h0.3c0.254,0,0.498,0.044,0.724,0.125v-6.27 C5.103,2.913,7.016,1,9.367,1c2.352,0,4.265,1.913,4.265,4.265v6.271c0.226-0.081,0.469-0.125,0.723-0.125h0.3 c0.564,0,1.112,0.233,1.501,0.64s0.597,0.963,0.571,1.526c0,0.005,0.001,0.124-0.08,0.6c-0.47,2.703-2.459,4.917-5.029,5.748v2.378 c0,1.158-0.942,2.1-2.1,2.1H9.217V24.401z"
                  ></path>
                  <path
                    fill="#0DA9E5"
                    className="ptt-status-icon"
                    d="M9.367,15.668c1.527,0,2.765-1.238,2.765-2.765V5.265c0-1.527-1.238-2.765-2.765-2.765 S6.603,3.738,6.603,5.265v7.638C6.603,14.43,7.84,15.668,9.367,15.668z M14.655,12.91h-0.3c-0.33,0-0.614,0.269-0.631,0.598 c0,0,0,0-0.059,0.285c-0.41,1.997-2.182,3.505-4.298,3.505c-2.126,0-3.904-1.521-4.304-3.531C5.008,13.49,5.008,13.49,5.008,13.49 c-0.016-0.319-0.299-0.579-0.629-0.579h-0.3c-0.33,0-0.591,0.258-0.579,0.573c0,0,0,0,0.04,0.278 c0.378,2.599,2.464,4.643,5.076,4.978v3.562c0,0.33,0.27,0.6,0.6,0.6h0.3c0.33,0,0.6-0.27,0.6-0.6V18.73 c2.557-0.33,4.613-2.286,5.051-4.809c0.057-0.328,0.061-0.411,0.061-0.411C15.243,13.18,14.985,12.91,14.655,12.91z"
                  ></path>
                </svg>
              </div>
            )}
            <span
              className={`chat__msg-footer ${(a?.fromMe === 1 || a?.fromMe === true) && "me-voc"
                }`}
            >
              {getMessageTime()}
              {((a?.fromMe === 1 || a?.fromMe === true) || a?.id_from_whatsapp?.substring(0, 1) === "t") && (
                <>
                  {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read") ? <Icon
                    id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : ""))}
                    aria-label={a?.ack}
                    className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                      }`}
                  /> :
                    <svg className="chat__msg-status-icon" data-src={wai}></svg>}
                </>
              )}
            </span>
            <span className="chat__msg-footer dur">
              {audioRef?.current &&
                !isNaN(audioRef.current.duration) &&
                showTime(audioRef.current.duration).toString()}
            </span>
          </div>
          <OptionsMenu copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus() }} />
        </div>
      );
    }
    else if (a?.type === "video") {
      return (
        <div
          onMouseLeave={() => setOpen(false)}
          className={`message-hold ${opens && "ac"} message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
            }`}
        >

          <div
            id={"s" + ids}
            onClick={() => setOpen(true)}
            style={{ flexWrap: "nowrap" }}


            className={`${a?.isForwarded === 1 && "padTop"} aud_msg ml-0 med-msg chat__msg chat__img chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >

            {a?.isForwarded === 1 &&
              <div className="forwarded-icon">
                <svg data-src={rep} ></svg>
                <span>Forwarded Message</span>
              </div>}
            {(a?.fromMe === 0 || !a?.fromMe) && (
              <>
                {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                  {contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    : contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                </div>}
                <img alt="" src={contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
              </>
            )}
            {!a.received_from_contact_id && a?.sender_employee_user_id &&
              <>
                {activeChat?.isGroup === 1 && <div className="group_name">
                  {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                </div>}
                <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
              </>
            }
            <div className={`vid-cont ${audioRef?.current?.currentTime === 0 && "vixs"}`}>
              {(
                <svg
                  onClick={() => {
                    setVid(a.media)
                  }}
                  data-src={ply}
                ></svg>
              )}
            </div>
            <video onClick={() => setVid(a?.media)} onEnded={() => {
              handleEnd()
              setPlay(false);
              audioRef.current.currentTime = 0;
            }} ref={audioRef} className="img-mes" src={a?.media} controls={false}>
              <source src={a?.media}></source>
            </video>
            <span
              className={`chat__msg-footer ${((a?.fromMe === 1 || a?.fromMe === true) || a?.id_from_whatsapp?.substring(0, 1) === "t") && "me-voc"
                }`}
            >
              {getMessageTime()}
              {((a?.fromMe === 1 || a?.fromMe === true) || a?.id_from_whatsapp?.substring(0, 1) === "t") && (
                <>
                  {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read") ? <Icon
                    id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : ""))}
                    aria-label={a?.ack}
                    className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                      }`}
                  /> :
                    <svg className="chat__msg-status-icon" data-src={wai}></svg>}
                </>
              )}
            </span>
          </div>
          <OptionsMenu copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus() }} />
        </div>
      );
    }
    else if (a.type === "vcard") {
      return (
        <>
          <div
            onMouseLeave={() => setOpen(false)}
            className={`message-hold ${opens && "ac"}  message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >

            <div
              id={"s" + ids}
              onClick={() => setOpen(true)}

              className={`${a?.isForwarded === 1 && "padTop"}
            ${((a?.fromMe === 0) || (a?.sender_employee_user_id)) &&
                "group_chat_msg"
                }   chat__msg chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
                }`}
            >
              {a?.isForwarded === 1 &&
                <div className="forwarded-icon">
                  <svg data-src={rep} ></svg>
                  <span>Forwarded Message</span>
                </div>}
              {(a?.fromMe === 0 || !a?.fromMe) && (
                <>
                  {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                    {contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                      ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                      : contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                  </div>}
                  <img alt="" src={contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
                </>
              )}
              {!a.received_from_contact_id && a?.sender_employee_user_id &&
                <>
                  {activeChat?.isGroup === 1 && <div className="group_name">
                    {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                  </div>}
                  <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
                </>
              }
              {a?.quotedMsg && a?.quotedMsg?.id && (
                <div
                  className="replay-msg"
                  onClick={() => GetMessage(a?.id, a?.quotedMsg?.id)}
                >
                  <div className="bar-c"></div>
                  <div className="reply-cont">
                    <div className="reply-name group_name">
                      {a?.quotedMsg?.sender_name ? a?.quotedMsg?.sender_name : (a?.quotedMsg?.fromMe === 1
                        ? "You"
                        : activeChat?.isGroup === 1 ? (
                          (activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.pushname)
                            ? (activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.pushname)
                            : "+" + activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === a?.quotedMsg.whatsapp_contact_id)[0]?.number
                        )
                          :
                          ((activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                            ? (activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                            : "+" + activeChat?.whatsapp_contact?.number))}
                    </div>
                    <span className="reply-desc">
                      {(a?.quotedMsg?.type === "image" || a?.quotedMsg?.type === "sticker") && <><svg data-src={cam}></svg> Image</>}
                      {a?.quotedMsg?.type === "video" && <> <svg data-src={vide}></svg> Video</>}
                      {(a?.quotedMsg?.type === "ptt" || a?.quotedMsg?.type === "audio") && <><svg data-src={voc}></svg> Audio</>}
                      {a?.quotedMsg?.type === "document" && <><img alt="" width="12" height="15" src={fil} /> File</>}
                      {a?.quotedMsg?.type === "vcard" && <>  <svg data-src={contactIcon}></svg> Contact</>}
                      {" "}
                      {a?.quotedMsg?.body && a.quotedMsg.type !== "vcard" && a.quotedMsg.type !== "vcard"((a?.quotedMsg?.type !== "video") || (a.quotedMsg.type !== "vcard")) && getText(a?.quotedMsg?.body)}

                    </span>
                  </div>
                  {a?.quotedMsg && a?.quotedMsg?.media && (
                    <img alt=""
                      className="quotedImg"
                      src={a?.quotedMsg?.media}
                    />
                  )}
                </div>
              )}
              <div className="vcard">
                <img alt="" className="ava-vcard" src={ProfileNo} />
                <span>{getVcardName(a?.body)}</span>

              </div>

              <div className="vcard-option" onClick={() => {
                if (getVcardWaId(a?.body)) {
                  handleSetUser({
                    openVcard: true,
                    id: getVcardWaId(a?.body) + "@",
                    id_from_whatsapp: getVcardTel(a?.body) + "@",
                    name: getVcardName(a?.body),
                    whatsapp_contact: {
                      name: getVcardName(a?.body),
                      number: getVcardTel(a?.body)
                    },
                    messages: []
                  }, null)
                } else {
                  let elem = document.querySelector("#text-copy");
                  elem.value = `name:${getVcardName(a?.body)}\n phone: ${getVcardTel(a?.body)}`
                  elem.select()
                  document.execCommand('Copy');
                }

              }}>
                {getVcardWaId(a?.body) ? 'Message' : 'Copy Number'}
              </div>


              <span className="chat__msg-footer">
                {getMessageTime()}
                {((a?.fromMe === 1 || a?.fromMe === true) || a?.id_from_whatsapp?.substring(0, 1) === "t") && (
                  <>
                    {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read") ? <Icon
                      id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : ""))}
                      aria-label={a?.ack}
                      className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                        }`}
                    /> :
                      <svg className="chat__msg-status-icon" data-src={wai}></svg>}
                  </>
                )}
              </span>
            </div>

            <OptionsMenu copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
          </div>
        </>
      )
    }
  };
  return <>{showMessage()}</>;
}

export default memo(Message);
