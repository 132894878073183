import React, { useState } from 'react'
import edit from "../Routes/Languages/svgs/addsquare.svg"
import cancel from "../Routes/Languages/svgs/cancel.svg"
import en from "../Routes/Languages/svgs/E.svg"
import tr from "../Routes/Languages/svgs/Tr.svg"
import ar from "../Routes/Languages/svgs/Ar.svg"
import del from "../Routes/Languages/svgs/delete.svg"
function Editable(props) {
    const {child,setEdit}=props
    const [english,setEng]=useState('Women')
    const [turkish,setTurki]=useState('Kadin')
    const [ara,setAr]=useState('نساء')
    const update_en = (e)=>{
        if(e.target.value.length<11)
        setEng(e.target.value)
    }
    const update_tr = (e)=>{
        if(e.target.value.length<11)
        setTurki(e.target.value)
    }
    const update_ar = (e)=>{
        if(e.target.value.length<11)
        setAr(e.target.value)
    }
  return (
    <div className={`parent-info edit-info`}>
        <div className='img-container'>
            <div className='main-icon'><div className='edit-label'>Edit</div><div className='img-type'>Flat</div><img alt='icon' src={child.icon}/></div>
            <div className='main-icon'><img alt='outline' src={edit}/><div className='img-type'>Outline</div></div>
            <div className='main-icon'><img alt='flat' src={edit}/><div className='img-type'>PNG</div></div>
            <div className='buttons-section'>
                <div className='save-button' onClick={()=>setEdit(null)}>Save</div>
                <div className='cancel-button' onClick={()=>setEdit(null)}><img alt='cancel' src={cancel}/>
                </div>    
            </div>

        </div>
        <div className='translations-section'>
            <div className='trans-row'>
                <div className='lang-icon'><img alt='english' src={en}/></div>
                <div className='input-name'><input value={english} onChange={(e)=>update_en(e)} type={"text"}/></div>
                <div className='input-staus'>
                    <div className='ch_counter'>{english.length-10}</div>
                    <div className='delete-input' onClick={()=>setEng("")}><img alt='delete' src={del}/></div>
                </div>
            </div>
            <div className='trans-row'>
                <div className='lang-icon'><img alt='turkish' src={tr}/></div>
                <div className='input-name'><input value={turkish} onChange={(e)=>update_tr(e)} type={"text"}/></div>
                <div className='input-staus'>
                    <div className='ch_counter'>{turkish.length-10}</div>
                    <div className='delete-input' onClick={()=>setTurki("")}><img alt='delete' src={del}/></div>
                </div>
            </div>
            <div className='trans-row'>
                <div className='lang-icon'><img alt='arabic' src={ar}/></div>
                <div className='input-name'><input value={ara} onChange={(e)=>update_ar(e)} type={"text"}/></div>
                <div className='input-staus'>
                    <div className='ch_counter'>{ara.length-10}</div>
                    <div className='delete-input' onClick={()=>setAr("")}><img alt='delete' src={del}/></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Editable