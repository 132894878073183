import React from 'react'

function FlatIcon({ name }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      <rect id="Rectangle_4606" data-name="Rectangle 4606" width="50" height="50" rx="10" fill="#f7f7f7" />
      <text textAnchor='middle' id="A" transform="translate(0 4)" fill="#404040" fontSize="30" fontFamily="SegoeUI, Segoe UI" letterSpacing="0.025em"><tspan x="50%" y="32">{name}</tspan></text>
    </svg>
  )
}

export default FlatIcon