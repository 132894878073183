import Icon from "../../../components/Icon";
import React from "react";

import { useSelector } from "react-redux";
import ProfileNo from "../../../assets/images/profileNo.png";
import grop from "../../../assets/images/group.svg";
const Search = ({search,onChange,searchedMessages,goToMessage}) => {
	const activeChat=useSelector((state)=>state.whatsapp.activeChat)
	const contacts=useSelector((state)=>state.whatsapp.contacts)
	return (
		<>
			<div className="search-wrapper">
				<div className="search-icons">
					<Icon id="search" className="search-icon" />
					<button className="search__back-btn">
						<Icon id="back" />
					</button>
				</div>
				<input value={search} onChange={(e)=>onChange(e)} className="search" placeholder="Search..." />
			</div>
			<div className="chat-sidebar__search-results">
			{searchedMessages.length===0?<p> Search for messages with Karen Okonkwo.</p>:
				searchedMessages.map((mes)=>(<>
				{mes.body&&<div className="sidebar-contact" onClick={()=>goToMessage(mes)}>
				<div className="sidebar-contact__avatar-wrapper">
					{activeChat?.isGroup && activeChat.isGroup === 1 ? (
					<svg className="avatar" data-src={grop}></svg>
					) : (
					<img
						src={(mes?.fromMe!==1)? activeChat?.profile_photo ? activeChat.profile_photo:ProfileNo:ProfileNo}
						alt={""}
						className="avatar"
					/>
					)}
				</div>
						<div className="sidebar-contact__content" style={{justifyContent:"flex-start",textAlign:"start"}}>
						<div className="sidebar-contact__topcontent">
							<h2 className="sidebar-contact__name">
							{mes?.fromMe===1
                        ? "You"
                        : contacts.filter((s)=>s.id===mes?.received_from_contact_id)[0]?.name
                        ? contacts.filter((s)=>s.id===mes?.received_from_contact_id)[0]?.name:
						("+" + contacts.filter((s)=>s.id===mes?.received_from_contact_id)[0]?.number ||
                        activeChat?.whatsapp_contact?.name)}
							</h2>
							
						</div>
						<div className="sidebar-contact__bottom-content" style={{justifyContent:"flex-start"}}>
						{mes?.ack && (
              <Icon
                id={
					mes?.ack === "device"
                    ? "singleTick"
                    : "doubleTick"
                }
                aria-label={mes?.ack}
                className={`sidebar-contact__message-icon ${
					mes?.ack === "read"
                    ? "sidebar-contact__message-icon--blue"
                    : ""
                }`}
              />
            )}
							{mes?.body}</div>
						</div>
					</div>}
				</>
					
				))
				}
			</div>
		</>
	);
};

export default Search;
